import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../components/Buttons/Button';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import LabelInput from '../../components/Form/LabelInput';
import Form from '../../components/Form/Form';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import Notification from '../../components/Notifications';
import { CloseCircleFilled } from '@ant-design/icons';
import { tenureTypes } from './constants';
import { toTitleCase } from '../../utils/index';

export default function AddLocker({ match, history }) {
	const [state, setStateHelper] = useState({
		terms: [
			{
				tenure: '',
				price: '',
				security_deposit: '',
				tenure_type: tenureTypes.DAY,
			},
		],
		isLoading: true,
		isError: false,
		lockerTypes: [],
		isSubmitting: false,
		locker_type: '',
	});

	const isSubmitDisabled = useMemo(() => {
		return !(
			state.terms.every((element) => {
				return Object.keys(element)?.every((termElement) => {
					if (termElement == 'tenure') return !!Number(element[termElement]);
					return !!element[termElement];
				});
			}) &&
			!!state?.locker_type &&
			!!state?.no_lockers
		);
	}, [JSON.stringify(state)]);

	const setState = (changes) => {
		setStateHelper((prevState) => ({ ...prevState, ...changes }));
	};

	const getLockerTypes = () => {
		setState({ isLoading: true });
		Api.get('locker-type/list')
			.then((data) => {
				setState({ isLoading: false, lockerTypes: data?.lockerTypes?.rows });
			})
			.catch((err) => setState({ isLoading: false, isError: true }));
	};
	useEffect(() => {
		getLockerTypes();
	}, []);

	const handleChange = (field, value) => {
		setState({ [field]: value });
	};

	const handleChangeTerms = (field, value, index) => {
		const { terms } = state;
		terms[index][field] = value;
		setState({ terms });
	};

	const addTerm = () => {
		setState({
			terms: [
				...state.terms,
				{
					tenure: '',
					price: '',
					security_deposit: '',
					tenure_type: tenureTypes.DAY,
				},
			],
		});
	};
	const deleteTerm = (index) => {
		const { terms } = state;
		terms.splice(index, 1);
		setState({ terms });
	};

	const handleSubmit = () => {
		const body = {
			locker_type_id: parseInt(state?.locker_type, 10),
			space_id: parseInt(match.params?.id, 10),
			number_of_lockers: parseInt(state?.no_lockers, 10),
			plans: state?.terms?.map((term) => ({
				tenure: parseInt(term?.tenure, 10),
				price: parseInt(term?.price, 10),
				security_deposit: parseInt(term?.security_deposit, 10),
				tenure_type: term?.tenure_type,
			})),
		};
		setState({ isSubmitting: true });
		Api.post('locker-price-plan', body)
			.then((data) => {
				setState({ isSubmitting: false });
				Notification.create(
					'success',
					'Lockers are added to the space successfully'
				);
				history.go(-1);
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};
	const { isLoading, isError, lockerTypes, isSubmitting } = state;

	if (isLoading) return <Loading />;
	if (isError)
		return (
			<div className='flex flex-col space-y-2 font-bold h-1/2 items-center justify-center'>
				<p>Something Went Wrong</p>
				<p>Please Try Again</p>
				<Button variant='primary' onClick={getLockerTypes}>
					Retry
				</Button>
			</div>
		);

	return (
		<>
			<p className='text-header-3 mb-10'>Add Lockers To Space</p>
			<Form onSubmit={handleSubmit} className='space-y-10 xl:w-3/4 mx-auto'>
				<div className='flex flex-col lg:flex-row'>
					<p className='text-header-3 p-5 lg:w-1/5 uppercase'>Locker Type</p>
					<PrimaryCard className='space-y-10 p-10'>
						<LabelInput
							label='Locker Type'
							type='select'
							placeholder='Choose type'
							rootClassName='w-full md:w-1/3'
							value={state?.locker_type ?? ''}
							onChange={(e) => handleChange('locker_type', e.target.value)}
							required
							options={lockerTypes?.map((el) => ({
								label: el?.type,
								value: el?.id,
							}))}
						/>
						<div className='space-y-5'>
							<p className='label text-black !text-[20px]'>Pricing Structure</p>
							{state?.terms?.map((term, index) => {
								return (
									<div className='flex flex-col'>
										<LabelInput
											rootClassName='mb-4'
											label='choose pricing tenure type'
											name={`tenureType-${index}`}
											type='radioGroup'
											variant='green'
											value={term?.tenure_type}
											onChange={(value) =>
												handleChangeTerms('tenure_type', value, index)
											}
											options={[
												{
													label: 'Days',
													value: tenureTypes.DAY,
													labelClassName: '!font-normal',
												},
												{
													label: 'Months',
													value: tenureTypes.MONTH,
													labelClassName: '!font-normal',
												},
												{
													label: 'Years',
													value: tenureTypes.YEAR,
													labelClassName: '!font-normal',
												},
											]}
										/>
										<div
											key={index}
											className='flex flex-wrap gap-5 items-center'
										>
											<LabelInput
												type='numberText'
												label={`No. of ${toTitleCase(term?.tenure_type)}s`}
												labelClassName="normal-case"
												value={term?.tenure ?? ''}
												placeholder={`Enter no. of ${term?.tenure_type?.toLowerCase()}s`}
												onChange={(e) => handleChangeTerms('tenure', e, index)}
												required
											/>
											<LabelInput
												type='numberText'
												label='Price in Rupees'
												labelClassName="normal-case"
												value={term?.price ?? ''}
												placeholder='Enter price for the term'
												onChange={(e) => handleChangeTerms('price', e, index)}
												required
											/>
											<LabelInput
												type='numberText'
												labelClassName="normal-case"
												label='Security Deposit'
												placeholder='Enter security deposit for the term'
												value={term?.security_deposit ?? ''}
												onChange={(e) =>
													handleChangeTerms('security_deposit', e, index)
												}
												required
											/>
											{index > 0 && (
												<Button
													variant='secondary'
													className='py-1 mt-10'
													onClick={() => deleteTerm(index)}
												>
													<CloseCircleFilled className='mr-1' /> Remove
												</Button>
											)}
										</div>
									</div>
								);
							})}
							<Button
								className='bg-subtle rounded-md text-white px-5 py-3 text-base font-bold'
								onClick={addTerm}
							>
								Add New Term
							</Button>
						</div>
						<LabelInput
							type='numberText'
							label='No.of Lockers in Space'
							value={state?.no_lockers ?? ''}
							rootClassName='w-full md:w-1/3'
							placeholder='Enter no. of lockers'
							onChange={(e) => handleChange('no_lockers', e)}
							required
						/>
					</PrimaryCard>
				</div>
				<div className='text-center'>
					<Button
						disabled={isSubmitDisabled}
						variant={isSubmitDisabled ? 'inactive' : 'cta'}
						className={`w-full lg:w-1/2 mx-auto !rounded-full ${
							isSubmitDisabled ? '!bg-[#CECECE] text-[#909090]' : ''
						}`}
						type='submit'
						isLoading={isSubmitting}
					>
						Add Lockers to Space
					</Button>
				</div>
			</Form>
		</>
	);
}
