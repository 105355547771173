// * builtins
import React, { useEffect, useState } from 'react';

// * components

import Modal from '../../components/Modal';
import Form from '../../components/Form/Form';
import LabelInput from '../../components/Form/LabelInput';
import Button from '../../components/Buttons/Button';

// * constants
import { tenureTypes } from './constants';

const AddAndUpdatePricing = ({
	title,
	values: { tenureType, tenure, formPrice, formSecurityDeposit },
	setValue: setState,
	originalData,
	onSubmit: onFormSubmit,
	isSubmitting = false,
	submitButtonText = '',
	modalId = ''
}) => {
	const [isFormDisabled, setIsFromDisabled] = useState(true);

	useEffect(() => {
		setIsFromDisabled(
			!(
				!!formSecurityDeposit &&
				!!formPrice &&
				!!Number(tenure) &&
				!!tenureType &&
				!(
					originalData?.price == formPrice &&
					originalData?.security_deposit == formSecurityDeposit &&
					originalData?.tenure == tenure &&
					(originalData?.tenure_type || tenureTypes?.DAY) == tenureType
				)
			)
		);
	}, [formSecurityDeposit, formPrice, tenure, tenureType]);

	return (
		<Modal
			title={title}
			className='p-10'
			onClose={() => setState({ [modalId]: false })}
		>
			<Form onSubmit={onFormSubmit} className='space-y-10 !w-[500px]'>
				<LabelInput
					label='choose tenure type'
					name='tenureType'
					type='radioGroup'
					variant='green'
					value={tenureType}
					onChange={(value) => setState({ tenureType: value })}
					options={[
						{
							label: 'Days',
							value: tenureTypes.DAY,
							labelClassName: '!font-normal',
						},
						{
							label: 'Months',
							value: tenureTypes.MONTH,
							labelClassName: '!font-normal',
						},
						{
							label: 'Years',
							value: tenureTypes.YEAR,
							labelClassName: '!font-normal',
						},
					]}
				/>
				<LabelInput
					label={`enter number of ${tenureType}s`}
					type='numberText'
					className='text-base pr-4 w-5/12'
					placeholder={`Enter number`}
					value={tenure}
					onChange={(value) => {
						setState({ tenure: value });
					}}
					required
				/>
				<div className='flex justify-between'>
					<LabelInput
						label='Enter New Price'
						type='numberText'
						className='text-base pr-4 w-full'
						placeholder='Enter price'
						value={formPrice}
						onChange={(value) => setState({ formPrice: value })}
						required
					/>
					<LabelInput
						label='Enter New Security Deposit'
						type='numberText'
						className='text-base pr-4 w-full'
						placeholder='Enter Security Deposit'
						value={formSecurityDeposit}
						onChange={(value) => setState({ formSecurityDeposit: value })}
						required
					/>
				</div>
				<div className='flex justify-center mt-8 space-x-5'>
					<Button
						variant='normal'
						className='w-1/2'
						onClick={() => setState({ editPricingModal: false })}
					>
						Cancel
					</Button>
					<Button
						disabled={isFormDisabled}
						variant={isFormDisabled ? 'inactive' : 'cta'}
						className={`w-1/2 !rounded-full ${
							isFormDisabled ? '!bg-[#CECECE] text-[#909090]' : ''
						}`}
						type='submit'
						isLoading={isSubmitting}
					>
						{submitButtonText}
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default AddAndUpdatePricing;
