// ** builtins
import React, { useEffect, useState } from 'react';

// ** components
import KeyValue from '../Form/KeyValue';
import Button from '../Buttons/Button';
import ImageViewer from '../ImageViewer/ImageViewer';
import Modal from '../Modal';
import RenewLockerModal from '../../pages/Renewal/RenewLockerModal';
import moment from 'moment';
import Api from '../../utils/ApiUtils/Api';

const RenewalCard = ({
	locker_number: lockerNumber,
	physical_id: physicalId,
	key_number: keyNumber = '',
	subscription_date: subscriptionDate,
	expiry_date: expiryDate,
	payment,
	tenure,
	is_active: isActive,
	renewOptions,
	previewOptions,
	customerName,
	mobileNumber,
	space_id: spaceId,
	locker_type_id: lockerTypeId,
	locker_id: lockerId,
	safe_subscription_id: subscriptionId,
}) => {
	const [isViewProof, setIsViewProof] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [images, setImages] = useState([]);
	const onModalClose = (value) => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (previewOptions?.image?.imageUrl) {
			(async () => {
				try {
					const response = await Api.post(`/images`, {
						urls: [previewOptions?.image?.imageUrl],
					});
					setImages((prev) => [
						...prev,
						{
							caption: 'Proof',
							source: response.signedUrls[0],
						},
					]);
				} catch (error) {
					console.log(error);
				}
			})();
		}
	}, [JSON.stringify(previewOptions)]);
	return (
		<div className='border-[1px] min-w-[450px] border-[#E6E6E6] p-[24px] rounded-[8px] bg-white shadow-lg'>
			<div className='mb-4 uppercase text-[#909090] text-[16px] font-bold'>
				subscription ID:{' '}
				<span className='ml-1 text-[#454545]'>{subscriptionId}</span>
			</div>
			<div className='bg-[#F9F9F9] p-[20px] flex justify-around items-center'>
				<div className='flex items-center'>
					<KeyValue
						labelStyle='text-[10px] text-[#909090] text-center'
						label='oro safe id'
						value={lockerNumber}
						class='text-[16px] text-[#454545] font-bold text-center'
					/>
				</div>
				<hr className='bg-[#E6E6E6] h-[2px] w-[30px] rotate-90' />
				<div className='flex items-center'>
					<KeyValue
						labelStyle='text-[10px] text-[#909090] text-center'
						label='godrej id'
						value={physicalId}
						class='text-[16px] text-[#454545] font-bold text-center'
					/>
				</div>
			</div>
			<div className='flex items-center justify-between mt-[24px]'>
				<div className='w-[35%]'>
					<KeyValue
						labelStyle='text-[10px] text-[#909090] text-left'
						label='subscription date'
						value={moment(subscriptionDate).format('DD-MM-YYYY')}
						class='text-[16px] text-[#454545] font-bold text-left'
					/>
				</div>
				<hr className='bg-[#E6E6E6] h-[2px] w-[30%]' />
				<div className='w-[35%]'>
					<KeyValue
						labelStyle='text-[10px] text-[#909090] text-right'
						label='expiry date'
						value={moment(expiryDate).format('DD-MM-YYYY')}
						class='text-[16px] text-[#454545] font-bold text-right'
					/>
				</div>
			</div>
			<hr className='bg-[#E6E6E6] w-full mt-[22px] h-[1px]' />
			<div className='mt-[24px] justify-between flex'>
				<div className='w-[80px] flex-col'>
					<KeyValue
						labelStyle='text-[10px] text-[#909090] text-left'
						label='amount paid'
						value={`₹ ${payment?.amount}`}
						class='text-[16px] font-bold text-left text-[#5A953E] whitespace-nowrap'
					/>
					<div className='text-[#909090] text-[12px] whitespace-nowrap'>
						Payment mode : {`${payment?.method}`}
					</div>
				</div>
				<div className='flex gap-2'>
					<div className='border-[1px] border-[#F9CA36] h-[30px] px-[10px] py-[4px] text-black text-[12px] rounded-[32px] font-bold flex justify-center items-center'>
						<div>{tenure}</div>
					</div>
					<div>
						{isActive ? (
							<div className='bg-[#454545] text-white border-[1px] border-[#454545] h-[30px] px-[10px] py-[4px] text-[12px] rounded-[32px] font-bold uppercase flex justify-center items-center'>
								<div>Active</div>
							</div>
						) : (
							<div className='bg-[#454545] text-white border-[1px] border-[#454545] h-[30px] px-[10px] py-[4px] text-[12px] rounded-[32px] font-bold uppercase flex justify-center items-center'>
								<div>Inactive</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{renewOptions ? (
				renewOptions?.isRenew ? (
					<Button
						onClick={() => {
							setIsModalOpen(true);
						}}
						variant='cta'
						className='text-xs font-bold py-[15px] text-center rounded-lg w-full text-[16px] mt-[30px] h-[40px]'
					>
						Renew now
					</Button>
				) : (
					<Button
						variant='cta'
						className='text-xs font-bold !bg-[#CECECE] !opacity-80 px-[30px] text-center rounded-lg !cursor-not-allowed !shadow-none !bg-none w-full text-[16px] mt-[30px] h-[40px]'
						disabled
					>
						Renewal will be available 1 month before expiry date
					</Button>
				)
			) : null}
			{previewOptions?.isPreview ? (
				<Button
					onClick={() => setIsViewProof(true)}
					variant='preview'
					className='border-[#F9CA36] border-[1px] text-xs font-bold py-[15px] text-center rounded-lg text-[16px] mt-[30px] h-[40px]'
				>
					View Proof
				</Button>
			) : null}

			{isViewProof ? (
				<ImageViewer
					images={images}
					onClose={() => {
						setIsViewProof(false);
					}}
				></ImageViewer>
			) : null}
			{isModalOpen ? (
				<Modal
					title='Renew Locker'
					onClose={onModalClose}
					className='w-[670px] h-[800px] p-[40px]'
				>
					<RenewLockerModal
						renewalData={{
							lockerNumber: lockerNumber,
							customerName: customerName,
							startDate: subscriptionDate,
							endDate: expiryDate,
							mobileNumber: mobileNumber,
							spaceId: spaceId,
							lockerTypeId: lockerTypeId,
							lockerId: lockerId,
						}}
						onModalClose={onModalClose}
					/>
				</Modal>
			) : null}
		</div>
	);
};

export default RenewalCard;
