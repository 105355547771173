import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import OroSafeLogo from '../../assets/oro-safe.svg';
import EditIcon from '../../assets/edit.svg';
import { generateID, getVisitDisplayId, randomIntFromInterval } from '../../utils';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Button from '../../components/Buttons/Button';
import Link from '../../components/Link';
import Loading from '../../components/loading';
import HasuraApi from '../../utils/ApiUtils/HasuraApi';
import Notification from '../../components/Notifications';


export default function FinishVisit(props) {
  const {state: stepperState} = props;
  // const [goldData, setGoldData] = useState([]);
  const [state, setStateHelper] = useState({
    visitFinished: false,
    isLoading: false,
    isError: false,
    isSubmitted: false,
  });
  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));
  const { id: visitId } = useParams();
  
  // const columns = [
  //   {
  //     title: 'Oro Safe ID',
  //     dataIndex: 'oro_safe_id',
  //     key: 'oro_safe_id',
  //     render: (text) => <p className="text-body-bold p-2">{text}</p>,
  //   },
  //   {
  //     title: 'Gold Items',
  //     dataIndex: 'gold_items',
  //     key: 'gold_items',
  //     width: 200,
  //     render: (text) => <p className="text-body-bold p-2">{text}</p>,
  //   },
  //   {
  //     title: 'Quality',
  //     dataIndex: 'quality',
  //     key: 'quality',
  //     render: (text) => <p className="text-body-bold p-2">{text}</p>,
  //   },
  //   {
  //     title: 'Quantity',
  //     dataIndex: 'quantity',
  //     key: 'quantity',
  //     render: (text) => <p className="text-body-bold p-2">{text}</p>,
  //   },
  //   {
  //     title: 'Net Weight',
  //     dataIndex: 'net_weight',
  //     key: 'net_weight',
  //     render: (text) => <p className="text-body-bold p-2">{text}</p>,
  //   },
  //   {
  //     title: 'Gold Photos',
  //     dataIndex: 'gold_photos',
  //     key: 'gold_photos',
  //     width: 180,
  //     render: () => (
  //       <Button variant="secondary" type="button" className="px-3 text-xs font-bold text-subtle">
  //         view Photo
  //       </Button>
  //     ),
  //   },
  //   {
  //     title: 'Caratmenter Photos',
  //     dataIndex: 'caratmeter_photo',
  //     key: 'caratmeter_photo',
  //     render: () => (
  //       <Button variant="secondary" type="button" className="px-3 text-xs font-bold text-subtle">
  //         view Photo
  //       </Button>
  //     ) },
  //   {
  //     title: 'Certificate',
  //     dataIndex: 'certificate',
  //     key: 'certificate',
  //     width: 200,
  //     render: () => (
  //       <Link variant="primary" type="button" className="text-sm" to="/digital-certificates/details">
  //         View  Certificate
  //       </Link>
  //     ),
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'action',
  //     fixed: 'right',
  //     key: 'action',
  //     width: 50,
  //     render: () => <img src={EditIcon} alt="" />,
  //   },
  // ];
  const completeVisit = () => {
    const completeVisitQuery = {
      variables: {
        id: parseInt(visitId, 10),
        intermediate_status: '-',
      },
      query: `
      mutation myMutation(
        $id: Int!
        $visit_status: visit_status_enum = "VISIT_COMPLETED"
        $intermediate_status:String
      ) {
        update_visits_by_pk(
          pk_columns: { id: $id }
          _set: { visit_status: $visit_status, intermediate_status: $intermediate_status}
        ) {
          id
          visit_status
          intermediate_status
        }
      }
    `,

    };
    setState({isSubmitted: true});
    HasuraApi.post('/v1/graphql', completeVisitQuery).then(
      (data) => {
        if (data?.data) {
          Notification.create('success', 'Visit Completed Successfully');
          setState({isSubmitted: false, visitFinished: true});
        }
      },
    ).catch(err => setState({isSubmitted: false, isError: true}));
  };

  const {isLoading, visitFinished, isSubmitted} = state;
  if (isLoading) return <Loading />;
  return (
    <div className="space-y-10">
      {(visitFinished || stepperState.visit_status === 'VISIT_COMPLETED') ? (
        <PrimaryCard className="p-10 w-3/4 space-y-10 mx-auto flex flex-col items-center text-center">
          <img src={OroSafeLogo} className="mx-auto" alt="oro-safe" />
          <p className="text-body-large w-full md:w-1/2">
            {getVisitDisplayId(visitId)}
            {' '}
            is Complete
          </p>
          <Link variant="cta" className="w-full md:w-1/2" to={`/visits/locker-visit-details/${visitId}`}>
            View Visit Summary
          </Link>
        </PrimaryCard>
      ) : (
        <div className="space-y-10">
          <PrimaryCard className="w-full xl:w-3/4 space-y-10 mx-auto flex flex-col items-center text-center">
            <img src={OroSafeLogo} className="mx-auto" alt="oro-safe" />
            <p className="text-body-large w-3/4 lg:w-1/2">
              If the visit is complete, please click Finish Visit to end the visit
            </p>
            <div className="flex sm:flex-row  flex-col gap-5 w-full  md:w-3/4 mx-auto justify-center">
              {/* <Button type="button" variant="normal" className="w-3/4 md:w-1/2 mx-auto bg-white border border-[#CECECE]"
                onClick={() => setCurrent(current - 1)}
              >
                Add Another Digital Certificate
              </Button> */}
              <Button variant="cta" type="submit" className="w-3/4 mx-auto md:w-1/2" isLoading={isSubmitted} 
                onClick={() => completeVisit()}>
                Finish Visit
              </Button>
            </div>
          </PrimaryCard>
          {/* <Table scroll={{ x: 1500 }} columns={columns} dataSource={goldData} pagination={false} /> */}
        </div>
      )}
    </div>
  );
}
