import { addToLocalStorage, getValueFromLocalStorage, removeFromLocalStorage } from "./local-storage";

const AuthUtils = {
  getToken() {
    return getValueFromLocalStorage("token");
  },
  getRefreshToken() {
    return getValueFromLocalStorage("refreshToken");
  },
  setToken(token) {
    addToLocalStorage("token", token);
  },
  login(data) {
    addToLocalStorage("token", data?.token);
    addToLocalStorage("refreshToken", data?.refreshToken);
  },
  logout() {
    removeFromLocalStorage("token");
    removeFromLocalStorage("refreshToken");
    removeFromLocalStorage("userPermissions");
    window.location.reload();
  },
  isLoggedIn() {
    if (getValueFromLocalStorage("token") && getValueFromLocalStorage("refreshToken"))
      return true;
    return false;
  },
};

export default AuthUtils;
