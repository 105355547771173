import API from './ApiUtils/Api';
import permissions from './permissionConfig';

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
function generateID(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function generateIntegerID(length) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
const indianStates = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
  "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh",
  "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu",
  "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
  "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Lakshadweep", "Puducherry"];

const indianCities = ["Chennai", "Hyderabad", "Bengaluru","Pune", "Vijaywada","Kochi"];

const getCustomerDisplayId = (id) =>
  (id ? `OSC${"0".repeat((6 - id.toString().length) > 0 ? 6 - id.toString().length : 0)}${id}` : 'NIL');
const getVisitDisplayId = (id) => 
  (id ? `OSV${"0".repeat((6 - id.toString().length) > 0 ? 6 - id.toString().length : 0)}${id}` : 'NIL');

const getFullName = (firstName, lastName) => {
  if (firstName === lastName) return firstName;
  return firstName && lastName ? `${firstName} ${lastName}` : firstName;
};

const toTitleCase = (phrase = '') => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getCityAndStateDataFromPincode = async (pincode) => {
  try {
    const data = await API.get(`/api/v1/website/${pincode}`, {
      baseURL: process.env.REACT_APP_SALES_API_BASE_URL,
    });

    return {
      cityName: toTitleCase(data?.data?.city),
      stateName: toTitleCase(data?.data.state)
    };
  } catch (err) {
    return {};
  }
};


const fetchPermittedRoute = (validPermissions) => {
  const userPermissions = new Set(validPermissions);
  const routesPermissionMap = {
    '/visits': permissions.visits.permission,
    '/spaces': permissions.spaces.permission,
    '/locker-types': permissions.locker_types.permission,
    '/customers': permissions.customers.permission,
  };
  for (let key in routesPermissionMap) {
    if (userPermissions.has(routesPermissionMap[key][0])) return key;
  }
  return '/';
};


function intersect(a = [], b = []) {
  const filtered = a.filter(Set.prototype.has, new Set(b));
  return Array.from(new Set(filtered));
}

const checkPermission = (requiredPermissions, userPermissions, looseCheck) => {
  if (requiredPermissions.length === 0) return false;
  const intersectionLength = intersect(
    userPermissions,
    requiredPermissions
  )?.length;
  const allowed = looseCheck
    ? intersectionLength > 0 // if any permission matches
    : intersectionLength === requiredPermissions.length; // if all permissions are given to user
  return allowed;
};

export {
  checkPermission,
  fetchPermittedRoute,
  randomDate,
  getFullName,
  randomIntFromInterval,
  generateID,
  generateIntegerID,
  getCustomerDisplayId,
  getVisitDisplayId,
  indianStates,
  indianCities,
  getCityAndStateDataFromPincode,
  toTitleCase
};
