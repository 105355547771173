/* eslint-disable camelcase */
/* eslint-disable indent */
const permissions = {
  visits: {
    permission: ["VIEW_VISITS_SAFE"],
    create_locker_visit: {
      permission: ["CREATE_NEW_MANUAL_VISIT_SAFE"]
    },
    create_doorstep_visit: {
      permission: ["CREATE_NEW_MANUAL_VISIT_SAFE"]
    },
    table_row: {
      permission: ["VIEW_VISITS_SAFE"],
      customer_details: {
        permission: ["VIEW_USER_SAFE"]
      },
      space_details: {
        permission: ["VIEW_SPACE_SAFE"]
      },
      resume_visit: {
        permission: ["EDIT_VISIT_SAFE"]
      }
    }
  },
  spaces: {
    permission: ["VIEW_SPACE_SAFE"],
    create_new_space: {
      permission: ["CREATE_SPACE_SAFE"]
    },
    table_row: {
      permission: ["VIEW_SPACE_SAFE"],
      add_lockers_to_space: {
        permission: ["EDIT_SPACE_SAFE"]
      },
      create_new_locker_type: {
        permission: ["CREATE_LOCKER_SAFE"]
      },
      table_row: {
        view_lockers: {
          permission: ["VIEW_LOCKER_SAFE"]
        },
        deactivate: {
          permission: ["EDIT_LOCKER_SAFE"]
        }
      }
    }
  },
  locker_types: {
    permission: ["VIEW_LOCKER_SAFE"],
    create_new_locker_type: {
      permission: ["CREATE_LOCKER_SAFE"]
    },
    table_row: {
      deactivate: {
        permission: ["EDIT_LOCKER_SAFE"]
      }
    }
  },
  customers: {
    permission: ["VIEW_USER_SAFE"],
    table_row: {
      permission: ["VIEW_USER_SAFE"],
      visit_id_details: {
        permission: ["VIEW_VISITS_SAFE"]
      }
    }
  },
  other: {
    permission: []
  }
};

export default permissions;
