import React from "react";
import twMerge from "../CustomMerge";

export default function Button({ children, className = '', isLoading =false, disabled=false, 
  onClick, variant='', type = "button" }) {
  const variantClassNameMap = {
    primary: 'bg-medium-yellow px-5 py-2 rounded-lg text-base font-bold',
    secondary: 'bg-white px-5 border border-[#454545] py-2 rounded-lg text-base font-bold',
    normal: 'border border-[#454545] text-base font-bold px-5 py-3.5  rounded-full',
    cta: 'bg-gradient-to-b from-[#F9CA36] to-[#F8B830] text-base font-bold px-5 py-3.5 rounded-full shadow-lg',
    active: 'bg-new-green px-5 py-2 rounded-lg text-base font-bold text-white',
    inactive: 'rounded-lg px-5 py-2 border border-gray-300 text-base font-bold text-gray-400',
    preview: 'py-1 px-3 border border-[#454545] rounded-lg bg-white text-xs text-black',
  };

  return (
    <button type={type} onClick={onClick} disabled={disabled || isLoading} 
      className={twMerge(`text-center flex gap-2 justify-center items-center`, variantClassNameMap[variant] ??''
        , className) }>
      {isLoading && (
        <svg className={`animate-spin w-4 h-4 ${variant == 'cta' ? 'text-white' : 
          'text-black'}`} xmlns="http://www.w3.org/2000/svg" fill="none" 
        viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 
          0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      )}
      {children}
    </button>
  );
}
