import React, { useState } from "react";

// components
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import Button from "../../components/Buttons/Button";
import LabelInput from "../../components/Form/LabelInput";
import Notification from "../../components/Notifications";

// services
import assignNewLocker from "./services/assignNewLocker";

const NewDoorstepLockerModal = ({
  isOpen = false,
  onClose,
  showSuccessModal,
}) => {
  if (!isOpen) {
    return null;
  }

  const { listOfSpaces, listOfLockerTypes, createNewDoorstepLocker, isError } =
    assignNewLocker();

  if (isError) {
    return null;
  }

  const [state, updateState] = useState({
    spaceId: null,
    lockerTypeId: null,
    godrejId: null,
  });

  // helpers
  const setState = (newStateObj) =>
    updateState((prev) => ({ ...prev, ...newStateObj }));

  const handleNewDoorstepLockerCreation = async () => {
    const { spaceId, lockerTypeId, godrejId } = state;

    try {
      await createNewDoorstepLocker(godrejId, lockerTypeId, spaceId);
      onClose();
      showSuccessModal(`Locker with Godrej ID ${godrejId} is assigned`);
    } catch (err) {
      Notification.create(
        "error",
        `Locker with Godrej ID ${godrejId} cannot be assigned. Please report the error.`
      );
    }
  };

  return (
    <Modal
      title="Assign Locker to doorstep lockers"
      onClose={onClose}
      className="w-4/5 md:w-1/2 xl:w-2/5 p-10"
    >
      <Form onSubmit={handleNewDoorstepLockerCreation} className="space-y-10">
        <LabelInput
          label="Space"
          type="select"
          className="text-base mb-8"
          placeholder="Choose Space"
          options={listOfSpaces?.map((space) => ({
            label: `${space?.space_number}-${space?.name}`,
            value: space?.id,
          }))}
          value={state.spaceId}
          onChange={(e) => setState({ spaceId: e.target.value })}
          required
        />
        <div className="flex flex-wrap">
          <div className="space-y-4 w-1/3">
            <LabelInput
              label="Locker Type"
              type="select"
              className="text-base"
              placeholder="Choose Type"
              options={listOfLockerTypes?.map((lockerType) => ({
                label: `${lockerType?.type}-${lockerType?.id}`,
                value: lockerType?.id,
              }))}
              value={state.lockerTypeId}
              onChange={(e) => setState({ lockerTypeId: e.target.value })}
              required
            />
          </div>
          <div className="w-2/3 pl-4">
            <LabelInput
              label="Godrej ID"
              type="text"
              className="text-base"
              placeholder="Enter Godrej ID here"
              value={state.godrejId}
              onChange={(e) => setState({ godrejId: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="flex gap-5">
          <Button variant="normal" className="w-full" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="cta" className="w-full" type="submit">
            Assign Locker
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NewDoorstepLockerModal;
