import React from "react";
import styles from "./Radio.module.scss";

export default function Radio({
  type,
  label,
  labelClassName,
  onChange,
  className,
  checked,
  ...rest
}) {
  let preference;
  switch (type) {
  case "green":
    preference = styles.green;
    break;
  default:
    break;
  }
  return (
    <div className={`flex space-x-5 items-center ${preference} ${className}`}>
      <input
        type="radio"
        onClick={onChange}
        className="cursor-pointer"
        checked={checked}
        {...rest}
      />
      {!!label &&
        <p
          className={`text-base font-bold cursor-pointer ${labelClassName ?? ""}`}
          onClick={onChange}
        >
          {label}
        </p>}
    </div>
  );
}
