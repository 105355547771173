import { uniqueId } from 'lodash';

class Notifications{
  constructor(){
    this.notifications = [];
    this.subscriptions = [];
  }
  create(type, message){
    const id = uniqueId();
    this.notifications = ([...this.notifications, { type, message, id}]);
    this.subscriptions.map((func) => func(this.notifications));
    setTimeout(() => this.deleteNotification(id), 3000)
  }
  deleteNotification(id){
    this.notifications = this.notifications.filter((el) => el.id!==id);
    this.subscriptions.map((func) => func(this.notifications));
  }
  subscribe(func) {
    this.subscriptions.push(func);
  }
  unsubscribe(func){
    this.subscriptions = this.subscriptions.filter((el) => el!==func );
  }
}

const Notification = new Notifications();

export default Notification; 
