import React, { useEffect, useState } from 'react';
import NotificationBar from './components/Notifications/NotificationBar';
import AuthenticatedRoutes from './routes';
import UnAuthenticated from './routes/unAuthenticated';
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import AuthApi from './utils/ApiUtils/AuthApi';
import { getValueFromLocalStorage, addToLocalStorage } from './utils/local-storage';
import {ReactComponent as Spinner} from './assets/Spinner.svg';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const[permissionsAdded, setPermissionsAdded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [verificationComplete, setVerificationComplete] = useState(false);

  useEffect(() => {
    if (getValueFromLocalStorage('token')) {
      AuthApi.verifyToken()
        .then((res) => {
          const permissions = res?.user?.permissions;
          if (!getValueFromLocalStorage('userPermissions')) {
            addToLocalStorage('userPermissions', permissions);
          }
          setPermissionsAdded(true);
          setAuthenticated(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setVerificationComplete(true);
        });
    } else {
      setLoading(false);
      setVerificationComplete(true);
    }
  }, [isAuthenticated]);

  if (!verificationComplete) {
    return <div className="self-center mt-10 h-full w-full flex items-center justify-center">
            <Spinner className="animate-spin" width={30} height={30}  color="black" />
           </div>
  }

  return (
    <>
      <Spin indicator={antIcon} spinning={isLoading || false}>
        <NotificationBar />
        <div className='App'>
          {isAuthenticated ? (
            <AuthenticatedRoutes setAuthenticated={setAuthenticated} permissionsAdded={permissionsAdded} />
          ) : (
            <UnAuthenticated setAuthenticated={setAuthenticated} />
          )}
        </div>
      </Spin>
    </>
  );
}

export default (App);
