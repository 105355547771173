import { toTitleCase } from '../../utils';
import Api from '../../utils/ApiUtils/Api';
import { tenureTypes } from '../Spaces/constants';

const getPricing = async ({ spaceId = 1, lockerTypeId }) => {
	try {
		const data = await Api.get(
			`/locker-price-plan/${lockerTypeId}/space/${spaceId}`
		);
		return data?.lockerPlan?.rows?.map((element) => ({
			...element,
			tenureString: `${element?.tenure} ${
				element?.tenure > 1
					? `${toTitleCase(element?.tenure_type)}s`
					: toTitleCase(element?.tenure_type)
			}`,
		}));
	} catch (err) {
		return { isLoading: false, isError: true };
	}
};

const getTenureType = (tenure) => {
	if (tenure.toLowerCase().includes(tenureTypes.MONTH.toLowerCase())) {
		return tenureTypes.MONTH;
	} else if (tenure.toLowerCase().includes(tenureTypes.DAY.toLowerCase())) {
		return tenureTypes.DAY;
	} else if (tenure.toLowerCase().includes(tenureTypes.YEAR.toLowerCase())) {
		return tenureTypes.YEAR;
	}
};

export { getTenureType, getPricing };
