import React from "react";
import { Input } from "antd";
import PrimaryCard from "../../../../../components/Cards/PrimaryCard";
import LabelInput from "../../../../../components/Form/LabelInput";
import { indianStates } from "../../../../../utils";
const { TextArea } = Input;

export default function NomineeDetailsCard({ state, onChange }) {
  const { firstName, lastName, mobileNumber, address } = state;
  const { addrLine1, pincode, state: selectedState, city } = address;
  return (
    <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
      {/* // CUSTOMER FIRST NAME AND LAST NAME */}
      <div className="flex gap-6 flex-col md:flex-row pt-6">
        <LabelInput
          label="first name"
          type="text"
          placeholder="Enter Customer First Name"
          rootClassName="w-1/2"
          value={firstName}
          onChange={(e) => onChange("firstName", e)}
          required
        />
        <LabelInput
          label="last name"
          type="text"
          placeholder="Enter Customer Last Name"
          rootClassName="w-1/2"
          value={lastName}
          onChange={(e) => onChange("lastName", e)}
          required
        />
      </div>
      {/* // CUSTOMER Mobile Number */}
      <div className="flex gap-6 flex-col md:flex-row pt-6">
        <LabelInput
          label="Mobile Number"
          type="numberText"
          placeholder="Enter Customer Mobile Number"
          rootClassName="w-1/2"
          value={mobileNumber}
          maxLength={10}
          onChange={(e) => onChange("mobileNumber", e)}
          required
        />
      </div>
      {/* // CUSTOMER ADDRESS  */}
      <div className="flex flex-col space-y-4 pt-6">
        <p className="label">permanent address</p>
        <TextArea
          rows={5}
          autoSize={false}
          className="rounded-lg border-[#CECECE] text-base font-bold py-3 pl-2 border outline-none"
          placeholder="Enter Customer Address"
          value={addrLine1}
          onChange={(e) => onChange("addr_line_1", e)}
          style={{
            resize: "none",
            outline: "none"
          }}
        />
        <div className="flex gap-5 flex-wrap">
          <LabelInput
            label="city"
            type="text"
            placeholder="City"
            rootClassName="w-1/4"
            value={city}
            required
            onChange={(e) => onChange("city", e)}
          />
          <LabelInput
            label="Pincode"
            type="numberText"
            placeholder="Pincode"
            rootClassName="w-1/4"
            value={pincode}
            required
            onChange={(e) => onChange("pincode", e)}
            maxLength={6}
          />
          <LabelInput
            label="State"
            type="select"
            placeholder="State"
            rootClassName="w-1/4"
            value={selectedState}
            options={indianStates}
            
            required
            onChange={(e) => onChange("state", e)}
          />
        </div>
      </div>
    </PrimaryCard>
  );
}
