import React from "react";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import KeyValue from "../../../../components/Form/KeyValue";

export default function AgentDetailsCard({ visitData }) {
  return (
    <PrimaryCard className="flex">
      <div className="border-r border-[#E6E6E6] pr-14 mr-10">
        <p className="text-header-3 mb-5">
          Locker
          <br />
          Visit
        </p>
        <p className="bg-oro-yellow rounded-lg px-3 py-2 m-0 text-small-bold">
          {visitData?.visit_display_id}
        </p>
      </div>
      <div className="space-y-5">
        <p className="text-body-bold mr-5">Locker Agent</p>
        <div className="flex">
          <div className="mr-2 space-y-5">
            <KeyValue label="Agent name" value={visitData?.agent?.first_name} />
            <KeyValue
              label="Agent mobile number"
              value={visitData?.agent?.mobile_number}
              class="m-0"
            />
          </div>
          <KeyValue
            label="Agent id"
            absolute
            link={`${process.env.REACT_APP_ADMIN_URL}/agents/view/${visitData?.agent?.id}`}
            value={visitData?.agent?.agent?.agent_display_id}
            class="font-bold text-[#5A953E]"
          />
        </div>
      </div>
    </PrimaryCard>
  );
}
