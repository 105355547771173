import React from 'react';
import {
  Redirect, Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';
import LandingPage from '../pages/LandingPage';

const UnAuthenticated = ({ setAuthenticated }) => (
  <Router>
    <Switch>
      <Route path="/" exact
        render={() => <LandingPage setAuthenticated={setAuthenticated} />}
      />
      <Route
				path='*'
				component={() => {
					window.location.state = {
						url: window?.location?.pathname,
						hash: 'initialRoute',
					};
					return <Redirect to='/' />;
				}}
			/>
    </Switch>
  </Router>
);

export default UnAuthenticated;
