import React, { useEffect, useState } from "react";
import Input from "./Input";
import DeleteIcon from '../../assets/delete.svg';
import Notification from "../Notifications";
import Api from "../../utils/ApiUtils/Api";
import ImageModal from "../ImageModal";


export default function SelectWithUpload({ label, labelClassName, className, rootClassName, value, onChange, ...rest }) {
  const [frontSide, setFrontSide] = useState(null);
  const [backSide, setBackSide] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploadingFront, setUploadingFront] = useState(false);
  const [isUploadingBack, setUploadingBack] = useState(false);


  useEffect(() => {
    let data = value;
    if (!data) {
      data = {};
    }
    data.image_type = imageType;
    data.front_side = frontSide?.url;
    data.back_side = backSide?.url;
    onChange(data);
  }, [frontSide, backSide, imageType]);

  const handleChangeImage = async(type, data) => {
    try{
      if(type === 'front_side'){
        setUploadingFront(true)
        const url = await Api.uploadImage(data);
        setFrontSide({name: data.name, url: url});
        setUploadingFront(false)
      }
      else {
        setUploadingBack(true)
        const url = await Api.uploadImage(data);
        setBackSide({name: data.name, url: url});
        setUploadingBack(false)
      }
    }
    catch(err){
      Notification.create('error', "Unable To Upload Image");
    }
  }

  return (
    <>
      <div className={`space-y-2 ${rootClassName ?? ''}`}>
        <p className={`text-base font-bold text-[#909090] uppercase ${labelClassName ?? ''}`}>
          {label}
        </p>
        <div className={`flex space-x-3 w-full relative ${className ?? ''}`}>
          <div className="space-y-1 w-full">
            <Input type="select"  value={imageType} className="w-full"
              onChange={(e) => setImageType(e.target.value)}
              {...rest} required
            />
            {!!frontSide && (
              <p className="flex space-x-5 cursor-pointer" key={frontSide?.path} onClick={() => setPreview(frontSide.url)}>
                <span className="w-36 overflow-hidden whitespace-nowrap overflow-ellipsis">{frontSide?.name}</span>
                <span className="cursor-pointer" onClick={() => setFrontSide(null)}><img src={DeleteIcon} alt="delete" /></span>
              </p>
            )}
            {!!backSide && (
              <p className="flex space-x-5 cursor-pointer" key={backSide?.path} onClick={() => setPreview(backSide.url)}>
                <span className="w-36 overflow-hidden whitespace-nowrap overflow-ellipsis">{backSide?.name}</span>
                <span className="cursor-pointer" onClick={() => setBackSide(null)}><img src={DeleteIcon} alt="delete" /></span>
              </p>
            )}
          </div>
          <div className="space-y-3 w-3/4">
            <Input type="fileBtn" maxFiles={1} placeholder={frontSide ? "Reupload" : "Upload"}
              accept=".jpg, .jpeg, .png" isUploading={isUploadingFront}
              value={frontSide} disabled={!imageType}
              onChange={(data) => handleChangeImage('front_side', data[0])}
            />
            {(!!frontSide || !!backSide) && (
              <Input type="fileBtn" maxFiles={1} value={backSide}
                accept=".jpg, .jpeg, .png" isUploading={isUploadingBack}
                inputClassName="bg-transparent border border-gray-400 w-full px-2"
                placeholder={backSide ? "Reupload Backside" : 'Upload Backside'}
                onChange={(data) => handleChangeImage('back_side', data[0])}
              />
            )}
          </div>
        </div>
      </div>
      {preview && (
        <ImageModal imageUrl={preview}  onClose={() => setPreview(null)} />
      )}
    </>
  );
}
