import React, { useState } from "react";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import Input from "../../../../components/Form/Input";
import { DOORSTEP_CUSTOMER_TYPES } from "../../../../constants/DoorstepVisitsRadioButtonOptions";
import ManageDoorstepLockerForm from "./ManageDoorstepLockerForm";
import NewCustomerForm from "./NewCustomerForm";
import NewSubscriptionForm from "./NewSubscriptionForm";

export default function CreateDoorstepVisit() {
  const [state, setState] = useState({
    visitSubType: DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_CUSTOMER.value
  });

  const onChangeCustomerType = (event) => {
    setState({ visitSubType: event });
  };

  // destructure state here
  const { visitSubType } = state;

  const renderCreatVisitForm = () => {
    // Render new customer form
    if (
      visitSubType === DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_CUSTOMER.value
    ) {
      return <NewCustomerForm visitSubType={visitSubType} />;
    }

    // render new subscription form
    if (
      visitSubType ===
      DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_SUBSCRIPTION.value
    ) {
      return <NewSubscriptionForm visitSubType={visitSubType} />;
    }

    return (
      <ManageDoorstepLockerForm visitSubType={visitSubType} />
    );
  };

  return (
    <div className="space-y-10">
      <div>
        <p className="text-header-3">Create New Visit</p>
      </div>
      <div className="flex lg:flex-row flex-col items-start">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">choose customer</p>
        <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
          <Input
            value={visitSubType}
            type="radioGroup"
            name="customer_type"
            variant="green"
            onChange={(event) => onChangeCustomerType(event)}
            options={[
              {
                label: DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_CUSTOMER.label,
                value: DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_CUSTOMER.value,
                className:
                  visitSubType ===
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_CUSTOMER.value
                    ? ""
                    : "text-subtle"
              },
              {
                label:
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_SUBSCRIPTION.label,
                value:
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_SUBSCRIPTION.value,
                className:
                  visitSubType ===
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_NEW_SUBSCRIPTION.value
                    ? ""
                    : "text-subtle"
              },
              {
                label:
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_MANAGE_SUBSCRIPTION
                    .label,
                value:
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_MANAGE_SUBSCRIPTION
                    .value,
                className:
                  visitSubType ===
                  DOORSTEP_CUSTOMER_TYPES.SAFE_DOORSTEP_MANAGE_SUBSCRIPTION
                    .value
                    ? ""
                    : "text-subtle"
              }
            ]}
          />
        </PrimaryCard>
      </div>
      <div className="flex flex-col lg:flex-row items-start">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">create Visit</p>
        {renderCreatVisitForm()}
      </div>
    </div>
  );
}
