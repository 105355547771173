import React, { useState } from "react";
import { debounce } from "lodash";

// components
import { Table } from "antd";
import Button from "../../components/Buttons/Button";
import NewDoorstepLockerModal from "./NewDoorstepLockerModal";
import Success from "../../components/Modals/Success";

// icons
import { SearchOutlined } from "@ant-design/icons";

// services
import getDoorstepLockers from "./services/getDoorstepLockers";

// utils
import { doorstepLockerTableColumns } from "./constants";

const DoorstepLockers = () => {
  const [state, updateState] = useState({
    isNewDoorstepLockerModalOpen: false,
    showSuccessModal: false,
    successMessage: "",
    searchValue: "",
    pageNumber: 1,
  });

  const {
    listOfDoorstepLockers,
    totalDoorstepLockers,
    isLoading,
    isError,
    getLockers,
  } = getDoorstepLockers(state?.pageNumber, state?.searchValue);

  // helpers
  const setState = (newStateObj) =>
    updateState((prev) => ({ ...prev, ...newStateObj }));
  const handleOpenModal = () =>
    setState({ isNewDoorstepLockerModalOpen: true });
  const handleCloseModal = () =>
    setState({ isNewDoorstepLockerModalOpen: false });
  const handleTableChange = (pagination) =>
    setState({ pageNumber: pagination.current });
  const handleSearch = debounce((value) => {
    setState({ searchValue: value });
  }, 500);

  const { isNewDoorstepLockerModalOpen, showSuccessModal, successMessage } =
    state;

  if (isError)
    return (
      <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
        <p>Something Went Wrong</p>
        <p>Please Try Again</p>
        <Button variant="primary" onClick={getLockers}>
          Retry
        </Button>
      </div>
    );

  return (
    <>
      <div className="space-y-10">
        <div className="flex justify-between mr-48">
          <div className="flex gap-10 items-center flex-wrap">
            <p className="text-header-3">Doorstep Lockers</p>
            <Button
              variant="secondary"
              className="text-xs font-bold"
              onClick={handleOpenModal}
            >
              Assign New Locker to Doorstep Flow
            </Button>
          </div>
          <div className="relative ">
            <input
              type="search"
              placeholder="Search using any parameter"
              className="input-normal font-bold outline-none w-96 p-2"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
          </div>
        </div>
        <Table
          loading={isLoading}
          className="w-3/4 mx-auto"
          scroll={{ x: 800 }}
          columns={doorstepLockerTableColumns}
          dataSource={listOfDoorstepLockers}
          onChange={handleTableChange}
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            total: totalDoorstepLockers,
          }}
        />
      </div>
      <NewDoorstepLockerModal
        isOpen={isNewDoorstepLockerModalOpen}
        onClose={handleCloseModal}
        showSuccessModal={(successMessage) => {
          setState({ showSuccessModal: true, successMessage });
          getLockers();
        }}
      />
      {showSuccessModal && (
        <Success
          body={
            <div className="text-center text-2xl font-bold w-3/4">
              {successMessage}
            </div>
          }
          onClose={() =>
            setState({ showSuccessModal: false, successMessage: "" })
          }
        />
      )}
    </>
  );
};

export default DoorstepLockers;
