import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import AddAndUpdatePricing from './addAndUpdatePricing';
import { tenureTypes } from './constants';
import { toTitleCase } from '../../utils';

const LockerTypePricing = () => {
	const [state, setStateHelper] = useState({
		price: null,
		planId: null,
		security_deposit: null,
		pricingData: [],
		isLoading: true,
		isError: false,
		priceDate: null,
		formPrice: 0,
		formSecurityDeposit: 0,
		editPricingModal: false,
		addPricingModal: false,
		lockerTypeName: null,
		lockerTypeDim: null,
		isSubmitting: false,
		tenureType: tenureTypes.DAY,
		tenure: 0,
	});
	const { spaceId, lockerTypeId } = useParams();
	const [originalData, setOriginalData] = useState({});

	const setState = (changes) => {
		setStateHelper((prev) => ({
			...prev,
			...changes,
		}));
	};

	const getPricing = () => {
		setState({ isLoading: true });

		Api.get(`/locker-price-plan/${lockerTypeId}/space/${spaceId}`)
			.then((data) => {
				setState({
					isLoading: false,
					pricingData: data?.lockerPlan?.rows?.map((element) => ({
						...element,
						tenureString: `${element?.tenure} ${
							element?.tenure > 1
								? `${toTitleCase(element?.tenure_type)}s`
								: toTitleCase(element?.tenure_type)
						}`,
					})),
					lockerTypeName: data?.lockerPlan?.locker_type,
					lockerTypeDim: data?.lockerPlan?.locker_type_size,
				});
			})
			.catch((err) => setState({ isLoading: false, isError: true }));
	};

	const updatePricing = () => {
		setState({ isSubmitting: true });
		Api.put(`locker-price-plan/${state?.planId}`, {
			price: state?.formPrice,
			security_deposit: Number(state?.formSecurityDeposit),
			tenure_type: tenureType,
			tenure: Number(tenure),
		})
			.then((data) => {
				setState({
					isSubmitting: false,
					editPricingModal: false,
					formPrice: '',
					formSecurityDeposit: '',
				});
				getPricing();
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};

	const addPricing = () => {
		setState({ isSubmitting: true });
		Api.post(`locker-price-plan/add-price-plan`, {
			price: Number(state?.formPrice),
			securityDeposit: Number(state?.formSecurityDeposit),
			tenureType: tenureType,
			tenure: Number(tenure),
			spaceId,
			lockerTypeId,
		})
			.then((data) => {
				setState({
					isSubmitting: false,
					addPricingModal: false,
					formPrice: '',
					formSecurityDeposit: '',
				});
				getPricing();
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};

	useEffect(() => {
		getPricing();
	}, []);

	const columns = [
		{
			title: 'Tenure',
			dataIndex: 'tenureString',
			key: 'tenure',
			render: (text) => <p className='text-body-bold capitalize'>{text}</p>,
		},
		{
			title: 'Pricing',
			dataIndex: 'price',
			key: 'pricing',
			render: (text) => (
				<p className='text-body-bold capitalize'>{`₹${text}`}</p>
			),
		},
		{
			title: 'Security Deposit',
			dataIndex: 'security_deposit',
			key: 'security_deposit',
			render: (text) => (
				<p className='text-body-bold capitalize'>{`₹${text}`}</p>
			),
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			render: (text, data) => (
				<Button
					variant='secondary'
					className='text-xs font-bold'
					onClick={() => {
						setOriginalData(data);
						setState({
							price: data?.price,
							formPrice: data?.price || 0,
							formSecurityDeposit: data?.security_deposit || 0,
							planId: data?.id,
							security_deposit: data?.security_deposit || 0,
							tenure: data?.tenure || 0,
							editPricingModal: true,
							tenureType: data?.tenure_type || tenureTypes.DAY,
						});
					}}
				>
					Edit Pricing
				</Button>
			),
		},
	];

	const {
		price,
		security_deposit,
		tenure,
		isLoading,
		isSubmitting,
		isError,
		lockerTypeName,
		lockerTypeDim,
		pricingData,
		editPricingModal,
		formPrice,
		formSecurityDeposit,
		tenureType,
		addPricingModal,
	} = state;

	if (isLoading) return <Loading />;
	if (isError)
		return (
			<div className='flex flex-col space-y-2 font-bold h-1/2 items-center justify-center'>
				<p>Something Went Wrong</p>
				<p>Please Try Again</p>
				<Button variant='primary' onClick={getPricing}>
					Retry
				</Button>
			</div>
		);

	return (
		<>
			<div className='space-y-10'>
				<div className='flex'>
					<p className='text-header-3'>
						{lockerTypeName} - {lockerTypeDim} - Pricing Details
					</p>
					<Button
						variant='cta'
						className='text-xs font-bold ml-4 rounded-md text-[16px] !py-0'
						onClick={() => {
							setOriginalData({
								formPrice: '',
								formSecurityDeposit: '',
								tenure: '',
								tenureType: tenureTypes.DAY,
							});
							setState({
								formPrice: '',
								formSecurityDeposit: '',
								tenure: '',
								addPricingModal: true,
								tenureType: tenureTypes.DAY,
							});
						}}
					>
						Add New Pricing
					</Button>
				</div>

				<Table
					className='w-3/4 mx-auto'
					columns={columns}
					dataSource={pricingData}
					pagination={{ position: ['bottomCenter'] }}
				/>
			</div>
			{editPricingModal && (
				<AddAndUpdatePricing
					title='Edit Pricing'
					setValue={setState}
					values={{
						tenureType,
						tenure,
						formPrice,
						formSecurityDeposit,
					}}
					onSubmit={updatePricing}
					originalData={originalData}
					isSubmitting={isSubmitting}
					submitButtonText='Update Pricing'
					modalId='editPricingModal'
				/>
			)}
			{addPricingModal && (
				<AddAndUpdatePricing
					title='Add New Pricing'
					setValue={setState}
					values={{
						tenureType,
						tenure,
						formPrice,
						formSecurityDeposit,
					}}
					onSubmit={addPricing}
					originalData={originalData}
					isSubmitting={isSubmitting}
					submitButtonText='Add Pricing'
					modalId='addPricingModal'
				/>
			)}
		</>
	);
};
export default LockerTypePricing;
