import { useEffect, useState } from 'react';

// * utils
import {
	addToLocalStorage,
	getValueFromLocalStorage,
	removeFromLocalStorage,
} from './local-storage';

/* eslint-disable eslint-plugin/prefer-object-rule */
/* eslint-disable eslint-plugin/require-meta-type */
/* eslint-disable eslint-plugin/require-meta-schema */
function useTimer (props) {
	const { time, timerName, timesObject } = props;
	const [counter, setCounter] = useState(0);
	const [isCounterRunning, setIsCounterRunning] = useState(false);
	const [timeInterval, setTimeInterval] = useState(0);

	function resetTimer() {
		setIsCounterRunning(false);
		setTimeInterval(0);
		removeFromLocalStorage(timerName);
	}
	useEffect(() => {
		let timerRef;
		if (timeInterval) {
			setCounter(timeInterval);
			timerRef = setInterval(() => {
				setCounter((prev) => {
					if (!prev) {
						resetTimer();
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		} else {
			clearInterval(timerRef);
		}
		return () => {
			clearInterval(timerRef);
		};
	}, [timeInterval]);

	function getterTimeInterval({ timeUptoNow, timeGiven }) {
		if (timeUptoNow < timeGiven * 60) {
			const timeLeft = timeGiven * 60 - timeUptoNow;
			return timeLeft;
		}
		return 0;
	}

	function setterTimeInterval({
		remainingTime = 0,
		timerValue = '',
		doNotUpdateLocal,
		doNotUpdateTimer,
	}) {
		if (remainingTime * 60 > 0) {
			if (!doNotUpdateTimer) {
				setIsCounterRunning(true);
				setTimeInterval(remainingTime);
			}
			if (!doNotUpdateLocal) addToLocalStorage(timerName, timerValue);
		} else {
			resetTimer();
		}
	}

	function callOnTimer({
		times,
		timeUptoNow,
		registerTime,
		triggeredByChange = false,
	}) {
		// continuty case in the times case, if the timer times is already equal or greater means timer must be running.
		if (timesObject && +times >= timesObject?.times) {
			const remainingTime = getterTimeInterval({
				timeGiven: +time,
				timeUptoNow,
			});
			setterTimeInterval({
				remainingTime,
				timerValue: `${registerTime}%${times}`,
				doNotUpdateLocal: true,
			});
			// duration check let say if duration is passed or not if waited time greater than the duration reset to one times.
		} else if (
			timesObject &&
			timesObject.duration * 60 < timeUptoNow &&
			!triggeredByChange
		) {
			const timerValue = `${new Date().getTime()}%${1}`;
			setterTimeInterval({ timerValue });
		} else if (
			timesObject &&
			timesObject.duration * 60 >= timeUptoNow &&
			!triggeredByChange
		) {
			// if the last times the timer case comes will update the storage but not the internal state.
			if (timesObject && +times + 1 === timesObject.times) {
				const remainingTime = getterTimeInterval({
					timeGiven: +time,
					timeUptoNow: 0,
				});
				const timerValue = `${new Date().getTime()}%${+times + 1}`;
				setterTimeInterval({
					remainingTime,
					timerValue,
				});
			} else {
				const timerValue = `${new Date().getTime()}%${+times}`;
				const remainingTime = getterTimeInterval({
					timeGiven: +time,
					timeUptoNow: 0,
				});
				setterTimeInterval({
					timerValue,
					remainingTime,
					doNotUpdateTimer: true,
				});
			}
		}
	}

	function callWhenTrigger(triggeredByChange) {
		const isAlreadyCounterRegistered = getValueFromLocalStorage(timerName);
		if (isAlreadyCounterRegistered) {
			const [registerTime, times] = isAlreadyCounterRegistered.split('%');

			const timeUptoNow = Math.round(
				(new Date().getTime() - +registerTime) / 1000
			);
			// checking if the times case happend, means not to start the timer directly but after some actions.
			if (+times) {
				callOnTimer({
					registerTime,
					times: String(+times + 1),
					timeUptoNow,
					triggeredByChange,
				});
			} else {
				const remainingTime = getterTimeInterval({
					timeGiven: time,
					timeUptoNow,
				});
				// this is starting from the timer from the last time where it was.
				const timerValue = `${new Date().getTime()}%${0}`;
				setterTimeInterval({
					remainingTime,
					timerValue,
					doNotUpdateLocal: true,
				});
				// not updating the localstorage as no refresh of the time needed.
			}
		} else {
			// initial case 1: 0 case is for the times , if times it will be alteast first times triggered.
			const timerValue = `${new Date().getTime()}%${timesObject ? 1 : 0}`;
			setterTimeInterval({
				remainingTime: time * 60,
				timerValue,
				doNotUpdateTimer: timesObject ? !!(timesObject.times - 1) : false,
			});
			// if times we do not update the timer state else in normal case we update.
		}
	}

	useEffect(() => {
		const isAlreadyCounterRegistered = getValueFromLocalStorage(timerName);
		if (
			isAlreadyCounterRegistered ||
			(isAlreadyCounterRegistered &&
				timesObject &&
				timesObject.times <= +isAlreadyCounterRegistered[1])
		) {
			callWhenTrigger(true);
		}
	}, []);

	return {
		triggerCounter: () => callWhenTrigger(false),
		isCounterRunning,
		counter,
	};
};

export default useTimer;
