// ** builtins
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';

// ** components
import Link from '../../components/Link';
import useFilterHook from './Filters';
import Modal from '../../components/Modal';
import RenewLockerModal from './RenewLockerModal';

// ** services
import Api from '../../utils/ApiUtils/Api';

const Renewal = () => {
	const [state, setStateHelper] = useState({
		renewalData: [],
		searchValue: '',
		searchYear: '',
		searchMonth: '',
		limit: 10,
		count: 0,
		currentPage: 1,
		isLoading: true,
		isModalOpen: false,
		modalData: {},
		lockerTypeId: '',
		spaceId: '',
	});
	const setState = (changes) =>
		setStateHelper((prevState) => ({ ...prevState, ...changes }));
	const getCustomers = (page = 1, values = {}, here) => {
		const { search = '', year = '', month = '' } = values;
		const { limit } = state;
		const appliedMonth = month;
		const appliedYear = year;
		const appliedSearch = search;
		setState({ isLoading: true });
		Api.get(
			`/safe/renewal-subscriptions?limit=${limit}&page=${page ?? currentPage}${
				appliedSearch ? `&search=${appliedSearch}` : ''
			}${appliedYear ? `&year=${appliedYear}` : ''}${
				appliedMonth ? `&month=${appliedMonth}` : ''
			}`
		).then((data) => {
			setState({ isLoading: false, page });
			if (data.status === 200) {
				setState({
					renewalData: data?.data,
					count: data?.count,
				});
			}
		});
	};

	useEffect(() => {
		getCustomers(1);
	}, []);

	const onModalClose = () => {
		setState({
			isModalOpen: false,
			modalData: {},
		});
	};

	const columns = [
		{
			title: 'Locker Id',
			dataIndex: 'lockerNumber',
			key: 'lockerId',
			show: true,
			render: (text) => <p className='text-body-bold p-2'>{text}</p>,
		},
		{
			title: 'Customer Name',
			dataIndex: 'customerName',
			key: 'customerName',
			show: true,
			render: (text) => <p className='text-body-bold'>{text}</p>,
		},
		{
			title: 'Mobile Number',
			dataIndex: 'mobileNumber',
			key: 'mobileNumber',
			show: true,
			render: (text) => <p className='text-body-bold'>{text}</p>,
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
			show: true,
			render: (text) => (
				<p className='text-body-bold'>{moment(text).format('DD-MM-YYYY')}</p>
			),
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			key: 'endDate',
			show: true,
			render: (text) => (
				<p className='text-body-bold text-[#D42800]'>
					{moment(text).format('DD-MM-YYYY')}
				</p>
			),
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: 'actions',
			show: true,
			render: (text, data) => (
				<Button
					onClick={() =>
						setState({
							isModalOpen: true,
							modalData: data,
						})
					}
					className='rounded-[10px] bg-[#FFC351] font-extrabold uppercase'
					variant='primary'
				>
					Renew Now
				</Button>
			),
		},
		{
			title: 'More',
			dataIndex: 'customerId',
			key: 'customerId',
			show: true,
			render: (text) => (
				<p className='text-body-bold'>
					<Link to={`/customers/details/${text}`} className='underline'>
						Customer Details
					</Link>
				</p>
			),
		},
	];
	const handleTableChange = (pagination) => {
		setState({ currentPage: pagination.current });
		getCustomers(pagination.current);
	};
	const { renewalData, count, limit, isLoading } = state;
	const filterData = debounce(({ searchValue, searchYear, searchMonth }) => {
		setState({
			searchValue: searchValue,
			searchYear: searchYear,
			searchMonth: searchMonth,
		});
		getCustomers(
			state?.currentPage || 1,
			{
				search: searchValue,
				year: searchYear,
				month: searchMonth,
			},
			true
		);
	}, 500);
	const { Component: FilterComponent } = useFilterHook(filterData);

	return (
		<div className='space-y-10'>
			<div className='flex items-center justify-between'>
				<p className='text-header-3'>Upcoming Renewals</p>
			</div>
			{FilterComponent}
			<div className='mx-auto space-y-5'>
				<Table
					scroll={{ x: 900 }}
					loading={isLoading}
					columns={columns}
					pagination={{
						position: ['bottomCenter'],
						total: count,
						pageSize: limit,
					}}
					onChange={handleTableChange}
					dataSource={renewalData}
				/>
			</div>
			{state?.isModalOpen ? (
				<Modal
					title='Renew Locker'
					onClose={onModalClose}
					className='w-[670px] h-[800px] p-[40px]'
				>
					<RenewLockerModal
						renewalData={state?.modalData}
						onModalClose={onModalClose}
					/>
				</Modal>
			) : null}
		</div>
	);
};

export default Renewal;
