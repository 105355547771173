import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import ReactTag from "../../components/ReactTag";
import Action from "../../assets/Action.svg";
import Link from "../../components/Link";
import HasuraApi from "../../utils/ApiUtils/HasuraApi";
import { debounce } from "lodash";
import AccessCheck from "../../routes/accessCheck";
import permissions from "../../utils/permissionConfig";

export default function Visits() {
  // Use this map for mapping he visit type in frontend
  const VISIT_TYPE_LABEL_MAPPING = {
    "SAFE_VISIT": "Locker",
    "SAFE_VISIT/CALL": "Locker",
    "SAFE_VISIT/WALKIN": "Locker",
    "SAFE_VISIT/WEB": "Locker",
    "SAFE_CUSTOMER_ONBOARD_VISIT/CALL": "Locker",
    "SAFE_CUSTOMER_ONBOARD_VISIT/WALKIN": "Locker",
    "SAFE_CUSTOMER_ONBOARD_VISIT/WEB": "Locker",
    SAFE_DOORSTEP_NEW_CUSTOMER: "Doorstep",
    SAFE_DOORSTEP_NEW_SUBSCRIPTION: "Doorstep",
    SAFE_DOORSTEP_MANAGE_SUBSCRIPTION: "Doorstep"
  };

  const [state, setStateHelper] = useState({
    visitsData: [],
    limit: 10,
    count: 0,
    currentPage: 1,
    searchValue: "",
    isLoading: true
  });
  const setState = (changes) =>
    setStateHelper((prevState) => ({ ...prevState, ...changes }));
  const getVisits = (offset = 0, search = null) => {
    const { searchValue } = state;
    const searchText = search ?? searchValue;
    const getVisitsQuery = {
      variables: {
        limit: state?.limit,
        offset: offset,
        order_by: {
          created_at: "desc"
        },
        where: {
          _and: [
            {
              _or: [
                { first_name: { _ilike: `%${searchText}%` } },
                { last_name: { _ilike: `%${searchText}%` } },
                { id: { _eq: parseInt(searchText.replace("OMV", "")) || 0 } }
              ]
            },
            { space_id: { _is_null: false } }
          ]
        }
      },
      query: `
        query MyQuery(
          $limit: Int
          $offset: Int
        $where: visits_bool_exp = {}
        $order_by: [visits_order_by!] = {}
        ){
          visits_aggregate(where: $where) {
            aggregate {
              count
            }
          }
          visits( limit: $limit, offset: $offset, order_by: $order_by where:$where){
            visit_display_id
            id
            visit_time
            visit_type
            space_id
            visit_status
            first_name
            last_name
            user{
              first_name
              last_name
            }
            customer{
              first_name
              last_name
            }
            space{
              id
              space_number
              name
            }
            agent{
              id
              agent{
                id
                agent_display_id
              }
              first_name
              last_name
            }
          }
        }
      `
    };
    setState({ isLoading: true });
    HasuraApi.post("/v1/graphql", getVisitsQuery)
      .then((data) => data?.data)
      .then((data) =>
        setState({
          isLoading: false,
          visitsData: data?.visits,
          count: data?.visits_aggregate?.aggregate?.count
        })
      )
      .catch(() => setState({ isLoading: false, isError: true }));
  };
  useEffect(() => {
    getVisits();
  }, []);

  const columns = [
    {
      title: "Visit ID",
      dataIndex: "visit_display_id",
      key: "visit_display_id",
      show: true,
      render: (text, data) =>
        <div className="space-y-2">
          <p className="text-body-bold ">{text}</p>
          {VISIT_TYPE_LABEL_MAPPING[data?.visit_type] && <p className="text-subtle">
            {VISIT_TYPE_LABEL_MAPPING[data?.visit_type]}
          </p>}
        </div>

    },
    {
      title: "Visit Date & Time",
      dataIndex: "visit_time",
      key: "visit_time",
      show: true,
      render: (text) =>
        <div className="space-y-2">
          <p className="text-body-bold">{moment(text).format("DD-MM-YYYY")}</p>
          <p className="text-subtle">{moment(text).format("HH:mm")}</p>
        </div>

    },
    {
      title: "Visit Type",
      dataIndex: "visit_type",
      key: "visit_type",
      show: true,
      render: (text) =>
        <div className="space-y-2">
          <p className="text-body-bold">{VISIT_TYPE_LABEL_MAPPING[text]}</p>
        </div>

    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      show: true,
      width: 180,
      render: (text, data) => {
        const {customer = {}, first_name, last_name} = data;
        return <p className="text-body-bold capitalize">{`${customer ? customer?.first_name : first_name }
        ${customer ? customer?.last_name : last_name}`}</p>;
      }
    },
    {
      title: "Visitor Name",
      dataIndex: "visitor_name",
      key: "visitor_name",
      show: true,
      render: (text, data) =>
        <p className="text-body-bold">{`${data.customer?.first_name ?? data.first_name ?? ""
          }
       ${data.customer?.last_name ?? data.last_name ?? ""}`}</p>

    },
    {
      title: "Locker Manager",
      dataIndex: "locker_manager",
      key: "locker_manager",
      show: true,
      render: (text, data) =>
        <div>
          <p className="text-body-bold ">{data?.agent?.first_name}</p>
          <p className="text-subtle">
            {data?.agent?.agent?.agent_display_id ?? ""}
          </p>
        </div>

    },
    {
      title: "Space Details",
      dataIndex: "name",
      key: "name",
      show: true,
      render: (_, data) =>
        <div>
          <p className="text-body-bold ">{data?.space?.name}</p>
          <p className="text-subtle">{data?.space?.space_number ?? ""}</p>
        </div>

    },
    {
      title: "Status",
      dataIndex: "visit_status",
      key: "visit_status",
      show: true,
      width: 200,
      render: (text) => <ReactTag text={text} />
    },

    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      show: true,
      fixed: "right",
      width: 50,
      render: (_, data) =>
      <AccessCheck
        requiredPermissions={permissions.visits.table_row.permission}
        alternateChildren={<div></div>}
        looseCheck={false}
      >
        <Link
          to={
            data.visit_type === 'SAFE_DOORSTEP_NEW_CUSTOMER' ||
            data.visit_type === 'SAFE_DOORSTEP_NEW_SUBSCRIPTION' ||
            data.visit_type === 'SAFE_DOORSTEP_MANAGE_SUBSCRIPTION'
              ? `visits/doorstep-visit-details/${data?.id}`
              : `visits/locker-visit-details/${data?.id}`
          }
        >
          <img src={Action} alt='' />
        </Link>
      </AccessCheck>
    }
  ];

  const filterData = debounce(
    (value) => {
      setState({ searchValue: value });
      getVisits(0, value);
    },
    500,
    false
  );

  const onHandleTableChange = (pagination) => {
    setState({ currentPage: pagination.current });
    getVisits((pagination.current - 1) * 10);
  };

  const onSearchVisit = (event) => {
    filterData(event.target.value);
  };

  const { visitsData, count, isLoading } = state;
  return (
    <div className="space-y-10">
      <div className="flex justify-between">
        <div className="flex flex-col lg:flex-row lg:space-x-5 items-center">
          <p className="text-header-3 m-0">All Visits</p>
          <AccessCheck
            requiredPermissions={permissions.visits.create_locker_visit.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Link
              to="/visits/create-locker-visit"
              variant="secondary"
              className="text-xs"
            >
              Create Locker Visit
            </Link>
          </AccessCheck>
          <AccessCheck
            requiredPermissions={permissions.visits.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Link
              to="/visits/create-doorstep-visit"
              variant="secondary"
              className="text-xs"
            >
              Create Doorstep Visit
            </Link>
          </AccessCheck>
        </div>
        <div className="relative xl:w-1/4 md:w-1/2 lg:w-4/12">
          <input
            type="search"
            placeholder="Search using any parameter"
            className="input-normal font-bold w-full outline-none p-2"
            onChange={(event) => onSearchVisit(event)}
          />
          <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
        </div>
      </div>
      <Table
        loading={isLoading}
        scroll={{ x: 1500 }}
        columns={columns}
        pagination={{ position: ["bottomCenter"], pageSize: 10, total: count }}
        dataSource={visitsData}
        onChange={onHandleTableChange}
      />
    </div>
  );
}
