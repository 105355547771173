// ** builtins
import React, { useEffect, useState } from 'react';

// ** assets
import arrow from '../../assets/belowArrow.png';

const Dropdown = ({
	options,
	selectedOption,
	onSelect,
	placeholder,
	className,
	setCloseOnClick,
}) => {
	const [isOptionOpen, setIsOptionOpen] = useState(false);
	useEffect(() => {
		if (setCloseOnClick)
			setCloseOnClick((prev) => [...prev, () => setIsOptionOpen(false)]);
	}, []);
	return (
		<div className='relative w-[156px]'>
			<div
				className='relative w-[156px]'
				onClick={(e) => {
					setIsOptionOpen(!isOptionOpen);
				}}
			>
				<div
					name='paymentAmount'
					className={`w-[156px] h-[50px] border-[2px] rounded-[10px] 
                        text-[16px]
                        border-[#E8E8E8] outline-none pl-[16px] pt-[10px] ${className}`}
				>
					{selectedOption?.label ? (
						<span className='text-[#454545] font-extrabold text-[16px]'>
							{selectedOption?.label}
						</span>
					) : (
						<span className='text-[#909090] text-[16px]'>{placeholder}</span>
					)}
				</div>
				<img
					src={arrow}
					className={`w-[16px] h-[8px] transform absolute right-[12px] top-[20px] ${
						isOptionOpen ? 'rotate-180' : ''
					}`}
				/>
			</div>
			{isOptionOpen ? (
				<div className='absolute top-[54px] transition-all border rounded-[8px] first-letter:block bg-white z-10 min-h-[30px] max-h-[300px] overflow-y-auto scrollbar overflow-x-hidden w-[160px] mt-1'>
					{options.map((option) => (
						<div
							value={'isOption'}
							onClick={(e) => {
								setIsOptionOpen(false);
								onSelect({
									label: option?.label,
									value: option?.value,
								});
							}}
							className='flex items-center py-3 px-4 cursor-pointer border-b w-full hover:border-[2px] hover:border-[#FFC351] hover:border-1px first:rounded-t-[8px] last:rounded-b-[8px] font-bold'
						>
							{option?.label}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

export default Dropdown;
