import { Empty } from 'antd';
import React from 'react';

const NotFound = () => (
  <div className="h-screen flex justify-center items-center">
    <Empty description="Page not found" />
  </div>
);

export default NotFound;
