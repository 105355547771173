import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Logo from '../assets/oro-safe.svg';
import NotificationIcon from '../assets/notification.svg';
import Modal from './Modal';
import WarningIcon from '../assets/warning.svg';
import AuthApi from '../utils/ApiUtils/AuthApi';
import AuthUtils from '../utils/AuthUtils';
import Button from './Buttons/Button';
import { generateIntegerID } from '../utils';



const Nav = ({ history, setAuthenticated }) => {
	const [logoutModal, setLogoutModal] = useState(false);
	const [notificationBar, setNotificationBar] = useState(false);
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const [email, setEmail] = useState('');

	const logout = () => {
		setIsLoggingOut(true);
		AuthApi.logout().then((res) => {
			AuthUtils.logout();
			setIsLoggingOut(false);
			setLogoutModal(false);
			if (res.code === '200' || res.code === '400') {
				setAuthenticated(false);
				history.push('/');
			}
		});
	};
	const getEmail = async () => {
		try {
			const data = await AuthApi.verifyToken();
			return data?.user?.email_id;
		} catch (err) {
			return '';
		}
	};

	const DropDown = () => (
		<div className='bg-white absolute mt-12 w-80 z-20 h-80 overflow-y-scroll space-y-3 border shadow-lg border-gray-200'>
			{[1, 2, 3, 4, 5, 6, 7].map(() => (
				<div className='flex space-x-3 items-center border-b border-gray-300 p-2'>
					<p className='uppercase bg-new-green text-small-bold  text-white h-16 w-16 text-center py-6 rounded-full'>
						visits
					</p>
					<div className='space-y-2'>
						<p className='text-body-bold'>
							{`Visit Request - OSV${generateIntegerID(5)}`}
						</p>
						<p className='text-subtle'>
							{`Requested on ${moment().format('DD-MM-YYYY  HH:mm')}`}
						</p>
					</div>
				</div>
			))}
		</div>
	);

	useEffect(() => {
		(async function () {
			const email = await getEmail();
			setEmail(email);
		})();
	}, []);

	return (
		<>
			{logoutModal && (
				<Modal
					className='p-10 flex flex-col space-y-10  align-middle text-center
        w-3/4 md:w-2/5 xl:w-1/3 2xl:w-1/4'
					onClose={() => setLogoutModal(false)}
				>
					<img src={WarningIcon} className='w-1/3 mx-auto' alt='warning' />
					<div className='flex space-y-1 flex-col'>
						<p className='text-body-large'>Warning!</p>
						<p className='text-body-large w-3/4 mx-auto'>
							{' '}
							Are you sure you want to Logout?
						</p>
					</div>
					<div className='flex space-x-5 justify-center '>
						<Button
							variant='normal'
							className='w-1/2'
							onClick={() => setLogoutModal(false)}
						>
							Cancel
						</Button>
						<Button
							variant='cta'
							className='w-1/2'
							isLoading={isLoggingOut}
							onClick={logout}
						>
							Logout
						</Button>
					</div>
				</Modal>
			)}
			<div className='flex justify-between items-center  bg-white h-20 border-b border-gray-300'>
				<div className='p-3'>
					<img src={Logo} alt='oro-safe' className='w-20 logo' />
				</div>
				<div className='flex space-x-10 items-center  mr-2 '>
					{/* <div className="flex space-x-1 items-start relative mr-10">
            <div className="text-body-bold cursor-pointer" onClick={() => setNotificationBar((el) => !el)}>
              Notifications
            </div>
            <img src={NotificationIcon} alt="" />
            {notificationBar && (<DropDown />)}
          </div> */}
					<p className='text-body-bold'>{email}</p>
					<Button variant='primary' onClick={() => setLogoutModal(true)}>
						Logout
					</Button>
				</div>
			</div>
		</>
	);
};

export default withRouter(Nav);
