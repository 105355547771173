import moment from "moment";

export const disabledHours = (state) => {
  const currentHour =
    state?.date?.get("day") === moment().get("day")
      ? moment().get("hours")
      : -1;
  const hours = [];
  for (let i = 0; i < currentHour; i++) {
    hours.push(i);
  }
  return hours;
};

export const disabeleMinutes = (hour) => {
  const currentMinute =
    hour === moment().get("hours") ? moment().get("minutes") : -1;
  const minutes = [];
  for (let i = 0; i < currentMinute; i++) {
    minutes.push(i);
  }
  return minutes;
};
