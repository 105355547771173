import { CloseCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Form from '../../components/Form/Form';
import LabelInput from '../../components/Form/LabelInput';
import Loading from '../../components/loading';
import Api from '../../utils/ApiUtils/Api';
import Notification from '../../components/Notifications';
import { ReactComponent as Spinner } from '../../assets/Spinner.svg';
import { uniqueId } from 'lodash';
import { toTitleCase } from '../../utils';

const AddLockerForm = (props) => {
	const {
		index,
		handleChange,
		lockerValue,
		lockerTypes,
		visitId,
		deleteLocker,
	} = props;
	const [state, setStateHelper] = useState({
		tenure: [],
		isLoading: false,
		isError: false,
	});

	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const changeLockerType = (value) => {
		setState({ isLoading: true });
		Api.get(`safe/visit/${visitId}/locker-type/${value}`)
			.then((data) => {
				setState({ isLoading: false, tenure: data?.lockerPlan?.rows });
			})
			.catch((err) => setState({ isLoading: false, isError: true }));

		handleChange(
			index,
			'locker_type_name',
			lockerTypes?.filter((type) => type?.id === value)[0]?.type
		);
		handleChange(index, 'tenureData', null);
		handleChange(index, 'locker_type', value);
	};

	const changeTenure = (value) => {
		handleChange(
			index,
			'plan_id',
			state?.tenure?.filter((el) => el?.id == value)[0]?.id
		);
		handleChange(index, 'tenureData', value);
	};

	const getLockerPrice = () => {
		return state.tenure?.filter((el) => el.id == lockerValue?.tenureData)[0]
			?.price;
	};

	const getSecurityDeposit = () =>
		state.tenure?.filter((el) => el.id == lockerValue?.tenureData)[0]
			?.security_deposit;

	const { tenure, isLoading, isError } = state;

	return (
		<PrimaryCard className='p-10 relative pt-12'>
			{index > 0 && (
				<Button
					variant='secondary'
					className='absolute py-1 ml-auto right-2 top-3'
					onClick={() => deleteLocker(index)}
				>
					<CloseCircleFilled className='mr-1' /> Remove
				</Button>
			)}
			<div className='space-y-10'>
				<div className='flex gap-5 flex-wrap'>
					<LabelInput
						type='select'
						name='locker_type'
						placeholder='Choose Locker Type'
						rootClassName='w-3/4 lg:w-1/3'
						value={lockerValue?.locker_type ?? ''}
						label='Choose Locker Type'
						options={
							lockerTypes?.map((lockerType) => ({
								label: lockerType?.type,
								value: lockerType?.id,
							})) ?? []
						}
						required
						onChange={(e) => changeLockerType(e.target.value)}
					/>
					<LabelInput
						type='select'
						value={lockerValue?.tenureData ?? ''}
						onChange={(e) => changeTenure(e.target.value)}
						name='tenure'
						rootClassName='w-3/4 lg:w-1/3'
						placeholder='Click to choose'
						label='Choose Tenure'
						options={tenure?.map((el) => ({
							label: `${
								el?.tenure == 1
									? `1 ${toTitleCase(el?.tenure_type)}`
									: `${el?.tenure} ${toTitleCase(el?.tenure_type)}s`
							}`,
							value: el?.id,
						}))}
						required
					/>
					{lockerValue?.locker_type &&
						!isLoading &&
						(!tenure || tenure?.length === 0) && (
							<p className='self-center mt-10 text-base font-bold'>
								No Plans Found
							</p>
						)}
					{isLoading && (
						<div className='self-center mt-10'>
							<Spinner
								className='animate-spin'
								width={30}
								height={30}
								color='black'
							/>
						</div>
					)}
					{lockerValue?.locker_type && lockerValue?.tenureData != null && (
						<div className='space-y-5'>
							<p className='label'>Price</p>
							<p className='text-body-large'>{`₹${getLockerPrice()}`}</p>
						</div>
					)}
					{lockerValue?.locker_type && lockerValue?.tenureData != null && (
						<div className='space-y-5'>
							<p className='label'>Security Deposit</p>
							<p className='text-body-large'>{`₹${getSecurityDeposit()}`}</p>
						</div>
					)}
				</div>
				<LabelInput
					type='text'
					label='Goderj ID'
					value={lockerValue?.godrej_id ?? ''}
					rootClassName='w-3/4 lg:w-1/2'
					onChange={(e) => handleChange(index, 'godrej_id', e.target.value)}
					name='godrej_id'
					placeholder='Enter Godrej ID of the locker'
					required
				/>
			</div>
		</PrimaryCard>
	);
};
export default function AddLockers(props) {
	const { current, setCurrent, state: stepperState } = props;
	const { id: visitId } = useParams();
	const [state, setStateHelper] = useState({
		lockers: [
			{
				key: uniqueId(),
				locker_type: null,
				tenureData: null,
				godrej_id: null,
				locker_type_name: null,
				plan_id: null,
			},
		],
		isLoading: true,
		isError: false,
		isSubmitting: false,
		lockertypes: [],
	});

	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const getLockerTypes = () => {
		setState({ isLoading: true, isError: false });
		Api.get(`locker-type`)
			.then((data) => {
				setState({ isLoading: false, lockertypes: data?.lockerTypes });
			})
			.catch((err) => {
				setState({ isLoading: false, isError: true });
			});
	};
	useEffect(() => {
		getLockerTypes();
	}, []);

	const handleChange = (index, field, value) => {
		const lockers = state?.lockers;
		lockers[index] = { ...lockers[index], [field]: value };
		setState({ lockers });
	};

	const addLocker = () => {
		setState({
			lockers: [
				...state.lockers,
				{ locker_type: null, tenureData: null, godrej_id: null },
			],
		});
	};
	const deleteLocker = (index) => {
		if (index > 0) {
			const lockers = state?.lockers;
			lockers.splice(index, 1);
			setState({ lockers });
		}
	};

	const handleSubmit = () => {
		setState({ isSubmitting: true, isError: false });
		Api.post(`safe/visit/${visitId}/locker`, {
			input: {
				payload: state?.lockers?.map((locker) => ({
					locker_type_id: parseInt(locker?.locker_type, 10),
					physical_id: locker?.godrej_id,
					plan_id: locker?.plan_id,
				})),
			},
		})
			.then((data) => {
				setState({ isSubmitting: false });
				Notification.create('success', 'Lockers are added successfully');
				setCurrent(current + 1);
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};

	const { isLoading, isError, lockertypes, lockers, isSubmitting } = state;
	if (isLoading) return <Loading />;

	return (
		<div className='space-y-10'>
			<div className='flex flex-col lg:flex-row'>
				<p className='text-header-3 p-5 lg:w-1/5 uppercase'>Add Lockers</p>
				<Form
					onSubmit={handleSubmit}
					className='w-full lg:w-4/5 xl:w-3/4 space-y-10'
				>
					{lockers?.map((lockerValue, index) => (
						<AddLockerForm
							key={lockerValue?.key}
							lockerTypes={lockertypes}
							visitId={visitId}
							lockerValue={lockerValue}
							deleteLocker={deleteLocker}
							handleChange={handleChange}
							index={index}
						/>
					))}
					<Button
						type='button'
						variant='primary'
						onClick={addLocker}
						className='rounded-md px-5 py-2'
					>
						Add Another Locker
						<PlusCircleOutlined className='ml-2 text-xl' />
					</Button>
					<div className='flex gap-5 flex-wrap mx-auto justify-center'>
						{/* <Button type="button" variant="normal" className="w-full lg:w-2/5 bg-white border border-[#CECECE]"
              onClick={() => setCurrent(current - 1)}
            >
              Back To Edit Customer Details
            </Button> */}
						<Button
							variant='cta'
							type='submit'
							className='w-1/2 lg:w-2/5'
							isLoading={isSubmitting}
						>
							Go to Cart
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}
