import React from 'react';

const Step = ({ index, title, current, length }) => {
  const getBgColor = () => {
    if (index > current) return 'bg-inactive';
    if (index === current) return 'bg-new-green';
    return 'bg-new-green bg-opacity-30';
  };

  const getTextColor = () => {
    if (index > current) return 'text-inactive';
    if (index === current) return 'text-new-green';
    return 'text-new-green bg-opacity-30';
  };

  return (
    <div className="flex items-center">
      <div className="step flex flex-col space-y-1 xl:flex-row xl:space-x-2  justify-center items-center">
        <div className={`rounded-full w-8 h-8 xl:h-10 xl:w-10 2xl:h-12 2xl:w-12 flex flex-col items-center 
        justify-center text-center ${getBgColor()}`}
        >
          <p className="lg:text-xs xl:text-lg 2xl:text-2xl font-bold text-white">{index}</p>
        </div>
        <p className={`${getTextColor()} text-xs xl:text-base text-center font-bold`}>{title}</p>
        {index !== length && <div className="hidden
        xl:block seperator border-b-2 2xl:border-b-4 border-dashed h-0.5 w-5 md:w-10 border-black"
        />}
      </div>
      <div>
        {index !== length && <div className="xl:hidden
      seperator border-b md:border-b-2 2xl:border-b-4 border-dashed h-0.5 w-5 md:w-10 border-black"
        />}
      </div>
    </div>
  );
};

export default function Stepper({ options, history, current, setCurrent, state, ...rest }) {
  return (
    <div className="space-y-10">
      <div className="flex space-x-1 lg:space-x-5 justify-center">
        {options?.map(({ key, title }) => (
          <Step key={key} title={title} index={key} length={options.length} current={current} {...rest} />
        ))}
      </div>
      <div>
        {options?.map(({ component, key }) => {
          const Component = component;
          if (Component) {
            return (
              <>
                {current === key
                  ? (
                    <Component history={history} current={current} setCurrent={setCurrent} state={state} />
                  ) : null}
              </>
            );
          }
          return (<></>);
        })}
      </div>
    </div>
  );
}
