/* eslint-disable react/jsx-handler-names */
import './imageViewer.css';
import {
  CloseOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftCircleOutlined,
  RedoOutlined,
  RightCircleOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import React, { useRef, useState } from 'react';

// ASSETS
import noImageFound from '../../assets/404_no_image_found.png';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ImageViewer = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const rootRef = useRef(null);

  function openFullscreen() {
    if (!fullScreen) {
      const elem = rootRef.current;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
      setFullScreen(true);
    }
  }

  function closeFullscreen() {
    if (fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setFullScreen(false);
    }
  }

  return (
    <>
      <div
        ref={rootRef}
        className="fixed -top-6  inset-0 w-full h-full bg-transparent"
        style={{
          zIndex: 2
        }}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-80 z-10 " />
        <TransformWrapper
          initialScale={1}
          centerZoomedOut
          limitToBounds={false}
          centerOnInit
          wheel={{ step: 0.08 }}
        >
          {({ zoomIn, zoomOut, resetTransform }) =>
            <>
              <div className="absolute top-10 right-10 flex gap-5 z-50">
                <RedoOutlined
                  className="cursor-pointer text-white text-2xl "
                  onClick={() => {
                    setRotation(0);
                    resetTransform(300);
                  }}
                ></RedoOutlined>
                <ZoomInOutlined
                  className="cursor-pointer text-white text-2xl "
                  onClick={() => zoomIn(0.3)}
                ></ZoomInOutlined>
                <ZoomOutOutlined
                  className="cursor-pointer text-white text-2xl "
                  onClick={() => zoomOut(0.3)}
                ></ZoomOutOutlined>
                <RotateRightOutlined
                  className="cursor-pointer text-white text-2xl "
                  onClick={() => {
                    setRotation((rotation) => (rotation + 90) % 360);
                  }}
                ></RotateRightOutlined>
                <RotateLeftOutlined
                  className="cursor-pointer text-white text-2xl "
                  onClick={() => {
                    setRotation((rotation) => (rotation - 90) % 360);
                  }}
                ></RotateLeftOutlined>

                <FullscreenOutlined
                  hidden={fullScreen}
                  className="cursor-pointer text-white text-2xl "
                  onClick={openFullscreen}
                ></FullscreenOutlined>
                <FullscreenExitOutlined
                  hidden={!fullScreen}
                  className="cursor-pointer text-white text-2xl "
                  onClick={closeFullscreen}
                ></FullscreenExitOutlined>
                <CloseOutlined
                  onClick={onClose}
                  className="cursor-pointer text-white text-2xl "
                ></CloseOutlined>
              </div>
              <div className="absolute flex w-full h-full items-center justify-between px-10">
                {currentIndex !== 0 ?
                  <LeftCircleOutlined
                    disabled={currentIndex === 0}
                    className="cursor-pointer text-white text-3xl z-50 "
                    onClick={() => {
                      if (currentIndex === 0) {
                        setCurrentIndex(images.length - 1);
                      } else {
                        setCurrentIndex(currentIndex - 1);
                      }
                      setRotation(0);
                      resetTransform();
                    }}
                  ></LeftCircleOutlined>
                  :
                  <p></p>}
                {currentIndex !== images.length - 1 ?
                  <RightCircleOutlined
                    disabled={currentIndex === images.length - 1}
                    className="cursor-pointer text-white text-3xl z-50"
                    onClick={() => {
                      if (currentIndex === images.length - 1) {
                        setCurrentIndex(0);
                      } else {
                        setCurrentIndex(currentIndex + 1);
                      }
                      setRotation(0);
                      resetTransform();
                    }}
                  ></RightCircleOutlined>
                  :
                  <p></p>}
              </div>
              <div className="absolute bottom-10 left-10 bg-gray-900 px-5 py-2 z-40">
                <p className="text-white text-2xl">
                  {images?.[currentIndex]?.caption}
                </p>
              </div>
              <TransformComponent
                contentClass="w-full h-full  flex justify-center items-center"
                wrapperClass="absolute w-full h-full flex  justify-center items-center z-40"
              >
                <img
                  src={images?.[currentIndex]?.source}
                  className="h-3/4 w-auto"
                  style={{
                    transform: `rotate(${rotation}deg)`
                  }}
                  onError={(event) => {
                    event.target.onerror = null;
                    event.target.src = noImageFound;
                  }}
                />
              </TransformComponent>
            </>}
        </TransformWrapper>
      </div>
    </>
  );
};
export default ImageViewer;
