import { Empty } from 'antd';
import React from 'react';
import { Route } from 'react-router-dom';
import BreadCrumbs from '../components/BreadCrumbs';
import SideBar from '../components/SideBar';
import ErrorBoundary from '../ErrorBoundary';
import './Auth.scss';
import AccessCheck from './accessCheck';
import NotFoundSVG from '../assets/404_error.svg';

const Auth = ({ component: Component, requiredPermissions, ...rest }) => (
  <Route
    key={rest.path}
    {...rest}
    render={(props) => (
      <AccessCheck
        requiredPermissions={requiredPermissions ?? ["defaultPermission"]}
        alternateChildren={
          <Empty
            image={NotFoundSVG}
            className="h-screen"
            description="404 Not Found"
            imageStyle={{ display: "flex", justifyContent: "center" }}
          />
        }
        looseCheck={false}
      >
        <div className="flex flex-row">
          <div className="border-r border-gray-300">
            <SideBar {...props} />
          </div>
          <div className="content px-8 pt-10 pb-20 bg-gray-100 flex-grow authContainer">
            <BreadCrumbs className="mb-5" />
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          </div>
        </div>
      </AccessCheck>
    )}
  />
);

export default Auth;
