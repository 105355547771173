/* eslint-disable eslint-plugin/require-meta-schema */
/* eslint-disable eslint-plugin/require-meta-type */
/* eslint-disable eslint-plugin/prefer-object-rule */
import { useEffect, useState } from "react";
import Api from "../../../utils/ApiUtils/Api";

const getDoorstepLockers = (pageNumber = 1, searchValue = "") => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDoorstepLockers, setTotalDoorstepLockers] = useState(0);
  const [listOfDoorstepLockers, setListOfDoorstepLockers] = useState([]);

  const getLockers = async () => {
    setIsLoading(true);

    try {
      const lockerApiResponse = await Api.get(
        `/safe/doorstep-locker/list?page=${pageNumber}&limit=10&search=${searchValue}`,
        {
          apiVersion: "v2",
        }
      );

      const formattedListOfLockers = lockerApiResponse?.data?.rows?.map(
        (locker) => ({
          "oro-safe-id": locker?.locker_number,
          "godrej-id": locker?.physical_id,
          "locker-type": locker?.lockerLockerType?.type,
          "size-identifier": locker?.lockerLockerType?.size,
        })
      );

      setIsError(false);
      setListOfDoorstepLockers(formattedListOfLockers);
      setTotalDoorstepLockers(lockerApiResponse?.data?.count);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getLockers();
  }, [pageNumber, searchValue]);

  return {
    listOfDoorstepLockers,
    totalDoorstepLockers,
    getLockers,
    isLoading,
    isError,
  };
};

export default getDoorstepLockers;
