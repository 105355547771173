import { useEffect, useState } from "react";
import Api from "../../../utils/ApiUtils/Api";

const assignNewLocker = () => {
  const [isError, setIsError] = useState(false);
  const [listOfSpaces, setListOfSpaces] = useState([]);
  const [listOfLockerTypes, setListOfLockerTypes] = useState([]);

  useEffect(() => {
    // get list of spaces
    if (listOfSpaces.length === 0) {
      const getSpaces = async () => {
        try {
          const spacesApiResponse = await Api.get("/space-config/space");
          setIsError(false);
          setListOfSpaces(spacesApiResponse?.spaces?.rows);
        } catch (error) {
          setIsError(true);
        }
      };

      getSpaces();
    }

    // get list of locker types
    if (listOfLockerTypes.length === 0) {
      const getLockerTypes = async () => {
        try {
          const lockerTypesApiResponse = await Api.get("/locker-type");
          setIsError(false);
          setListOfLockerTypes(lockerTypesApiResponse?.lockerTypes);
        } catch (error) {
          setIsError(true);
        }
      };

      getLockerTypes();
    }
  }, []);

  const createNewDoorstepLocker = async (godrejId, lockerTypeId, spaceId) => {
    try {
      await Api.post(
        "/locker/doorstep-locker",
        {
          godrejId,
          lockerTypeId,
          spaceId,
        },
        {
          apiVersion: "v2",
        }
      );
    } catch (err) {}
  };

  return { listOfSpaces, listOfLockerTypes, createNewDoorstepLocker, isError };
};

export default assignNewLocker;
