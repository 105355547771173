import React from "react";
import styles from './Checkbox.module.scss';

export default function Checkbox({ className, isSelected, type, onClick }) {
  let preference;
  switch (type) {
  default:
    preference = styles.green;
  }
  return (
    <label className={`${className} ${preference} cursor-pointer flex items-center justify-center`}>
      <input type="checkbox" checked={isSelected} onClick={onClick} />
      <span />
    </label>
  );
}
