import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import ImageUpload from '../Form/Upload';
import Button from '../Buttons/Button';
import Form from '../Form/Form';
import LabelInput from '../Form/LabelInput';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../loading';
import Notification from '../../components/Notifications';

export default function RecordManualPayment(props) {
  const { setVisible, setPaymentStatus, visitId } = props;
  const [state, setStateHelper] = useState({
    paymentData: null,
    isLoading: false,
    isError: false,
    isSubmitting: false
  });
  useEffect(() => {
    setState({isLoading: true})
    Api.get(`safe/visit/${visitId}/payment/pending`).then(
      (data) => {
        if(data?.payment.is_pending){
          setState({paymentData: data?.payment?.payemnt_details, isLoading: false})
        }
        else{
          setState({isLoading: false});
          setPaymentStatus(true);
          setVisible(false);
        }
      },
    ).catch(err => setState({isLoading: false, isError: true}));
  }, []);

  const setState = (changes) => {
    setStateHelper((prevState) => ({
      ...prevState,
      ...changes,
    }));
  };

  const handleChange = (field, value) => {
    setState({ [field]: value });
  };

  const handleSubmit = () => {
    setState({isSubmitting: true})
    Api.post(`safe/visit/${visitId}/payment/${state?.paymentData?.id}/manual-payment`, {
      input: {
        method: state?.payment_type,
        external_payment_id: state?.utr,
        proof: state?.transaction_photo,
      },
    }).then(
      (data) => {
        Notification.create('success', "Payment is recorded successfully");
        setState({isSubmitting: false})
        setPaymentStatus(true);
        setVisible(false);
      },
    ).catch(err => setState({isSubmitting: false, isError: true}));
  };

  const validate = () => {
    if (!state.payment_type || state.payment_type === '') {
      setState({ formError: 'Plese check all fields' });
      return false;
    }
    return true;
  };

  const {isLoading, isError, isSubmitting, paymentData} = state;

  return (
    <Modal
      title="Record Manual Payment"
      className="w-4/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3 p-5 xl:p-10"
      onClose={() => setVisible(false)}
      isLoading ={isLoading}
    >
      <Form onSubmit={handleSubmit} customValidation={validate}>
        <div className="space-y-10">
          <div className="flex justify-between">
            <div className="space-y-2">
              <p className="label">Amount</p>
              <p className="text-body-large">
                ₹
                {' '}
                {paymentData?.amount}
              </p>
            </div>
            <div className="space-y-2">
              <LabelInput label="Payment Type" name="payment_type" type="radioGroup" variant="green"
                value={state?.payment_type ?? ''}
                onChange={(value) => handleChange('payment_type', value)}
                options={
                  [{
                    label: 'Pos',
                    value: 'pos',
                  }, {
                    label: 'Cash',
                    value: 'cash',
                  }]
                }
              />
            </div>
          </div>
          <div className="flex gap-10 flex-wrap w-full">
            <LabelInput type="text" label="UTR" value={state?.utr ?? ''}
              rootClassName="w-1/2"
              onChange={(e) => handleChange('utr', e.target.value)}
              placeholder="Enter UTR/ Payment ID here" required
            />
            <ImageUpload label="photo of transaction" value={state?.transaction_photo ?? []}
              maxFiles={1} placeholder="Upload Photo"
              onChange={(value) => handleChange('transaction_photo', value?.length >= 1 ? value[0] : null)}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-5">
            <Button type="button" variant="normal" className="w-full" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button variant="cta" className="w-full" type="submit" isLoading={isSubmitting}>Record Manual Payment</Button>
          </div>
        </div>
        <p className="text-error-red mt-2">{state?.formError ?? ''}</p>
      </Form>
    </Modal>
  );
}
