// ** builtins
import React from 'react';

// ** components
import SearchSvg from '../../assets/SearchSvg';

export default function SearchInput({ placeHolder, onSearch, searchValue }) {
	return (
		<div className='bg-white gap-4 border border-secondary-grey rounded-lg w-full flex items-center p-[5px]'>
			<SearchSvg />
			<input
				type='text'
				placeholder={placeHolder}
				className='rounded-lg placeholder:text-[#909090]  placeholder:font-[600] text-base font-bold outline-none w-full'
				value={searchValue}
				onChange={(event) => {
					onSearch(event.target.value);
				}}
			/>
		</div>
	);
}
