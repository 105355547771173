/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Buttons/Button";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import Form from "../../../../components/Form/Form";
import LabelInput from "../../../../components/Form/LabelInput";
import HasuraApi from "../../../../utils/ApiUtils/HasuraApi";
import {  getAssignVisitQuery, getFieldAgentsListQuery } from "./constants";

export default function AssignVisitCard({ visitId, onAssign }) {
  const [state, setStateHelper] = useState({
    lockerManager: "",
    lockerManagers: [],
    isSubmitting: false
  });

  const setState = (changes) =>
    setStateHelper((prevState) => ({ ...prevState, ...changes }));

  const onHandleAssignVisit = async () => {
    const assignVisitQuery = await getAssignVisitQuery(visitId, state?.lockerManager);
    setState({ isSubmitting: true, isError: false });
    HasuraApi.post("/v1/graphql", assignVisitQuery)
      .then(() => {
        setState({
          isSubmitting: false,
          isError: false
        });
        onAssign();
      })
      .catch(() => {
        setState({
          isSubmitting: false,
          isError: true,
          retry: onHandleAssignVisit
        });
      });
  };

  const getFieldAgents = () => {
    const fieldAgentListQuery = getFieldAgentsListQuery();
    setState({ isLoading: true, isError: false });
    HasuraApi.post("/v1/graphql", fieldAgentListQuery)
      .then((data) => {
        const fieldAgents = data?.data?.users.map((agent) => ({
          label: `${agent?.first_name} ${agent?.last_name} / ${agent?.agent?.agent_display_id}`,
          value: agent?.auth_id
        }));
        setState({
          lockerManagers: fieldAgents,
          isLoading: false
        });
      })
      .catch(() =>
        setState({ isError: true, isLoading: false, retry: getFieldAgents })
      );
  };

  useEffect(() => {
    getFieldAgents();
  }, []);

  const { lockerManagers, lockerManager, isSubmitting } = state;

  const onAssignVisit = (val) => {
    setState({ lockerManager: val });
  };

  return (
    <Form onSubmit={onHandleAssignVisit}>
      <div className="flex w-full">
        <p className="text-header-3 hidden md:block px-4">
          Assign
          <br />
          Visit
        </p>
        <PrimaryCard className="ml-3 p-8 w-[525px]">
          <p className="text-header-3 md:hidden">Assign Visit</p>
          <LabelInput
            type="selectWithAutocomplete"
            label="field agent"
            placeholder="Choose Agent"
            value={lockerManager}
            onChange={(val) => {
              onAssignVisit(val)
            }}
            options={
              lockerManagers?.map(({ label, value }) => ({
                label,
                value
              })) ?? []
            }
            required
          />
          <div className="pt-10">
            <Button
              variant="cta"
              type="submit"
              className="w-full"
              isLoading={isSubmitting}
            >
              Assign Visit
            </Button>
          </div>
        </PrimaryCard>
      </div>
    </Form>
  );
}
