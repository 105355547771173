import React, { useEffect, useRef, useState } from "react";
import Api from "../utils/ApiUtils/Api";
import Loading from "./loading";

function ImageModal({ imageUrl, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const bg = useRef(null);
  const modal = useRef(null);
  const getImage = async (imageUrl) => {
    try{
      setIsLoading(true);
      const response = await Api.post(`/images`, {urls: [imageUrl]});
      setImage(response.signedUrls[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } 
  };

  function beforeClose() {
    bg.current.classList.remove('animate-fade');
    bg.current.classList.add('animate-fadeOut');
    modal.current.classList.remove('animate-modal');
    modal.current.classList.add('animate-modalOut');
    setTimeout(onClose, 500);
  }


  useEffect( () => {
    getImage(imageUrl)
  }, [imageUrl]);

  if(isLoading || image === null) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="absolute inset-0 bg-gray-900 opacity-50" />
        <Loading />
      </div>
    );
  }

  return (
    <div  ref={bg} className="animate-fade fixed inset-0 flex items-center justify-center z-50" onClick={beforeClose}>
      <div  className="fixed inset-0 bg-gray-900 opacity-50" />
      <div ref={modal} className="relative  bg-white rounded-lg p-6 overflow-hidden max-h-screen">
        <button type='button'
          className="absolute top-0 right-0 p-3 text-gray-500 hover:text-gray-900"
          onClick={beforeClose}
        >
          <svg
            className="fill-current h-6 w-6"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </button>
        <img src={image} alt="image"/>
      </div>
    </div>
  );
}
export default ImageModal;

