import React, { useState, useEffect } from "react";
import LabelInput from "./LabelInput";
import DeleteIcon from '../../assets/delete.svg';
import Api from "../../utils/ApiUtils/Api";
import Notification from "../Notifications";
import ImageModal from "../ImageModal";

export default function ImageUpload({ value, onChange, maxFiles, fileListClassName, accept, ...rest }) {
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(null);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    onChange(files.map((file) => file?.url));
  }, [files]);

  const handleChange = async(acceptedFiles) => {
    try{
      acceptedFiles?.forEach(async file => {
        setUploading(true);
        const url = await Api.uploadImage(file)
        setFiles((fileList) => [...fileList, {name: file.name, url: url}].slice(-1 * (maxFiles ?? 1)));
        setUploading(false);
      });
    }catch(err){
      setUploading(false);
      Notification.create('error', "Unable To Upload Image");
    }
  };

  const handleDelete = (file) => {
    setFiles((fileList) => fileList.filter((el) => el !== file));
  };

  return (
    <div>
      <div className="flex-col space-y-2">
        <LabelInput type="fileBtn" {...rest} accept={accept ?? ".jpg, .jpeg, .png"} isUploading={isUploading}
          maxFiles={maxFiles} onChange={handleChange} />
        <div className="space-y-2 files">
          {files?.map((file) => (
            <div key={file.url} className={fileListClassName ?? ''}>
              <p className="space-x-5 cursor-pointer inline-flex" key={file.url} onClick={()=> setPreview(file.url)}>
                <span className="w-36 overflow-hidden whitespace-nowrap overflow-ellipsis">{file.name}</span>
                <span className="cursor-pointer" onClick={(e) =>(e.stopPropagation(),
                handleDelete(file))}><img src={DeleteIcon} alt="delete" /></span>
              </p>
            </div>
          ))}
        </div>
      </div>
      {preview && (
        <ImageModal imageUrl={preview}  onClose={() => setPreview(null)} />
      )}
    </div>
  );
}
