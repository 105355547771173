import React, { useRef } from "react";
import { CloseOutlined } from '@ant-design/icons';

export default function Modal({ className, onClose, closable = true, isCloseOutside = true, title, children, isLoading= false }) {
  const bg = useRef();
  const modal = useRef();

  function beforeClose() {
    bg.current.classList.remove('animate-fade');
    bg.current.classList.add('animate-fadeOut');
    modal.current.classList.remove('animate-modal');
    modal.current.classList.add('animate-modalOut');
    setTimeout(onClose, 500);
  }

  function onClickOutside() {
    if (isCloseOutside) beforeClose();
  }

  return (
    <>
      <div ref={bg} className="animate-fade fixed inset-0 flex items-center justify-center z-50" onClick={onClickOutside}>
        <div className="fixed inset-0 bg-black opacity-50" />
        {isLoading ? (
          <svg className={`animate-spin -ml-1 mr-3 h-10 w-10 text-white`} xmlns="http://www.w3.org/2000/svg" fill="none" 
            viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 
              0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
        ) : (   
          <div
            ref={modal}
            className={`animate-modal bg-white p-5 text-black rounded-xl ${className}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <p className="text-2xl font-bold mr-5">{title}</p>
              {closable && <CloseOutlined className="text-black text-lg font-bold" onClick={beforeClose} />}
            </div>
            {children}
          </div>
        )}
      </div>
    </>);
}
