import moment from 'moment';
import React, { useEffect, useState } from 'react';
import KeyValue from '../../../../components/Form/KeyValue';
import ReactTag from '../../../../components/ReactTag';
import PrimaryCard from '../../../../components/Cards/PrimaryCard';
import LabelInput from '../../../../components/Form/LabelInput';
import Button from '../../../../components/Buttons/Button';
import Form from '../../../../components/Form/Form';
import Link from '../../../../components/Link';
import HasuraApi from '../../../../utils/ApiUtils/HasuraApi';
import Loading from '../../../../components/loading';
import Modal from '../../../../components/Modal';
import { ReactComponent as WarningIcon } from '../../../../assets/warning.svg';
import { getFullName } from '../../../../utils';
import permissions from '../../../../utils/permissionConfig';
import AccessCheck from '../../../../routes/accessCheck';
import AuthApi from '../../../../utils/ApiUtils/AuthApi';

export default function VisitDetails({ match, history }) {
	const [state, setStateHelper] = useState({
		lockerManager: null,
		lockerManagers: null,
		isLoading: true,
		retry: () => {},
		isError: false,
		isSubmitting: false,
		isCancelling: false,
		showCancelVisitModal: false,
		visitData: {},
	});

	const setState = (changes) =>
		setStateHelper((prevState) => ({ ...prevState, ...changes }));

	const assignVisit = async () => {
		const assignVisitQuery = {
			variables: {
				id: parseInt(match.params?.id, 10),
				agent_auth_id: state?.lockerManager,
				assigned_by: `${(await AuthApi?.verifyToken())?.user?.id}`,
				assigned_at: moment(),
				visit_status: 'VISIT_CONFIRMED',
			},
			query: `
      mutation updateAgent(
        $id: Int!
        $agent_auth_id: String!
        $visit_status: visit_status_enum = "VISIT_ASSIGNED"
        $assigned_by: String!
        $assigned_at: timestamptz!
        $secondary_field_agent_one_auth_id: String
        $secondary_field_agent_two_auth_id: String
        $secondary_agents_visit_status: jsonb
      ) {
        update_visits_by_pk(
          pk_columns: { id: $id }
          _set: {
            agent_auth_id: $agent_auth_id
            visit_status: $visit_status
            assigned_by_auth_id: $assigned_by
            assigned_at: $assigned_at
            secondary_field_agent_one_auth_id: $secondary_field_agent_one_auth_id
            secondary_field_agent_two_auth_id: $secondary_field_agent_two_auth_id
            secondary_agents_visit_status: $secondary_agents_visit_status
          }
        ) {
          id
          agent_auth_id
          visit_status
          secondary_field_agent_one_auth_id
          secondary_field_agent_two_auth_id
          secondary_agents_visit_status
          assigned_by_auth_id
          assigned_by {
            id
            first_name
            last_name
            auth_id
            mobile_number
          }
          agent {
            id
            agent {
              id
              agent_display_id
            }
            auth_id
            first_name
            last_name
            mobile_number
          }
        }
      }
    `,
		};
		setState({ isSubmitting: true, isError: false });
		HasuraApi.post('/v1/graphql', assignVisitQuery)
			.then((data) => {
				setState({
					visitData: { ...state.visitData, ...data?.data?.update_visits_by_pk },
					isSubmitting: false,
					isError: false,
				});
			})
			.catch((err) => {
				setState({ isSubmitting: false, isError: true, retry: assignVisit });
			});
	};

	const getVisitData = () => {
		const getVisitById = {
			variables: {
				where: {
					id: { _eq: parseInt(match.params.id, 10) },
				},
			},
			query: `
      query getVisitById($where: visits_bool_exp = {}) {
        visits(where: $where) {
          id
          visit_display_id
          visit_start_time
          visit_status
          intermediate_status
          visit_time
          visit_type
          first_name
          last_name
          space_id
          assigned_by {
            id
            first_name
            last_name
          }
          space {
            id
            name
            space_number
            status
            address_id
          }
          agent {
            id
            agent {
              id
              agent_display_id
            }
            auth_id
            first_name
            last_name
            mobile_number
          }
          user {
            id
            first_name
            last_name
          }
          customer {
            id
            first_name
            last_name
          }
        }
      }
      `,
		};
		setState({ isLoading: true, isError: false });
		HasuraApi.post('/v1/graphql', getVisitById)
			.then((data) =>
				setState({ visitData: data?.data?.visits[0], isLoading: false })
			)
			.catch((err) =>
				setState({ isLoading: false, isError: true, retry: getVisitData })
			);
	};

	const getLockerManagers = () => {
		const getLockerManagersQuery = {
			variables: {},
			query: `
      query MyQuery {
        users(where: {hasura_default_role: {_eq: "locker_manager"}}) {
          auth_id
          first_name
          last_name
          email_id
          mobile_number
          name
          role_name
          agents {
            address
            address_proof
            agent_display_id
            id
            id_proof
            id_card_photos
            relation
            secondary_address
            secondary_mobile_number
          }
        }
      }
      `,
		};
		setState({ isLoading: true, isError: false });
		HasuraApi.post('/v1/graphql', getLockerManagersQuery)
			.then((data) => {
				setState({ lockerManagers: data?.data?.users, isLoading: false });
			})
			.catch((err) =>
				setState({ isError: true, isLoading: false, retry: getLockerManagers })
			);
	};

	const cancelVisit = () => {
		const cancelVisitQuery = {
			variables: {
				visit_id: parseInt(match.params?.id, 10),
			},
			query: `
      mutation cancelVisit(
        $visit_id: Int!
      ) {
        update_visits_by_pk(
          pk_columns: { id: $visit_id }
          _set: {
            visit_status: "VISIT_CANCELLED"
          }
        ) {
          id
          visit_status
          cancellation_reason
          cancelled_by {
            id
            first_name
            last_name
          }
        }
      }
    `,
		};
		setState({ isCancelling: true, isError: false });
		HasuraApi.post('/v1/graphql', cancelVisitQuery)
			.then((data) => {
				setState({
					visitData: { ...state.visitData, ...data?.data?.update_visits_by_pk },
					isCancelling: false,
					isError: false,
					showCancelVisitModal: false,
				});
			})
			.catch((err) => {
				setState({ isCancelling: false, isError: true, retry: cancelVisit });
			});
	};

	const {
		isLoading,
		isError,
		visitData,
		lockerManager,
		lockerManagers,
		isCancelling,
		isSubmitting,
		showCancelVisitModal,
		retry,
	} = state;

	const startVisit = () => {
		const startVisitQuery = {
			variables: {
				id: parseInt(match.params?.id, 10),
				intermediate_status:
					state.visitData?.visit_type?.split('/')[0] === 'SAFE_VISIT'
						? 'SAFE_LOCATION_REACHED'
						: '-',
				visit_status:
					state.visitData?.visit_type?.split('/')[0] === 'SAFE_VISIT'
						? 'VISIT_CONFIRMED'
						: 'VISIT_IN_PROGRESS',
				visit_start_time: moment(),
			},
			query: `
      mutation myMutation(
        $id: Int!
        $visit_status: visit_status_enum = "VISIT_IN_PROGRESS"
        $intermediate_status:String
        $visit_start_time: timestamptz
      ) {
        update_visits_by_pk(
          pk_columns: { id: $id }
          _set: { visit_status: $visit_status , visit_start_time: $visit_start_time , intermediate_status: $intermediate_status}
        ) {
          id
          visit_status
          intermediate_status
        }
      }
    `,
		};
		setState({ isSubmitting: true, isError: false });
		HasuraApi.post('/v1/graphql', startVisitQuery)
			.then((data) => {
				setState({ isSubmitting: false });
				history.push({
					pathname: `/visits/start/${match.params?.id}`,
					state: {
						history: [...(location.state?.history ?? []), location.pathname],
						user_id: visitData?.user?.id,
					},
				});
			})
			.catch((err) =>
				setState({ isSubmitting: false, isError: true, retry: startVisit })
			);
	};

	useEffect(() => {
		getVisitData();
		getLockerManagers();
	}, []);

	if (isLoading) return <Loading />;
	if (isError)
		return (
			<div className='flex flex-col space-y-2 font-bold h-1/2 items-center justify-center'>
				<p>Something Went Wrong</p>
				<p>Please Try Again</p>
				<Button variant='primary' onClick={retry}>
					Retry
				</Button>
			</div>
		);

	return (
		<>
			<div className='space-y-10 h-full pb-20'>
				<div className='flex gap-10 flex-wrap items-center'>
					<p className='text-header-3 m-0'>Visit Details</p>
					<div>
						<ReactTag text={visitData?.visit_status} />
					</div>
					<div className='flex gap-5 flex-wrap'>
						<PrimaryCard>
							<KeyValue
								label='Booked on'
								value={`${moment(visitData?.visit_time).format(
									'DD-MM-YYYY | HH:mm'
								)}hrs`}
								class='capitalize m-0'
							/>
						</PrimaryCard>
						{(visitData?.visit_status === 'VISIT_IN_PROGRESS' ||
							visitData?.visit_status === 'VISIT_COMPLETED') && (
							<PrimaryCard>
								<KeyValue
									label='visit happened on'
									value={`${moment(visitData?.visit_start_time).format(
										'DD-MM-YYYY | HH:mm'
									)}hrs`}
									class='capitalize m-0'
								/>
							</PrimaryCard>
						)}
						{/* {visitData?.visit_status === 'VISIT_COMPLETED' && (
            <PrimaryCard>
              <KeyValue label="visit ended on" value={`${moment().format('DD-MM-YYYY | HH:mm')}hrs`} class="capitalize m-0" />
            </PrimaryCard>
          )} */}
					</div>
					{(visitData?.visit_status === 'VISIT_REQUESTED' ||
						visitData?.visit_status === 'VISIT_CONFIRMED') && (
						<Button
							variant='secondary'
							className='bg-white rounded-full'
							onClick={() => setState({ showCancelVisitModal: true })}
						>
							Cancel Visit
						</Button>
					)}
					{visitData?.visit_status === 'VISIT_CONFIRMED' && (
						<div className='flex space-x-5 w-1/5'>
							<Button
								onClick={startVisit}
								isLoading={isSubmitting}
								variant='cta'
								className='w-full'
							>
								Start Visit
							</Button>
						</div>
					)}
					{visitData?.visit_status === 'VISIT_IN_PROGRESS' && (
						<div className='flex space-x-5 w-1/5'>
							<AccessCheck
								requiredPermissions={
									permissions.visits.table_row.resume_visit.permission
								}
								alternateChildren={<div></div>}
								looseCheck={false}
							>
								<Button
									onClick={() => {
										history.push({
											pathname: `/visits/start/${match.params?.id}`,
											state: {
												history: [
													...(location.state?.history ?? []),
													location.pathname,
												],
												user_id: visitData?.user?.id,
											},
										});
									}}
									variant='cta'
									className='w-full text-center'
								>
									Resume Visit
								</Button>
							</AccessCheck>
						</div>
					)}
				</div>
				<div className='flex gap-5 flex-wrap items-start '>
					<PrimaryCard className='bg-oro-gradient w-72 space-y-2'>
						<div>
							<p className='text-body-large m-0'>
								{visitData?.visit_display_id}
							</p>
							<p className='text-small m-0'>{visitData?.space?.name}</p>
						</div>
						<div>
							<AccessCheck
								requiredPermissions={
									permissions.visits.table_row.space_details.permission
								}
								alternateChildren={<div></div>}
								looseCheck={false}
							>
								<Link
									className='bg-white rounded-full px-3 py-1 text-small-bold '
									to={`/spaces/details/${visitData?.space?.id}`}
								>
									{visitData?.space?.space_number}
								</Link>
							</AccessCheck>
						</div>
					</PrimaryCard>
					<PrimaryCard className='w-72 space-y-5'>
						<div className='flex justify-between items-center'>
							<p className='text-body-bold m-0'>Customer Details</p>
							<p className='bg-oro-yellow rounded-lg px-3 py-2 m-0 text-small-bold capitalize'>
								{`${
									visitData?.visit_type?.split('/')[0] === 'SAFE_VISIT'
										? 'Safe'
										: visitData?.visit_type?.split('/')[1]?.toLowerCase()
								} visit`}
							</p>
						</div>
						{visitData?.visit_type?.split('/')[0] !== 'SAFE_VISIT' ? (
							<>
								<KeyValue
									label='Primary Customer name'
									value={getFullName(
										visitData?.first_name,
										visitData?.last_name
									)}
									class='capitalize'
								/>
								<div className='flex flex-col space-y-2'>
									<KeyValue
										label='Visitor Name'
										value={getFullName(
											visitData?.first_name,
											visitData?.last_name
										)}
										class='m-0'
									/>
									<span className='self-start bg-medium-yellow px-3 py-1 text-small-bold rounded-full'>
										Primary Customer
									</span>
								</div>
							</>
						) : (
							<>
								<AccessCheck
									requiredPermissions={
										permissions.visits.table_row.customer_details.permission
									}
									alternateChildren={
										<KeyValue
											label='Primary Customer name'
											value={getFullName(
												visitData?.user?.first_name,
												visitData?.user?.last_name
											)}
											class='underline capitalize'
										/>
									}
									looseCheck={false}
								>
									<KeyValue
										label='Primary Customer name'
										value={getFullName(
											visitData?.user?.first_name,
											visitData?.user?.last_name
										)}
										link={`/customers/details/${visitData?.user?.id}`}
										class='underline capitalize'
									/>
								</AccessCheck>
								<div className='flex flex-col space-y-2'>
									<AccessCheck
										requiredPermissions={
											permissions.visits.table_row.customer_details.permission
										}
										alternateChildren={
											<KeyValue
												label='Visitor Name'
												value={getFullName(
													visitData?.customer?.first_name,
													visitData?.customer?.last_name
												)}
												class='underline m-0'
											/>
										}
										looseCheck={false}
									>
										<KeyValue
											label='Visitor Name'
											value={getFullName(
												visitData?.customer?.first_name,
												visitData?.customer?.last_name
											)}
											link={`/customers/details/${visitData?.user?.id}`}
											class='underline m-0'
										/>
									</AccessCheck>
									<span className='self-start bg-medium-yellow px-3 py-1 text-small-bold rounded-full'>
										{visitData?.user?.first_name ==
											visitData?.customer?.first_name &&
										visitData.user?.last_name === visitData?.customer?.last_name
											? 'Primary Customer'
											: 'Secondary/Tertiary'}
									</span>
								</div>
							</>
						)}
					</PrimaryCard>
					{visitData?.visit_status === 'VISIT_REQUESTED' && (
						<Form onSubmit={assignVisit} className='w-1/3'>
							<div className='flex w-full'>
								<p className='text-header-3 hidden md:block'>
									Assign
									<br />
									Visit
								</p>
								<PrimaryCard className='ml-3 p-10 space-y-10 w-full'>
									<p className='text-header-3 md:hidden'>Assign Visit</p>
									<LabelInput
										type='select'
										label='Locker Agent'
										placeholder='Choose Agent'
										value={lockerManager ?? ''}
										onChange={(e) =>
											setState({ lockerManager: e.target.value })
										}
										options={
											lockerManagers?.map((agent) => ({
												label: `${agent?.first_name} ${agent?.last_name}`,
												value: agent?.auth_id,
											})) ?? []
										}
										required
									/>
									<Button
										variant='cta'
										type='submit'
										className='w-full'
										isLoading={isSubmitting}
									>
										Assign Visit
									</Button>
								</PrimaryCard>
							</div>
						</Form>
					)}
					{(visitData?.visit_status === 'VISIT_CONFIRMED' ||
						visitData?.visit_status === 'VISIT_IN_PROGRESS' ||
						visitData?.visit_status === 'VISIT_COMPLETED') && (
						<PrimaryCard className='space-y-5'>
							<div className='flex justify-between items-center'>
								<p className='text-body-bold m-0 mr-14'>Visit Confirmation</p>
							</div>
							<KeyValue
								label='visit confirmed by'
								value={visitData?.assigned_by?.first_name}
							/>
							<AccessCheck
								requiredPermissions={
									permissions.visits.table_row.space_details.permission
								}
								alternateChildren={
									<KeyValue
										label='visit confirmed at'
										value={visitData?.space?.name}
										class='underline m-0'
									/>
								}
								looseCheck={false}
							>
								<KeyValue
									label='visit confirmed at'
									value={visitData?.space?.name}
									link={`/spaces/details/${visitData?.space?.id}`}
									class='underline m-0'
								/>
							</AccessCheck>
						</PrimaryCard>
					)}
					{(visitData?.visit_status === 'VISIT_IN_PROGRESS' ||
						visitData?.visit_status === 'VISIT_COMPLETED') && (
						<PrimaryCard className='flex'>
							<div className='border-r border-[#E6E6E6] pr-14 mr-10'>
								<p className='text-header-3 mb-5'>
									Locker
									<br />
									Visit
								</p>
								<p className='bg-oro-yellow rounded-lg px-3 py-2 m-0 text-small-bold'>
									{visitData?.visit_display_id}
								</p>
							</div>
							<div className='space-y-5'>
								<p className='text-body-bold mr-5'>Locker Agent</p>
								<div className='flex'>
									<div className='mr-2 space-y-5'>
										<KeyValue
											label='Agent name'
											value={visitData?.agent?.first_name}
										/>
										<KeyValue
											label='Agent mobile number'
											value={visitData?.agent?.mobile_number}
											class='m-0'
										/>
									</div>
									<KeyValue
										label='Agent id'
										value={visitData?.agent?.agent?.agent_display_id}
										class='font-bold text-[#5A953E]'
									/>
								</div>
							</div>
						</PrimaryCard>
					)}
				</div>
			</div>
			{showCancelVisitModal && (
				<Modal
					className='p-10 flex flex-col space-y-10  align-middle text-center
        w-3/4 md:w-2/5 xl:w-1/3 2xl:w-1/4'
					onClose={() => setState({ showCancelVisitModal: false })}
				>
					<WarningIcon className='mb-5 mx-auto' />
					<div className='flex space-y-1 flex-col'>
						<p className='text-body-large'>Warning!</p>
						<p className='text-body-large w-3/4 mx-auto'>
							{' '}
							Are you sure you want to cancel the visit
						</p>
					</div>
					<div className='flex space-x-5 justify-center '>
						<Button
							variant='normal'
							className='w-1/2'
							isLoading={isCancelling}
							onClick={cancelVisit}
						>
							Cancel
						</Button>
						<Button
							variant='cta'
							className='w-1/2'
							onClick={() => setState({ showCancelVisitModal: false })}
						>
							Don't Cancel
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
}
