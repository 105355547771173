import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import OroSafeLogo from '../../assets/oro-safe.svg';
import Button from '../../components/Buttons/Button';
import LabelInput from '../../components/Form/LabelInput';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Form from '../../components/Form/Form';
import HasuraApi from '../../utils/ApiUtils/HasuraApi';
import Loading from '../../components/loading';
import Api from '../../utils/ApiUtils/Api';
import TickIcon from '../../assets/tick.svg';
import ImageModal from '../../components/ImageModal';
import useTimer from '../../utils/useTimer';
import Notification from '../../components/Notifications';

export default function ExistingCustomerVisit({ setCurrent}) {
	const { id: visitId } = useParams();

  const { triggerCounter, isCounterRunning } = useTimer({
		time: 1,
		timerName: visitId,
	});

	const [state, setStateHelper] = useState({ 
		isLoading: true,
		isError: false,
		isSubmitting: false,
		image: null,
		showImageModal: false,
		customerType: '',
		lockersForVisit: [],
		otp: null,
	  });
	
	  const setState = (changes) => {
		setStateHelper((prevState) => ({
		  ...prevState,
		  ...changes,
		}));
	  };
	
	  const getData = () => {
		setState({isLoading: true});
		Api.get(`/visit/lockers-details/${visitId}`).then(
		  (data) => {      
			setState({
			  isLoading: false,
			  lockersForVisit: data.data?.lockers,
			  image: data?.data?.live_photo,
			  customerType: data?.data?.userType
			})
		  },
		).catch(err => {
		  setState({isLoading: false, isError: true, retry: getData})
		});
	  };
	
	  useEffect(() => {
		getData();
	  }, []);
	
	  const startVisit = () => {
		const startVisitQuery = {
		  variables: {
			id: parseInt(visitId, 10),
			visit_status: 'VISIT_IN_PROGRESS',
			visit_start_time: moment(),
		  },
		  query: `
      mutation myMutation(
        $id: Int!
        $visit_status: visit_status_enum = "VISIT_IN_PROGRESS"
        $visit_start_time: timestamptz
      ) {
        update_visits_by_pk(
          pk_columns: { id: $id }
          _set: { visit_status: $visit_status , visit_start_time: $visit_start_time}
        ) {
          id
          visit_status
          intermediate_status
        }
      }
    `,};
		setState({ isLoading: true });
		HasuraApi.post('/v1/graphql', startVisitQuery)
			.then((data) => {
				if (data?.data) {
					setState({ isLoading: false });
					setCurrent(4);
				}
			})
			.catch((err) => setState({ isLoading: false, isError: true }));
	};

	const validateOtp = () => {
		const query = {
			variables: {
				visit_id: parseInt(visitId, 10),
				otp,
				intermediate_status: 'SAFE_CUSTOMER_OTP_VALIDATED',
			},
			query: `
      mutation validateOTP($visit_id: Int!, $otp: String!, $intermediate_status: String!) {
        validateOTP(visit_id: $visit_id, otp: $otp, intermediate_status: $intermediate_status) {
          message
        }
      }`,
		};
		setState({ isSubmitting: true });
		HasuraApi.post('/v1/graphql', query)
			.then((data) => {
				setState({ isSubmitting: false });
				if (
					data?.data?.validateOTP?.message === 'OTP validate and status changed'
				) {
					startVisit();
				}
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};
	// const columns = [
	//   {
	//     title: 'Oro Safe ID',
	//     dataIndex: 'oro_safe_id',
	//     key: 'oro_safe_id',
	//     render: (text) => <p className="text-body-bold p-2">{text}</p>,
	//   },
	//   {
	//     title: 'Gold Items',
	//     dataIndex: 'gold_items',
	//     key: 'gold_items',
	//     width: 200,
	//     render: (text) => <p className="text-body-bold p-2">{text}</p>,
	//   },
	//   {
	//     title: 'Quality',
	//     dataIndex: 'quality',
	//     key: 'quality',
	//     render: (text) => <p className="text-body-bold p-2">{text}</p>,
	//   },
	//   {
	//     title: 'Quantity',
	//     dataIndex: 'quantity',
	//     key: 'quantity',
	//     render: (text) => <p className="text-body-bold p-2">{text}</p>,
	//   },
	//   {
	//     title: 'Net Weight',
	//     dataIndex: 'net_weight',
	//     key: 'net_weight',
	//     render: (text) => <p className="text-body-bold p-2">{text}</p>,
	//   },
	//   {
	//     title: 'Gold Photos',
	//     dataIndex: 'gold_photos',
	//     key: 'gold_photos',
	//     render: () => (
	//       <Button variant="secondary" className="px-3 py-2 text-xs font-extrabold text-subtle">
	//         view Photo
	//       </Button>
	//     ),
	//   },
	//   {
	//     title: 'Caratmenter Photos',
	//     dataIndex: 'caratmeter_photo',
	//     key: 'caratmeter_photo',
	//     render: () => (
	//       <Button variant="secondary" className="px-3 py-2 text-xs font-extrabold text-subtle">
	//         view Photo
	//       </Button>
	//     ),
	//   },
	//   {
	//     title: 'Certificate',
	//     dataIndex: 'certificate',
	//     key: 'certificate',
	//     render: () => (
	//       <Link variant="primary" className="text-sm font-bold inline-block" to="/digital-certificates/details">
	//         View  Certificate
	//       </Link>
	//     ),
	//   },
	//   {
	//     title: '',
	//     dataIndex: 'action',
	//     key: 'action',
	//     fixed: 'right',
	//     width: 50,
	//     render: () => <img src={EditIcon} alt="" />,
	//   },
	// ];
	const {
		lockersForVisit,
		isError,
		isLoading,
		isSubmitting,
		image,
		otp,
		showImageModal,
		customerType,
	} = state;

	if (isLoading) return <Loading />;
	if (isError)
		return (
			<div className='flex flex-col space-y-2 font-bold h-1/2 items-center justify-center'>
				<p>Something Went Wrong</p>
				<p>Please Try Again</p>
				<Button variant='primary' onClick={() => window.location.reload()}>
					Retry
				</Button>
			</div>
		);

  const onResendOTP =  debounce((e) => {
    e.stopPropagation();
    Api.patch(`/visit/safe-otp/${visitId}`).then((data) => {
      triggerCounter()
      Notification.create('success', 'OTP Sent Successfully');

    })}, 1000)

	return (
		<>
			<Form onSubmit={() => validateOtp()} className='space-y-10'>
				<PrimaryCard className='p-10 space-y-10 mx-auto flex flex-col items-center justify-center w-full md:w-3/4'>
					<img
						width='120px'
						height='82px'
						src={OroSafeLogo}
						className='mx-auto'
						alt='oro-safe'
					/>
					<p className='text-body-large w-[72%] m-auto mb-[45px]'>
						{' '}
						Please enter the OTP shared by the customer here to start the visit
					</p>
					<div className='flex items-start'>
						<div>
              <div className='flex items-center gap-4 justify-center mr-[10px]'>
							<LabelInput
								labelClassName='text-left'
								label='Enter OTP here'
								type='numberText'
								value={otp}
								rootClassName='w-full md:w-1/2 lg:w-auto'
								onChange={(value) => setState({ otp: value })}
								required
							/>
							<Button 
                disabled={isCounterRunning}
								variant='primary'
								type="button"
								className={`text-sm relative mt-10 ${ isCounterRunning ? '!opacity-50 text-[#909090] cursor-not-allowed' : '' }`}
								onClick={(e) => onResendOTP(e)}
							>
								Resend OTP
							</Button>
              </div>
              <div>
               { isCounterRunning ? <p className='text-body-large text-[16px] mt-[20px]'>OTP sent again. Wait for 1 minute to retry.</p> : null }
              </div>
						</div>
						<div className='flex gap-3 items-start ml-5 relative left-12'>
							<div className='space-y-2'>
								<p className='label'>recent photo of customer</p>
								<Button
									variant='primary'
									type='button'
									className='text-sm'
									onClick={() => setState({ showImageModal: true })}
								>
									View Recent Photo
								</Button>
							</div>
							<Button
								type='button'
								className='px-2 py-1 bg-medium-yellow text-small-bold rounded-lg uppercase'
							>
								{customerType}
							</Button>
						</div>
					</div>
          <div className='w-[72%]'>
            {
              lockersForVisit ? <p className="label">Lockers To Visit</p> : null
            }
          </div>
					<div className='flex gap-5 flex-wrap w-[72%] !my-5'>
						{lockersForVisit &&
							Object.keys(lockersForVisit).map((key, index) => (
								<div key={key} className='space-y-5'>
									<p className='text-xs font-semibold'>{key} type</p>
									<div className='space-y-4'>
										{lockersForVisit[key]?.map(
											({ locker_id, locker_number }) => (
												<div key={locker_id} className='flex gap-2'>
													<img src={TickIcon} alt='' className='w-4' />
													<p className='text-xs font-semibold'>
														{locker_number}
													</p>
												</div>
											)
										)}
									</div>
								</div>
							))}
					</div>
				</PrimaryCard>
				{/* <Table scroll={{ x: 1500 }} columns={columns} dataSource={goldData} pagination={false} /> */}
				<div className='flex gap-5 flex-col md:flex-row w-full md:w-3/4 text-center mx-auto'>
					{/* <Button type="button" variant="normal" className="mx-auto w-full md:w-1/2 bg-white border border-[#CECECE]"
          onClick={() => setCurrent(2)}
        >
          Add Lockers
        </Button> */}
					<Button
						variant='cta'
						type='submit'
						isLoading={isSubmitting}
						className='mx-auto w-full md:w-1/2'
					>
						Confirm Customer
					</Button>
				</div>
			</Form>
			{showImageModal && (
				<ImageModal
					imageUrl={image}
					onClose={() => setState({ showImageModal: false })}
				/>
			)}
		</>
	);
}
