import React from "react";
export default function SearchSvg({
  width = "32",
  height = "32",
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.3"
        y="0.3"
        width="31.4"
        height="31.4"
        rx="3.7"
        fill="#FDFAF4"
      />
      <path
        d="M14.9091 10C13.9381 10 12.989 10.2879 12.1817 10.8273C11.3744 11.3667 10.7452 12.1334 10.3737 13.0304C10.0021 13.9275 9.90491 14.9145 10.0943 15.8668C10.2837 16.819 10.7513 17.6937 11.4378 18.3803C12.1244 19.0668 12.9991 19.5344 13.9514 19.7238C14.9036 19.9132 15.8907 19.816 16.7877 19.4444C17.6847 19.0729 18.4514 18.4437 18.9908 17.6364C19.5302 16.8291 19.8181 15.88 19.8181 14.9091C19.818 13.6071 19.3008 12.3585 18.3802 11.4379C17.4596 10.5173 16.211 10.0001 14.9091 10Z"
        fill="#FFAA07"
        stroke="#454545"
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5718 18.5715L22.0002 22"
        stroke="#454545"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <rect
        x="0.3"
        y="0.3"
        width="31.4"
        height="31.4"
        rx="3.7"
        stroke="#FFAA07"
        strokeWidth="0.6"
      />
    </svg>
  );
}