/* eslint-disable indent */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable max-len */
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Buttons/Button";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import KeyValue from "../../../../components/Form/KeyValue";
import Loading from "../../../../components/loading";
import ReactTag from "../../../../components/ReactTag";
import { DOORSTEP_CUSTOMER_TYPES } from "../../../../constants/DoorstepVisitsRadioButtonOptions";
import { getFullName } from "../../../../utils";
import HasuraApi from "../../../../utils/ApiUtils/HasuraApi";
import AgentDetailsCard from "./AgentDetailsCard";
import ApproveKycForm from "./ApproveKycDetails";
import AssignVisitCard from "./AssignVisitCard";
import ConfirmVisitFormCard from "./ConfirmVisitFormCard";
import LockerStorageCard from "./LockerStorageCard";
import OtpVerificationModal from "./OtpVerificationModal";
import VisitConfirmationDetailsCard from "./VisitConfirmationDetailsCard";
import Link from '../../../../components/Link';
import {
  getStartVisitQuery,
  getVisitByIdQuery,
  VISIT_INTERMEDIATE_STATUES,
  VISIT_STATUES
} from "./constants";

export default function DoorstepVisitDetails() {
  const { id } = useParams();
  const [state, setStateHelper] = useState({
    lockerManager: null,
    lockerManagers: null,
    isLoading: true,
    retry: () => {},
    isError: false,
    isSubmitting: false,
    isCancelling: false,
    showCancelVisitModal: false,
    visitData: {},
    toggleLockerStorageCard: false,
    toggleOtpVerificationModal: false,
    toggleApproveKycForm: false
  });

  const onSetState = useCallback((changes) => {
    setStateHelper((prevState) => ({ ...prevState, ...changes }));
  });

  // use this function get visit details
  const getVisitData = useCallback(() => {
    const visitByIdQuery = getVisitByIdQuery(id);
    onSetState({ isLoading: true, isError: false });
    HasuraApi.post("/v1/graphql", visitByIdQuery)
      .then((data) =>
        onSetState({ visitData: data?.data?.visits[0], isLoading: false })
      )
      .catch(() =>
        onSetState({ isLoading: false, isError: true, retry: getVisitData })
      );
  }, []);

  // use this function to start the visit
  const onStartVisit = () => {
    const startVisitQuery = getStartVisitQuery(id);
    onSetState({ isLoading: true });
    HasuraApi.post("/v1/graphql", startVisitQuery)
      .then(() => {
        onSetState({ isLoading: false });
        getVisitData();
        onSetState({  toggleOtpVerificationModal: true });
      })
      .catch(() =>
        onSetState({ isLoading: false, isError: true, retry: onStartVisit })
      );
  };

  useEffect(() => {
    getVisitData();
  }, []);

  // DESTRUCTURE DATA
  const {
    isLoading,
    visitData,
    toggleLockerStorageCard,
    toggleOtpVerificationModal,
    toggleApproveKycForm
  } = state;

  if (isLoading) return <Loading />;

  function renderCardBasedOnStatus(status) {
    // if visit is in requested state render the confirm visit card
    if (status === VISIT_STATUES.visitRequested)
      return (
        <ConfirmVisitFormCard
          visitData={visitData}
          onUpdateStatus={() => getVisitData()}
        />
      );
    // if visit is in confirmed state render the assign visit card
    if (status === VISIT_STATUES.visitConfirmed)
      return <AssignVisitCard visitId={id} onAssign={() => getVisitData()} />;
    // if visit is in assigned state render the assigned
    if (
      status === VISIT_STATUES.visitAssigned ||
      status === VISIT_STATUES.visitInprogress ||
      status === VISIT_STATUES.visitCompleted
    ) {
      return (
        <div className="flex gap-5 items-start">
          <VisitConfirmationDetailsCard visitData={visitData} />
          <AgentDetailsCard visitData={visitData} />
        </div>
      );
    }
  }

  return (
    <>
      <div className="space-y-10 h-full pb-20">
        <div className="flex gap-10 flex-wrap items-center">
          <p className="text-header-3 m-0">Visit Details</p>
          <div>
            <ReactTag
              text={
                visitData?.visit_status === VISIT_STATUES.visitInprogress &&
                visitData?.intermediate_status ===
                  VISIT_INTERMEDIATE_STATUES.newVisit.kycVerificationSubmitted
                  ? "PENDING_KYC_APPROVAL"
                  : visitData?.visit_status
              }
            />
          </div>
          <div className="flex gap-5 flex-wrap">
            <PrimaryCard className="py-3 px-4">
              <KeyValue
                label="Booked on"
                value={`${moment(visitData?.visit_time).format(
                  "DD-MM-YYYY | HH:mm"
                )}hrs`}
                class="capitalize m-0"
              />
            </PrimaryCard>
            {(visitData?.visit_status === "VISIT_IN_PROGRESS" ||
              visitData?.visit_status === "VISIT_COMPLETED") &&
                <PrimaryCard className="py-3 px-4">
                  <KeyValue
                    label="visit happened on"
                    value={`${moment(visitData?.visit_start_time).format(
                    "DD-MM-YYYY | HH:mm"
                  )}hrs`}
                    class="capitalize m-0"
                />
                </PrimaryCard>}
          </div>
          {visitData?.visit_status === VISIT_STATUES.visitAssigned &&
            visitData?.intermediate_status ===
              VISIT_INTERMEDIATE_STATUES.existingVisit
                .agentReachedSafeToCollectLocker &&
                  <div className="flex space-x-5 w-1/5">
                    <Button
                      onClick={() => onStartVisit()}
                      variant="cta"
                      className="w-full"
                >
                      Start Visit
                    </Button>
                  </div>}
          {visitData?.visit_status === VISIT_STATUES.visitInprogress &&
            visitData?.intermediate_status ===
              VISIT_INTERMEDIATE_STATUES.existingVisit
                .agentReachedSafeToCollectLocker &&
                  <div className="flex space-x-5 w-1/5">
                    <Button
                      onClick={() => onSetState({ toggleOtpVerificationModal: true })}
                      variant="cta"
                      className="w-36"
                >
                      Share Otp
                    </Button>
                  </div>}
          {visitData?.visit_status === VISIT_STATUES.visitInprogress &&
            visitData?.intermediate_status ===
              VISIT_INTERMEDIATE_STATUES.common.reachedSafeWithLocker &&
            !toggleLockerStorageCard &&
              <div className="flex space-x-5 w-1/5">
                <Button
                  onClick={() => onSetState({ toggleLockerStorageCard: true })}
                  variant="cta"
                  className="w-full"
                >
                  Start Locker Storage
                </Button>
              </div>}
          {visitData?.visit_status === VISIT_STATUES.visitInprogress &&
            visitData?.intermediate_status ===
              VISIT_INTERMEDIATE_STATUES.newVisit.kycVerificationSubmitted &&
            !toggleApproveKycForm &&
              <div className="flex space-x-5 w-1/5">
                <Button
                  onClick={() => onSetState({ toggleApproveKycForm: true })}
                  variant="cta"
                  className="w-full"
                >
                  Approve Safe KYC
                </Button>
              </div>}
        </div>
        {toggleApproveKycForm ?
          <ApproveKycForm
            userId={visitData?.customer?.id}
            onUpdate={() => {
              onSetState({ toggleApproveKycForm: false });
              getVisitData();
            }}
          />
         :
          <div className="flex gap-5 flex-wrap items-start">
            {/* VISIT DETAILS CARD  */}
            <PrimaryCard className="bg-oro-gradient w-56">
              <div className="py-2">
                <p className="text-body-large m-0">
                  {visitData?.visit_display_id}
                </p>
              </div>
              <div className="py-2">
                <KeyValue
                  label="customer name"
                  value={getFullName(
                    visitData?.customer?.first_name,
                    visitData?.customer?.last_name
                  )}
                  labelStyle="uppercase text-black"
                  class="underline"
                />
              </div>
              <div className="py-2">
                <KeyValue
                  label="Visit type"
                  value="Doorstep"
                  labelStyle="uppercase text-black"
                />
              </div>
              <div className="py-2">
                <KeyValue
                  label="Visit sub-type"
                  value={DOORSTEP_CUSTOMER_TYPES[visitData?.visit_type].label}
                  labelStyle="uppercase text-black"
                />
              </div>
            </PrimaryCard>
            {/* CUSTOMER DETAILS CARD  */}
            <PrimaryCard className="w-72">
              <div className="flex justify-between items-center py-2">
                <p className="text-body-bold m-0">Customer Details</p>
              </div>
              <div className="flex flex-row items-start pt-2 pb-1.5">
                <div style={{ width: "50%" }}>
                  <KeyValue
                    label="customer name"
                    value={Object.keys(visitData?.customer)?.length > 0 ? getFullName(
                      visitData?.customer?.first_name,
                      visitData?.customer?.last_name
                    ) : getFullName(
                      visitData?.first_name,
                      visitData?.last_name
                    )}
                    labelStyle="uppercase"
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <KeyValue
                    label="Mobile number"
                    value={visitData?.mobile_number}
                    labelStyle="uppercase"
                  />
                </div>
              </div>
              <div className="flex flex-row items-start pt-2 pb-1.5">
                <div style={{ width: "50%" }}>
                  <KeyValue
                    label="customer id"
                    value={`OSC000${visitData?.customer?.id}`}
                    labelStyle="uppercase"
                    class="underline text-[#5A953E]"
                    link={`/customers/details/${visitData?.customer?.id}`}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <KeyValue
                    label="Pincode"
                    value={visitData?.address?.pincode}
                    labelStyle="uppercase"
                  />
                </div>
              </div>
              <div className="pt-2 pb-1.5">
                <p className="text-xsm text-subtle font-extrabold uppercase mb-1">
                  customer Address
                </p>
                <p className="text-base font-normal" style={{ width: "60%" }}>
                  {visitData?.address?.addr_line_1},
                </p>
                <p
                  className="text-base font-normal w-8/12"
                  style={{ width: "60%" }}
                >
                  {visitData?.address?.addr_line_2},
                </p>
                <p className="text-base font-normal">
                  {visitData?.address?.city} - {visitData?.address?.pincode}
                </p>
                <Link to={`/customers/details/${visitData?.customer?.id}`} variant="secondary" className="mt-2 text-xs">
                  View Customer Details
                </Link>
              </div>
            </PrimaryCard>
            {/* RENDER DIFFERENT CARDS BASED ON STATUS  */}
            {renderCardBasedOnStatus(visitData?.visit_status)}
            {/* OTP VERIFICATION POPUP FOR VERIFYING AGENT BY LOCKER MANAGER WHILE STORING SAFE  */}
            {toggleLockerStorageCard ||
            visitData?.visit_status === VISIT_STATUES.visitCompleted ||
            visitData?.visit_status === VISIT_STATUES.visitInprogress &&
              (visitData?.intermediate_status === VISIT_INTERMEDIATE_STATUES.common.lockerHandoverToLockerManager ||
                visitData?.intermediate_status ===
                  VISIT_INTERMEDIATE_STATUES.common.lockerStoredInSafe) ?
                    <LockerStorageCard
                      isOtpVerified={
                      visitData?.intermediate_status ===
                        VISIT_INTERMEDIATE_STATUES.common.agentVerified ||
                        visitData?.intermediate_status ===
                          VISIT_INTERMEDIATE_STATUES.common
                            .lockerHandoverToLockerManager ||
                      visitData?.intermediate_status ===
                        VISIT_INTERMEDIATE_STATUES.common.lockerStoredInSafe ||
                      visitData?.visit_status === VISIT_STATUES.visitCompleted &&
                        visitData?.intermediate_status ===
                          VISIT_INTERMEDIATE_STATUES.common
                            .lockerHandoverToLockerManager
                    }
                      toggleStoreLockerCard={visitData?.intermediate_status === VISIT_INTERMEDIATE_STATUES.common.lockerHandoverToLockerManager}
                      onUpdate={() => {
                        onSetState({
                          toggleLockerStorageCard: false
                        });
                        getVisitData();
                      }}
                      isLockerStored={
                  visitData?.intermediate_status ===
                  VISIT_INTERMEDIATE_STATUES.common.lockerStoredInSafe
                }
              />
             : null}
          </div>}
      </div>
      {toggleOtpVerificationModal && <OtpVerificationModal onClose={() => onSetState({toggleOtpVerificationModal: false})} />}
    </>
  );
}
