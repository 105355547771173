import axios from 'axios';
import Notification from '../../components/Notifications';
const ApiPrototype = {
  // only methods common for all baseURLs
  get(path, config = {}) {
    return this.api.get(path, config);
  },
  post(path, payload, config = {}) {
    return this.api.post(path, payload, config);
  },
  put(path, payload, config = {}) {
    return this.api.put(path, payload, config);
  },
  patch(path, payload, config = {}) {
    return this.api.patch(path, payload, config);
  },
};

function responseInterceptor(res) {
  if(res?.data?.errors){
    res?.data?.errors?.map((error) => Notification.create('error', error?.message))
    throw "Something Went Wrong"
  }
  return res?.data;
}

function errorInterceptor(err) {
  switch(err?.response?.status?.toString()[0]) {
  case '4':
    if(err?.response?.data?.message === 'jwt expired' || err?.response?.data?.message === 'invalid signature') throw err;
    Notification.create('error', err?.response?.data?.message);
    break;
  case '5':
    Notification.create('error', "Something went wrong");
    break;    
  }
  throw err;
}

function ApiInit(config) {
  const instance = Object.create(ApiPrototype);
  instance.api = axios.create(config);
  instance.api.interceptors.response.use(responseInterceptor, errorInterceptor);
  return instance;
}

const ApiUtils = ApiInit({});
export default ApiUtils;
