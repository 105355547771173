import React, { useState } from 'react';

// ** assets
import filterIcon from '../../assets/filterIcon2.png';
import SingleSelect from '../../components/SingleSelect';

// ** components
import SearchInput from './Input';

// ** constants
import { MONTHS_DROPDOWN_OPTIONS, YEAR_DROPDOWN_OPTIONS } from './constants';

/* eslint-disable eslint-plugin/require-meta-schema */
/* eslint-disable eslint-plugin/require-meta-type */
/* eslint-disable eslint-plugin/prefer-object-rule */
const useFilterHook = (filterData) => {
	const [year, setYear] = useState({
		label: '',
		value: 0,
	});
	const [month, setMonth] = useState({
		label: '',
		value: 0,
	});

	const [search, setSearch] = useState('');

	const FilterComponent = (
		<div className='border-[1px] border-[#E6E6E6] rounded-[8px] bg-white p-[8px] flex justify-between'>
			<div className='flex py-[6px] gap-[16px]'>
				<div className='flex gap-[4px] items-center'>
					<img src={filterIcon} alt='filter icon' height='21px' width='21px' />
					<p className='text-[16px] font-normal text-[#454545]'>Filters</p>
				</div>
				<div className='flex gap-[8px]'>
					<SingleSelect
						onSelectOption={(yearData) => {
							filterData({
								searchValue: search,
								searchYear: yearData?.value,
								searchMonth: month?.value,
							});
							setYear(yearData);
						}}
						placeholder='Year'
						dropdownOptions={YEAR_DROPDOWN_OPTIONS}
					/>
					<SingleSelect
						onSelectOption={(monthData) => {
							filterData({
								searchValue: search,
								searchYear: year?.value,
								searchMonth: monthData?.value,
							});
							setMonth(monthData);
						}}
						placeholder='Month'
						dropdownOptions={MONTHS_DROPDOWN_OPTIONS}
					/>
				</div>
			</div>
			<div className='w-[40%]'>
				<SearchInput
					onSearch={(searchData) => {
						filterData({
							searchValue: searchData,
							searchYear: year?.value,
							searchMonth: month?.value,
						});
						setSearch(searchData);
					}}
					placeHolder='Search using mobile number, customer name, locker id'
				/>
			</div>
		</div>
	);

	return {
		Component: FilterComponent,
		meta: {
			search,
			month,
			year,
		},
	};
};

export default useFilterHook;
