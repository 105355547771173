import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Link from '../../components/Link';
import ReactTag from '../../components/ReactTag';
import Action from '../../assets/Action.svg';
import Api from '../../utils/ApiUtils/Api';
import { debounce } from 'lodash';
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';

const Spaces = () => {
  const [state, setStateHelper] = useState({
    spacesData: [],
    isLoading: true,
    isError: false,
    searchValue: '',
    count: 0,
    limit: 10,
    currentPage: 1,
  })
  const setState = (changes) => {
    setStateHelper((prevState) => ({
      ...prevState,
      ...changes,
    }));
  };


  const getLockerCount = (data) => {
    const res = { locker_count: 0, booked_locker_count: 0 };
    for (let i = 0; i < data?.length; i++) {
      res.locker_count += (data[i]?.locker_count ?? 0);
      res.booked_locker_count += (data[i]?.booked_locker_count ?? 0);
    }
    return res;
  };

  const columns = [
    {
      title: 'Space ID',
      dataIndex: 'space_number',
      key: 'space_number',
      show: true,
      render: (text) => <p className="text-body-bold p-2">{text}</p>,
    },
    {
      title: 'Space Name',
      dataIndex: 'name',
      key: 'name',
      show: true,
      render: (text) => <p className="text-body-bold">{text}</p>,
    },
    {
      title: 'City',
      dataIndex: 'spaceAddress',
      key: 'spaceAddress',
      show: true,
      width: 180,
      render: (text) => <p className="text-body-bold capitalize">{text?.city}</p>,
    },
    {
      title: 'Total Lockers',
      dataIndex: 'total_lockers',
      key: 'total_lockers',
      show: true,
      render: (text, data) => (
        <p className="text-body-bold">
          {getLockerCount(data?.spaceLockerActivity)?.locker_count}
        </p>
      )
      ,
    },
    {
      title: 'Booked Lockers',
      dataIndex: 'booked_lockers',
      key: 'booked_lockers',
      show: true,
      render: (text, data) => (
        <p className="text-body-bold text-error-red">
          {getLockerCount(data?.spaceLockerActivity)?.booked_locker_count}
        </p>
      ),
    },
    {
      title: 'Available Lockers',
      dataIndex: 'available_lockers',
      key: 'available_lockers',
      show: true,
      render: (text, data) => (
        <p className="text-body-bold text-new-green">
          {getLockerCount(data?.spaceLockerActivity)?.locker_count
            - getLockerCount(data?.spaceLockerActivity)?.booked_locker_count}
        </p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      show: true,
      width: 200,
      render: (text) => <ReactTag text={text} />,
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      show: true,
      fixed: 'right',
      width: 50,
      render: (text, data) => (
        <AccessCheck
          requiredPermissions={permissions.spaces.table_row.permission}
          alternateChildren={<div></div>}
          looseCheck={false}
        >
          <Link to={`spaces/details/${data?.id}`}>
            <img src={Action} alt="" />
          </Link>
        </AccessCheck>
      ),
    },
  ];
  const getSpaces = (page = 1, search = null) => {
    const { limit, searchValue, currentPage } = state;
    setState({ isLoading: true, isError: false });
    Api.get(`space-config/space-detail-list?page=${page ?? currentPage}&limit=${limit}&search=${search ?? searchValue}`).
      then((data) => {
        setState({ spacesData: data?.spaces?.rows, count: data?.spaces?.count, isLoading: false })
      }).catch(err => setState({ isLoading: false, isError: true }));
  }
  useEffect(() => {
    getSpaces();
  }, []);

  const handleTableChange = (pagination) => {
    setState({ currentPage: pagination.current })
    getSpaces(pagination.current);
  };
  const handleSearch = debounce((value) => {
    setState({ searchValue: value })
    getSpaces(1, value);
  }, 500)

  const { spacesData, isLoading, isError, count, limit } = state;
  return (
    <div className="space-y-10">
      <div className="flex items-center justify-between">
        <div className="flex gap-10 items-center flex-wrap">
          <p className="text-header-3">All Spaces</p>
          <AccessCheck
            requiredPermissions={permissions.spaces.create_new_space.permission}
            alternateChildren={<div></div>}
            looseCheck={false}
          >
            <Link to="/spaces/create" variant="secondary" className="text-xs">Create New Space </Link>
          </AccessCheck>
        </div>
        <div className="relative">
          <input type="search" placeholder="Search using any parameter" className="input-normal font-bold outline-none w-96 p-2"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
        </div>
      </div>
      <div>
        <Table loading={isLoading} scroll={{ x: 1300 }} columns={columns}
          pagination={{ position: ['bottomCenter'], total: count, pageSize: limit }}
          onChange={handleTableChange} dataSource={spacesData}
        />
      </div>
    </div>
  );
};

export default Spaces;
