import React from "react";
import Login from "./Login";
import styles from './LandingPage.module.scss';

export default function LandingPage({ setAuthenticated }) {
  return (
    <div className={`flex items-center  ${styles.background}`}>
      <div className="shadow-lg p-10 bg-gray-50 rounded-xl ml-20">
        <div className="flex flex-col space-y-2">
          <p className="text-subtle text-body-large font-semibold">Login to</p>
          <p className="uppercase text-header-3 font-extrabold">Oro Safe config</p>
          <Login setAuthenticated={setAuthenticated} />
        </div>
      </div>
    </div>
  );
}
