import { checkPermission } from "../utils";
import { getValueFromLocalStorage } from "../utils/local-storage";


export default function AccessCheck({
  children,
  requiredPermissions,
  alternateChildren,
  looseCheck
}) {
  const userPermissions = getValueFromLocalStorage('userPermissions');
  const allowed = checkPermission(requiredPermissions, userPermissions, looseCheck);
  return allowed ? children : alternateChildren;
}
