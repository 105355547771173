import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Link from '../../components/Link';
import Action from '../../assets/Action.svg';
import Api from '../../utils/ApiUtils/Api';
import { debounce } from 'lodash';
import { getCustomerDisplayId } from '../../utils';
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';

const Customers = () => {
  const [state, setStateHelper] = useState({
    customersData: [],
    searchValue: '',
    limit: 10,
    count: 0,
    currentPage: 1,
    isLoading: true,
  });
  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));
  const getCustomers = (page = 1, search = null) => {
    const { limit, searchValue, currentPage } = state;
    setState({ isLoading: true });
    Api.get(`/user/safe-user/list?limit=${limit}&page=${page ?? currentPage}&search=${search ?? searchValue}`).then(
      (data) => {
        setState({ isLoading: false, page });
        if (data.status === 200) {
          setState({ customersData: data?.data?.users, count: data?.data?.count });
        }
      },
    );
  };

  useEffect(() => {
    getCustomers(1);
  }, []);



  const columns = [
    {
      title: 'Customer ID',
      dataIndex: 'id',
      key: 'id',
      show: true,
      render: (text) => <p className="text-body-bold p-2">{getCustomerDisplayId(text)}</p>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      show: true,
      render: (_, data) => {
        return <p className="text-body-bold capitalize">{`${data?.firstName || ''} ${data?.middleName || ''} ${data?.lastName || ''}`}</p>
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      show: true,
      render: (text) => <p className="text-body-bold">{text}</p>,
    },
    {
      title: 'No.of Lockers Booked',
      dataIndex: 'locker_count',
      key: 'locker_count',
      show: true,
      render: (text) => <p className="text-body-bold">{text}</p>,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      show: true,
      fixed: 'right',
      width: 50,
      render: (text, data) => (
        <AccessCheck
          requiredPermissions={permissions.customers.table_row.permission}
          alternateChildren={<div></div>}
          looseCheck={false}
        >
          <Link to={`customers/details/${data?.id}`}>
            <img src={Action} alt="" />
          </Link>
        </AccessCheck>
      ),
    },
  ];
  const handleTableChange = (pagination) => {
    setState({ currentPage: pagination.current })
    getCustomers(pagination.current);
  };
  const filterData = debounce((value) => {
    setState({ searchValue: value });
    getCustomers(1, value)
  }, 500)
  const { customersData, count, limit, isLoading } = state;
  return (
    <div className="space-y-10">
      <div className="flex items-center justify-between">
        <p className="text-header-3">All Customers</p>
      </div>
      <div className='w-3/4 mx-auto space-y-5'>
        <div className="relative w-1/2 ml-auto">
          <input type="search" placeholder="Search using Customer ID, Name or any parameter"
            className="w-full py-3 text-base font-bold outline-none px-2 rounded-lg border border-inactive"
            onChange={(e) => filterData(e.target.value)}
          />
          <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
        </div>
        <Table scroll={{ x: 900 }} loading={isLoading} columns={columns}
          pagination={{ position: ['bottomCenter'], total: count, pageSize: limit }}
          onChange={handleTableChange} dataSource={customersData}
        />
      </div>
    </div>
  );
};

export default Customers;
