/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../../components/Buttons/Button";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import LabelInput from "../../../../components/Form/LabelInput";
import Api from "../../../../utils/ApiUtils/Api";

export default function LockerStorageCard({
  isOtpVerified,
  toggleStoreLockerCard,
  onUpdate,
  isLockerStored
}) {
  const { id } = useParams();
  const [state, setState] = useState({
    otp: "",
    locker: "",
    loading: false,
    isError: false,
    parentLocker: "",
    doorstepLockers: []
  });

  useEffect(() => {
    // fetch all the doorstep lockers
    async function fetchAllDoorStepLockers() {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const { data } = await Api.get(`/safe/doorstep-locker/list`, {
          apiVersion: "v2"
        });
        setState((prev) => ({
          ...prev,
          doorstepLockers: data.rows,
          loading: false
        }));
      } catch (err) {
        setState((prev) => ({ ...prev, isError: true, loading: false }));
      }
    }

    // fetch the parent locker data
    async function fetchParentLockerData() {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const { data } = await Api.get(`/visit/${id}/parent-locker`);
        const { parentLocker } = data;
        

        setState((prev) => ({
          ...prev,
          parentLocker,
          loading: false
        }));
      } catch (err) {
        setState((prev) => ({ ...prev, isError: true, loading: false }));
      }
    }

    if (toggleStoreLockerCard && !isLockerStored) {
      fetchAllDoorStepLockers();
    }
    if(isLockerStored) {
      fetchParentLockerData();
    }
  }, []);

  // destructure state here
  const { otp, locker, doorstepLockers, parentLocker, loading } = state;

  // use this onChange function to change the input states
  const onChange = (type, e) => {
    switch (type) {
    case "otp":
      return setState((prev) => ({
        ...prev,
        otp: e
      }));
    case "locker": {
      // find the locker number from the selected locker
      const lockerNumber = e.target.value.split(" - ")[2];
      
      // find the locker number from all the doorstep locker
      const lockerContenet = doorstepLockers.find(
        (locker) => locker?.locker_number === lockerNumber
      );
      // update the state
      return setState((prev) => ({
        
        ...prev,
        locker: lockerContenet
        
      }));
    }
    }
  };

  // use this api to verify the given otp
  const onVerifyOtp = () => {
    
    setState((prev) => ({...prev,  loading: true }));
    Api.post(`/visit/${id}/doorstep/otp/verify`, { otp })
      .then(() => {
        onUpdate();
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          isError: true,
          loading: false
        }));
      });
  };

  // use this api to Store doorstep locker in big lockers
  const onStoreDoorstepLocker = () => {
    setState((prev) => ({...prev,  loading: true }));
    Api.post(`/visit/${id}/doorstep/store-lockers`, { doorStepLockerId: locker?.id })
      .then(() => {
        onUpdate();
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          isError: true,
          loading: false
        }));
      });
  };

  // use this div if the otp is not verified
  const OTPNOTVERIFIED =
    <>
      <div className="py-2 w-3/4">
        <p className="text-xl text-subtle">
          Please enter the OTP shared by the agent here to start Locker Storage
        </p>
      </div>
      <div className="py-4">
        <LabelInput
          label="enter otp here"
          maxLength={4}
          type="numberText"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => onChange("otp", e)}
          required
        />
      </div>
      <div className="py-2">
        <Button
          variant={!otp ? 'inactive' : 'cta'}
          type="submit"
          className={!otp ? "w-full rounded-full bg-gray-300 py-3.5 cursor-not-allowed" : "w-full"}
          onClick={() => onVerifyOtp()}
          isLoading={loading}
          disabled={loading || !otp}
        >
          Confirm OTP
        </Button>
      </div>
    </>;
  // use this div if the otp is verified and to store the locker
  const STORELOCKER =
    <>
      <div className="py-2 w-3/4">
        <p className="text-xl text-subtle">Choose where to store the locker.</p>
      </div>
      <div className="py-4">
        <LabelInput
          type="select"
          label="choose locker to store"
          placeholder="Choose"
          value={locker !== "" ? `${locker?.lockerLockerType?.type} - ${locker?.id} - ${locker?.locker_number}` : ""}
          onChange={(event) => onChange("locker", event)}
          options={doorstepLockers?.map(
            (locker) =>
              `${locker?.lockerLockerType?.type} - ${locker?.id} - ${locker?.locker_number}`
          )}
          required
        />
      </div>
      <div className="py-2">
        <Button
          variant={!locker ? 'inactive' : 'cta'}
          type="submit"
          className={!locker ? "w-full rounded-full bg-gray-300 py-3.5 cursor-not-allowed" : "w-full"}
          onClick={() => onStoreDoorstepLocker()}
          isLoading={loading}
          disabled={loading || !locker}
        >
          Store Doorstep Locker
        </Button>
      </div>
    </>;
  const LOCKERSTORED =
    <>
      <div className="py-2">
        <p className="text-xl text-subtle">
          Locker stored at the following Locker.
        </p>
      </div>
      <div className="py-2">
        <div className="bg-oro-gradient py-2 px-4 inline-block rounded-xl">
          <p className="font-bold text-base">Locker id : {parentLocker?.lockerNumber??"--"}</p>
        </div>
      </div>
    </>;
  return (
    <div className="pt-6 w-1/3">
      <PrimaryCard>
        <div className="flex items-center py-2">
          <p className="text-3xl font-bold m-0">Locker Storage</p>
          {isOtpVerified ?
            <div className="px-5">
              <div className="w-28 px-4 py-1 rounded-full bg-medium-yellow flex justify-center text-xsm font-bold">
                <p>OTP VERIFIED</p>
              </div>
            </div>
            : null}
        </div>
        <div className="py-3">
          {isLockerStored
            ? LOCKERSTORED
            : toggleStoreLockerCard
              ? STORELOCKER
              : !isOtpVerified
                ? OTPNOTVERIFIED
                : null}
        </div>
      </PrimaryCard>
    </div>
  );
}
