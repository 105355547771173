import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Stepper from '../../components/Stepper';
import AddCustomerDetails from './addCustomerDetails';
import AddLockers from './addLockers';
import FinishVisit from './finishVisit';
import SubscriptionDetails from './subscriptionDetails';
import ReactTag from '../../components/ReactTag';
import KeyValue from '../../components/Form/KeyValue';
import Button from '../../components/Buttons/Button';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Loading from '../../components/loading';
import HasuraApi from '../../utils/ApiUtils/HasuraApi';
import ExistingCustomerVisit from './existingCustomerVisit';
import { getVisitDisplayId } from '../../utils';

export default function StartVisit(props) {
  const [state, setStateHelper] = useState({
    visitData: [],
    isLoading: true,
    isError: false,
    current: 1,
  });

  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));

  const statusCurrentMap = { SAFE_CUSTOMERS_ADDED: 1,
    SAFE_CUSTOMER_OTP_VALIDATED: 4,
    SAFE_NOMINEE_ADDED: 2,
    SAFE_LOCATION_REACHED: 1,
    '-': 1,
    SAFE_LOCKER_ADDED: 3,
    SAFE_CONFIRMED_SUBSCRIPTIONS: 3,
    SAFE_PAYMENT_RECORDED: 3,
  };
  const { history, location } = props;
  const { id } = useParams();

  const getVisitData = () => {
    setState({isLoading: true})
    const getVisitById = {
      variables: {
        where: {
          id: { _eq: parseInt(id, 10) },
        },
      },
      query: `
      query getVisitById($where: visits_bool_exp = {}) {
        visits(where: $where) {
          id
          visit_display_id
          visit_status
          visit_type
          intermediate_status
          visit_time
          customer_auth_id
          space {
            id
          }
          
        }
      }
      `,
    };
    HasuraApi.post('/v1/graphql', getVisitById).then((data) => {
  
      setState({isLoading: false, visitData: data?.data?.visits[0], 
        current: data?.data?.visits[0]?.visit_status === 'VISIT_COMPLETED'? 4:
          statusCurrentMap[data?.data?.visits[0]?.intermediate_status] })
      
    }).catch(err => setState({isLoading: false, isError: true}));
  }
  useEffect(() => {
    getVisitData();
  }, []);
  const options = [
    {
      key: 1,
      title: 'Customer Details',
      component: (state.visitData?.visit_type === 'SAFE_VISIT' || state.visitData?.visit_type?.split('/')[0] === 'SAFE_VISIT')? 
        ExistingCustomerVisit : AddCustomerDetails,
    },
    {
      key: 2,
      title: 'Add Lockers',
      component: AddLockers,
    },
    {
      key: 3,
      title: 'Confirm Subscription',
      component: SubscriptionDetails,
    },
    // {
    //   key: 4,
    //   title: 'Generate Digital Certificate',
    //   component: GenerateDigitalCertificate,
    // },
    {
      key: 4,
      title: 'Finish Visit',
      component: FinishVisit,
    },
  ];

  const {isLoading, isError, visitData, current  } = state;
  if (isLoading) return <Loading />;
  if(isError) return (
    <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
      <p>Something Went Wrong</p>
      <p>Please Try Again</p>
      <Button variant="primary" onClick={getVisitData}>Retry</Button>
    </div>
  )
  return (
    <div className="space-y-5">
      <div className="flex gap-5 flex-wrap items-center">
        <p className="text-header-3">
          Visit ID : {getVisitDisplayId(visitData?.id)}
        </p>
        <ReactTag text={visitData?.visit_status} />
        <PrimaryCard>
          <KeyValue label="Booked on" value={`${moment(visitData?.visit_time).format('DD-MM-YYYY | HH:mm')}hrs`} />
        </PrimaryCard>
        {visitData?.visit_type?.split('/')[0] !== 'SAFE_VISIT'  ? (
          <Button className="bg-medium-yellow uppercase text-xs font-bold px-3 py-2 rounded-lg" 
            type="button">New Customer</Button>
        ) : (
          <Button className="uppercase bg-black text-white text-xs font-bold px-3 py-2 rounded-lg" type="button">
            Existing Customer
          </Button>
        )}
      </div>
      <div className="w-full ">
        <Stepper state={{ intermediate_status: visitData?.intermediate_status,
          visit_status: visitData?.visit_status,
          space_id: visitData?.space?.id,
          user_id: location?.state?.user_id }} current={current} history={history} 
        refetch={getVisitData} setCurrent={(value) => setState({current: value})} options={options}
        />
      </div>
    </div>
  );
}
