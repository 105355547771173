import React from "react";
import Button from "./components/Buttons/Button";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return(
        <div className="flex flex-col items-center justify-center w-full h-[80vh] space-y-2">
          <p className="text-xl font-bold">Something went wrong.</p>
          <p className="text-base font-semibold"> Please Refresh The Page</p>
          <Button variant="primary" onClick={()=> window.location.reload()}>Refresh</Button>
        </div>
      );
    }

    return this.props.children; 
  }
}
export default ErrorBoundary;