import React, { useEffect, useRef, useState } from 'react';
// Assets
import ArrowSvg from '../../assets/ArrowSvg';
import TickSvg from '../../assets/tick.svg';
// Interface
export default function SingleSelect({
	width,
	placeholder,
	dropdownOptions,
	confirmChanges = false,
	onSelectOption,
	defaultFirstSelected,
	defaultSelectedOption,
	isResetOption = false,
}) {
	const [showOptions, setShowOptions] = useState(false);
	const [selectedDropdownOption, setselectedDropdownOption] = useState();
	const dropdownRef = useRef(null);
	const dropdownWidth = {
		width: `${width}px`,
	};

	const resetOption = isResetOption
		? dropdownOptions
		: defaultFirstSelected && dropdownOptions?.length;

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowOptions(false);
			}
		};
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		if (defaultFirstSelected && dropdownOptions?.length) {
			setselectedDropdownOption(dropdownOptions[0]);
			onSelectOption(dropdownOptions[0]);
		}
	}, [resetOption]);

	useEffect(() => {
		if (Object.values(defaultSelectedOption || {})?.length) {
			setselectedDropdownOption(defaultSelectedOption);
		}
	}, []);

	return (
		<div ref={dropdownRef} style={dropdownWidth} className='relative'>
			<div
				role='presentation'
				className={`h-[36px] bg-white border rounded-[4px] p-4 w-full flex items-center justify-between ${
					Object.values(selectedDropdownOption || {}).length
						? 'border-primary-yellow'
						: 'border-[#E8E8E8]'
				}`}
				onClick={() => {
					setShowOptions(!showOptions);
				}}
			>
				{Object.values(selectedDropdownOption || {}).length ? (
					<p className='text-sm font-normal text-[#909090] mr-[8px] cursor-pointer'>
						{selectedDropdownOption?.label}
					</p>
				) : (
					<p className='text-sm font-normal text-[#909090] mr-[8px] cursor-pointer'>
						{placeholder}
					</p>
				)}
				<ArrowSvg
					className={`h-3 w-3 ${showOptions && 'rotate-180'}`}
					arrowFill='gray'
				/>
			</div>
			{showOptions ? (
				<div
					className='w-full absolute border rounded-[4px] shadow-button-shadow block bg-white z-20 min-h-[30px] max-h-[300px] overflow-y-auto scrollbar overflow-x-hidden mt-1'
					style={dropdownWidth}
				>
					{dropdownOptions?.map((option) => {
						return (
							<div
								className='flex items-center py-3 pl-4 cursor-pointer border-b'
								role='presentation'
								onClick={(event) => {
									event.stopPropagation();
									if (confirmChanges) return;
									setselectedDropdownOption(option);
									onSelectOption(option);
									setShowOptions(false);
								}}
							>
								{option?.label}
							</div>
						);
					})}
				</div>
			) : null}
		</div>
	);
}
