// ** builtins
import React, { useState } from 'react';

// ** assets
import reupload from '../../assets/reupload.png';
import cancelUpload from '../../assets/cancelUpload.png';
import uploadImage from '../../assets/upload.png';

// ** services
import Api from '../../utils/ApiUtils/Api';

// ** components
import ImageViewer from '../ImageViewer/ImageViewer';

const Upload = ({ image, setImage, className }) => {
	const [toggleViewImageModal, setToggleViewImageModal] = useState(false);

	return (
		<div>
			<input
				onChange={async (event) => {
					const data = event?.target?.files[0];
					const url = await Api.uploadImage(data);
					const signedUrl = await Api.getImages(url);
					setImage({
						imageName: data?.name,
						imageUrl: signedUrl,
						unSignedImage: url,
					});
				}}
				type='file'
				id='file'
				style={{ display: 'none' }}
				accept=".jpg, .jpeg, .png"
			/>
			{image?.imageName ? (
				<div className='flex gap-[16px] items-center cursor-pointer'>
					<div
						onClick={() => setToggleViewImageModal(true)}
						className='h-[48px] w-[120px] border-[1px] border-[#FFC351] rounded-[10px] flex justify-center items-center'
					>
						View proof
					</div>
					<label
						htmlFor='file'
						style={{ cursor: 'pointer' }}
						className='!w-[32px] !h-[32px]'
					>
						<img src={reupload} />
					</label>
					<div
						className='!w-[32px] !h-[32px]'
						onClick={() =>
							setImage({
								imageName: '',
								imageUrl: '',
								unSignedImage: ''
							})
						}
					>
						<img src={cancelUpload} />
					</div>
				</div>
			) : (
				<label
					className={`h-[48px] w-[182px] border-[1px] border-[#FFC351] rounded-[10px] flex justify-center items-center cursor-pointer ${className}`}
					htmlFor='file'
				>
					<div className='flex gap-[10px]'>
						<img src={uploadImage} alt='upload' className='w-[18px] h-[18px]' />
						<p className='text-[#454545] text-[16px] font-bold'>Upload proof</p>
					</div>
				</label>
			)}

			{toggleViewImageModal && (
				<ImageViewer
					images={[
						{
							source: image?.imageUrl,
							caption: image?.imageName,
						},
					]}
					onClose={() => {
						setToggleViewImageModal(false);
					}}
				></ImageViewer>
			)}
		</div>
	);
};

export default Upload;
