// ** builtins
import React from 'react';
import { DatePicker as AntDDatePicker } from 'antd';

// ** assets
import arrow from '../../assets/belowArrow.png';

const DatePicker = ({
	date,
	onChange,
	disabled = false,
	disablePlaceholderText = '',
	disabledClassName = '',
	className = '',
}) => {
	return (
		<AntDDatePicker
			disabled={disabled}
			className={`w-[156px] h-[50px] border-[2px] rounded-[10px] 
                text-[16px]
                border-[#E8E8E8] pl-[16px] hover:border-[#E8E8E8]
                ${disabledClassName}
				${className}
                `}
			suffixIcon={
				disabled ? null : (
					<img src={arrow} className='w-[16px] h-[8px] transform' />
				)
			}
			dropdownClassName='hover:outline-none'
			format='DD-MM-YYYY'
			value={date}
			placeholder={disabled ? disablePlaceholderText : 'Choose'}
			onChange={(value) => onChange(value)}
			clearIcon={null}
		/>
	);
};

export default DatePicker;
