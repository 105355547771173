// DEPS
import React from 'react';
import PropTypes from 'prop-types';

const rules = {
  OROCORP_REJECTED: {
    backgroundColor: 'rgba(255, 242, 242, 1)',
    border: '1px solid rgba(163, 30, 0, 1)',
    color: 'rgba(163, 30, 0, 1)',
  },
  OROCORP_APPROVED: {
    backgroundColor: 'rgba(253, 255, 246, 1)',
    border: '1px solid rgba(0, 199, 8, 1)',
    color: 'rgba(0, 199, 8, 1)',
  },
  PENDING_OROCORP_APPROVAL: {
    backgroundColor: 'rgba(255, 249, 239, 1)',
    border: '1px solid rgba(255, 167, 0, 1)',
    color: 'rgba(255, 167, 0, 1)',
  },
  PENDING_FUND_TRANSFER: {
    backgroundColor: 'rgba(255, 242, 242, 1)',
    color: 'rgba(255, 101, 66, 1)',
    border: '1px solid rgba(255, 120, 89, 1)',
  },
  LOAN_AMOUNT_TRANSFERRED: {
    backgroundColr: 'rgba(248, 255, 254, 1)',
    border: '1px solid rgba(0, 165, 201, 1)',
    color: 'rgba(0, 165, 201, 1)',
  },
  BLC_VERIFIED: {
    background: 'rgba(248, 255, 254, 1)',
    border: '1px solid rgba(22, 160, 133, 1)',
    color: 'rgba(22, 160, 133, 1)',
  },
  GOLD_STORED: {
    backgroundColor: 'rgba(255, 249, 240, 1)',
    border: '1px solid rgba(234, 140, 0, 1)',
    color: 'rgba(234, 140, 0, 1)',
  },
  LOAN_ACCOUNT_CLOSURE_INITIATED: {
    backgroundColor: 'rgba(250, 252, 255, 1)',
    border: '1px solid rgba(65, 141, 255, 1)',
    color: 'rgba(65, 141, 255, 1)',
  },
  LOAN_CLOSED: {
    background: 'rgba(250, 252, 255, 1)',
    border: '1px solid rgba(255, 65, 168, 1)',
    color: 'rgba(255, 65, 168, 1)',
  },
  VISIT_REQUESTED: {
    background: '#FEFBFF',
    border: '1px solid #9B51E0',
    color: 'rgba(155, 81, 224, 1)',
  },
  VISIT_CONFIRMED: {
    background: 'rgba(245, 255, 253, 1)',
    border: '1px solid rgba(22, 160, 133, 1)',
    color: 'rgba(22, 160, 133, 1)',
  },
  VISIT_ASSIGNED: {
    background: 'rgba(250, 254, 255, 1)',
    border: '1px solid rgba(86, 204, 242, 1)',
    color: 'rgba(86, 204, 242, 1)',
  },
  VISIT_IN_PROGRESS: {
    background: 'rgba(255, 248, 234, 1)',
    border: '1px solid rgba(255, 170, 7, 1)',
    color: 'rgba(255, 170, 7, 1)',
  },
  VISIT_COMPLETED: {
    background: 'rgba(243, 255, 253, 1)',
    border: '1px solid rgba(90, 149, 62, 1)',
    color: 'rgba(90, 149, 62, 1)',
  },
  VISIT_CANCELLED: {
    background: 'rgba(255, 242, 242, 1)',
    border: '1px solid rgba(255, 120, 89, 1)',
    color: 'rgba(255, 101, 66, 1)',
  },
  RELEASE_REQUESTED: {
    border: '1px solid rgba(130, 130, 130, 1)',
    background: 'rgba(255, 255, 255, 1)',
    color: 'rgba(130, 130, 130, 1)',
  },
  RENEWAL_REQUESTED: {
    background: 'linear-gradient(180deg, #F9CA36 0%, #F8B830 100%)',
    border: '1px solid rgba(0, 0, 0, 1)',
    color: 'black',
  },
  PENDING_KYC_APPROVAL: {
    background: 'rgba(235, 245, 255, 1)',
    border: '1px solid rgba(7, 136, 255, 1)',
    color: 'rgba(7, 136, 255, 1)',
  },
  LOAN_RENEWED: {
    background: 'rgba(69, 69, 69, 1)',
    border: '1px solid',
    borderImageSource: 'linear-gradient(180deg, #F9CA36 0%, #F8B830 100%)',
    color: 'linear-gradient(180deg, #F9CA36 0%, #F8B830 100%)',
  },
  ACTIVE: {
    background: 'rgba(245, 255, 253, 1)',
    border: '1px solid rgba(22, 160, 133, 1)',
    color: 'rgba(22, 160, 133, 1)',
  },
  INACTIVE: {
    background: 'rgba(255, 242, 242, 1)',
    border: '1px solid rgba(255, 120, 89, 1)',
    color: 'rgba(255, 101, 66, 1)',
  },
  RESOLVED: {
    background: 'rgba(245, 255, 253, 1)',
    border: '1px solid rgba(22, 160, 133, 1)',
    color: 'rgba(22, 160, 133, 1)',
  },
  UNRESOLVED: {
    background: 'rgba(255, 242, 242, 1)',
    border: '1px solid rgba(255, 120, 89, 1)',
    color: 'rgba(255, 101, 66, 1)',
  },
};

export default function ReactTag(props) {
  const { text, style } = props;
  if (text) {
    if (rules[text]) {
      return (
        <span
          style={{ ...rules[text], ...style }}
          className="tag-font inline-block text-center p-3"
        >
          {text.split('_').join(' ')}
        </span>
      );
    }
    return (
      <span
        style={{ border: '1px solid black', ...style }}
        className="tag-font inline-block text-center p-3"
      >
        {text.toUpperCase().split('_').join(' ')}
      </span>
    );
  } return <></>;
}
ReactTag.propTypes = {
  text: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
};
ReactTag.defaultProps = {
  text: '',
  style: {},
};
