/* eslint-disable react/jsx-handler-names */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PrimaryCard from '../../../../../components/Cards/PrimaryCard';
import Form from '../../../../../components/Form/Form';
import LabelInput from '../../../../../components/Form/LabelInput';
import ImageViewer from '../../../../../components/ImageViewer/ImageViewer';
import Api from '../../../../../utils/ApiUtils/Api';

export default function PersonalDetailsCard({ state, onChange }) {
  const [toggleViewImageModal, onSetToggleViewImageModal] = useState(false);
  const [images, setImages] = useState([]);

  // const OCCUPATIONS = [
  //   { key: 'self-employed', value: 'Self Employed' },
  //   { key: 'employed', value: 'Employed' },
  //   { key: 'house-wife', value: 'House wife' }
  // ];

  const {
    firstName,
    lastName,
    middleName,
    dateOfBirth,
    gender,
    fatherName,
    // monthlyIncome,
    maskedAadhaarNumber,
    aadhaarCardPhoto,
    // occupation
  } = state;

  useEffect(() => {
    if (aadhaarCardPhoto?.length > 0) {
      const getImages = () => {
        aadhaarCardPhoto?.map(async (photo) => {
          if (photo?.imageUrl) {
            try {
              const response = await Api.post(`/images`, { urls: [photo?.imageUrl] });
              setImages((prev) => [...prev, {
                caption: photo?.type,
                source: response.signedUrls[0]
              }]);
            } catch (error) {
              console.log(error);
            }
          }
        });
      };

      getImages();
    }
  }, [aadhaarCardPhoto]);

  return (
    <>
      <PrimaryCard className="p-10 w-full md:w-3/4 xl:w-3/5">
        <Form>
          {/* // CUSTOMER FIRST NAME AND LAST NAME */}
          <div className="flex gap-6 flex-col md:flex-row pt-6">
            <LabelInput
              label="first name"
              type="text"
              placeholder="Enter Customer First Name"
              rootClassName="w-1/2"
              value={firstName}
              onChange={(e) => onChange('firstName', e)}
              required
            />
            <LabelInput
              label="Middle name"
              type="text"
              placeholder="Enter Customer Middle Name"
              rootClassName="w-1/2"
              value={middleName}
              onChange={(e) => onChange('middleName', e)}
              required
            />
            <LabelInput
              label="last name"
              type="text"
              placeholder="Enter Customer Last Name"
              rootClassName="w-1/2"
              value={lastName}
              onChange={(e) => onChange('lastName', e)}
              required
            />
          </div>
          {/* // CUSTOMER DATE OF BIRTH AND GENDER */}
          <div className="flex gap-6 flex-col md:flex-row pt-6">
            <div className="space-y-4 w-1/3">
              <p className="label">DATE OF BIRTH</p>
              <DatePicker
                format="DD/MM/YYYY"
                className="rounded-lg p-3 border w-full"
                value={moment(dateOfBirth)}
                onChange={(e) => onChange('dateOfBirth', e)}
              />
            </div>
            <LabelInput
              type="select"
              label="Gender"
              placeholder="Select Gender"
              rootClassName="w-1/3"
              value={gender}
              options={['Male', 'Female', 'Prefer not to say']}
              required
              onChange={(e) => onChange('gender', e)}
            />
          </div>
          {/* // CUSTOMERS FATHERS NAME */}
          <div className="flex gap-5 flex-wrap pt-6">
            <LabelInput
              label="father’s name"
              type="text"
              placeholder="Enter Father’s Name"
              value={fatherName}
              onChange={(e) => onChange('fatherName', e)}
              required
            />
          </div>
          {/* // CUSTOMER AADHAAR CARD INFO */}
          <div className="space-y-4">
            <p className="label pt-6">AADHAAR NUMBER</p>
            <div className="flex gap-6 flex-col items-center md:flex-row ">
              <p className="text-base text-black">xxxx-xxxx-xxxx-</p>
              <div className="w-1/12">
                <LabelInput
                  maxLength={4}
                  type="numberText"
                  className="w-full"
                  placeholder="Enter Aadhaar Number"
                  value={maskedAadhaarNumber?.substr(
                    maskedAadhaarNumber?.length - 4
                  )}
                  onChange={(e) => onChange('maskedAadhaarNumber', e)}
                  required
                />
              </div>
              <div
                className="w-28 rounded-full bg-medium-yellow h-10 flex items-center justify-center"
                onClick={() => onSetToggleViewImageModal(true)}
              >
                <p className="text-sm font-bold">View Photo</p>
              </div>
            </div>
          </div>
          {/* // CUSTOMER DATE OF BIRTH AND GENDER */}
          {/* <div className="flex gap-6 flex-col md:flex-row pt-6">
            <LabelInput
              type="select"
              label="occupation"
              placeholder="Select Occupation"
              rootClassName="w-1/3"
              value={
                occupation
                  ? OCCUPATIONS.find((item) => item.key === occupation).value
                  : ''
              }
              options={OCCUPATIONS.map((item) => item.value)}
              required
              onChange={(e) => {
                const selectedOccupation = OCCUPATIONS.find(
                  (item) =>
                    item.value.toLowerCase() === e.target.value.toLowerCase()
                );
                console.log(selectedOccupation);
                onChange('occupation', selectedOccupation.key);
              }}
            />
            <LabelInput
              label="monthly income in rupees"
              type="text"
              placeholder="Enter monthly income in rupees"
              value={monthlyIncome}
              onChange={(e) => onChange('monthlyIncome', e)}
              required
            />
          </div> */}
        </Form>
      </PrimaryCard>
      {toggleViewImageModal &&
        <ImageViewer
          images={images}
          onClose={() => {
				    onSetToggleViewImageModal(false);
          }}
        ></ImageViewer>}
    </>
  );
}
