import React from "react";
import twMerge from "../CustomMerge";
import Link from "../Link";


export default function KeyValue({ label, value, labelStyle, link, class: valueClass, absolute = false }) {
  if (!value) {
    return (
      <div>
        <p className={twMerge(`text-xsm text-subtle font-extrabold uppercase mb-1`, labelStyle)}>{label}</p>
        <p className="text-base font-normal">NIL</p>
      </div>
    );
  }
  return (
    <div>
      <p className={twMerge(`text-xsm text-subtle font-extrabold uppercase mb-1`, labelStyle)}>{label}</p>
      {link && value && absolute ? (
        <a href={link} target="_blank" rel="noreferrer">
          <p className={twMerge(`text-base font-normal`, valueClass)}>
            {value || 'NIL'}
          </p>
        </a>
      ) : link && value ? (
        <Link to={link}>
          <p className={twMerge(`text-base font-normal`, valueClass)}>
            {value || 'NIL'}
          </p>
        </Link>
      ) : (
        <p className={twMerge(`text-base font-normal`, valueClass)}>
          {value || 'NIL'}
        </p>
      )}
    </div>
  );
}
