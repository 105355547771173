export const DOORSTEP_CUSTOMER_TYPES = {
  SAFE_DOORSTEP_NEW_CUSTOMER: {
    label: "New Customer",
    value: "SAFE_DOORSTEP_NEW_CUSTOMER"
  },
  SAFE_DOORSTEP_NEW_SUBSCRIPTION: {
    label: "New Subscription",
    value: "SAFE_DOORSTEP_NEW_SUBSCRIPTION"
  },
  SAFE_DOORSTEP_MANAGE_SUBSCRIPTION: {
    label: "Manage Subscription",
    value: "SAFE_DOORSTEP_MANAGE_SUBSCRIPTION"
  }
};
