import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.less';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./ErrorBoundary";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';

const currEnv = process.env.REACT_APP_ENV?.toLowerCase();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  tracesSampler: () => {
    if (currEnv === 'prod' || currEnv === 'production') {
      return 0.5;
    }

    if (currEnv === 'uat') {
      return 0.1;
    }

    return 0;
  },
  debug: currEnv === 'uat',
  environment: currEnv,
  autoSessionTracking: true
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
