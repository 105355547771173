import React, { useRef } from "react";

export default function Form({ children, onSubmit, customValidation = () => true, className, ...props }) {
  const form = useRef();

  function validate() {
    const elements = form.current;
    if (form.current.checkValidity() === false) {
      for (var el of elements) {
        el.classList.add('submitted');
        const errorLabel = el.parentNode.querySelector(".warning");
        if (errorLabel && el.nodeName.toLowerCase() !== "button") {
          if (!el.validity.valid) {
            errorLabel.textContent = el.validationMessage;
          } else {
            errorLabel.textContent = "";
          }
        }
      }
      return false;
    }
    for (var el of elements) {
      const errorLabel = el.parentNode.querySelector(".warning");
      if (errorLabel && el.nodeName.toLowerCase() !== "button") {
        errorLabel.textContent = "";
      }
    }
    return true;
  }

  function submitHandler(e) {
    e.preventDefault();
    const isValid = customValidation();
    console.log(validate(), isValid);
    if (validate() && isValid && onSubmit) onSubmit();
  }

  return <form
    ref={form}
    onSubmit={submitHandler}
    {...props}
    className={className}
    noValidate
  >
    {children}
         </form>;
};
