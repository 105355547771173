import moment from "moment";
import React from "react";
import PrimaryCard from "../../../../components/Cards/PrimaryCard";
import KeyValue from "../../../../components/Form/KeyValue";
import AccessCheck from "../../../../routes/accessCheck";
import permissions from "../../../../utils/permissionConfig";

export default function VisitConfirmationDetailsCard({ visitData }) {
  const visitConfirmedAtDate = moment(visitData?.confirmed_at).format("DD-MM-YYYY");
  const visitConfirmedAtTime = moment(visitData?.confirmed_at).format("HH:mm");
  return (
    <PrimaryCard className="space-y-5">
      <div className="flex justify-between items-center">
        <p className="text-body-bold m-0 mr-14">Visit Confirmation</p>
      </div>
      <KeyValue
        label="visit confirmed by"
        value={visitData?.assigned_by?.first_name}
      />
      <AccessCheck
        requiredPermissions={permissions.visits.table_row.space_details.permission}
        alternateChildren={
          <KeyValue
            label="visit confirmed at"
            value={`${visitConfirmedAtDate} | ${visitConfirmedAtTime} hrs`}
            class="m-0"
          />
        }
        looseCheck={false}>
        <KeyValue
          label="visit confirmed at"
          value={`${visitConfirmedAtDate} | ${visitConfirmedAtTime} hrs`}
          link={`/spaces/details/${visitData?.space?.id}`}
          class="m-0"
        />
      </AccessCheck>
    </PrimaryCard>
  );
}
