import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

export default function BreadCrumbs({ className }) {
  const location = useLocation();
  const routes = [
    {
      path: '/spaces',
      breadcrumbName: 'All Spaces'
    },
    {
      path: '/spaces/create',
      breadcrumbName: 'Create Space'
    },
    {
      path: '/spaces/details/:id',
      breadcrumbName: 'Space Details'
    },
    {
      path: '/spaces/:id/locker-types/:id/details',
      breadcrumbName: 'Locker Details'
    },
    {
      path: '/spaces/:id/lockers/create',
      breadcrumbName: 'Add Lockers to Space'
    },
    {
      path: '/spaces/:id/locker-types/:id/pricing',
      breadcrumbName: 'Pricing Details'
    },
    {
      path: '/visits',
      breadcrumbName: 'All Visits'
    },
    {
      path: '/visits/create-locker-visit',
      breadcrumbName: 'Create Locker Visit'
    },
    {
      path: '/visits/create-doorstep-visit',
      breadcrumbName: 'Create Doorstep Visit'
    },
    {
      path: '/visits/locker-visit-details/:id',
      breadcrumbName: 'Visit Details'
    },
    {
      path: '/visits/doorstep-visit-details/:id',
      breadcrumbName: 'Visit Details'
    },
    {
      path: '/visits/start/:id',
      breadcrumbName: 'Start Visit'
    },
    {
      path: '/locker-managers',
      breadcrumbName: 'All Locker Managers'
    },
    {
      path: '/locker-types',
      breadcrumbName: 'All Locker Types'
    },
    // {
    //   path: '/locker-managers/details',
    //   breadcrumbName: 'Locker Manager Details',
    // },
    // {
    //   path: '/locker-managers/create',
    //   breadcrumbName: 'Create Locker Manager',
    // },
    {
      path: '/digital-certificates',
      breadcrumbName: 'All Digital Certificates'
    },
    {
      path: '/digital-certificates/details',
      breadcrumbName: 'Digital Certificate Details'
    },
    {
      path: '/customers',
      breadcrumbName: 'All Customers'
    },
    {
      path: '/customers/details/:id',
      breadcrumbName: 'Customer Details'
    }

  ];

  const getBreadCrumb = (route) => routes.filter((el) => matchPath(route,
    { path: el.path, exact: true })?.isExact)[0]?.breadcrumbName;
  return (
    <div className={`flex gap-3 items-center ${className}`}>
      {(location.state?.history ?? [])?.map((route, index) =>
        <>
          <Link className="text-body-normal text-new-green"
            to={{ pathname: route, state: { history: location.state?.history?.slice(0, index) } }}
          >
            {getBreadCrumb(route)}
          </Link>
          <RightOutlined className="text-subtle text-sm" />
        </>
      )}
      <p className="text-body-normal text-subtle">{getBreadCrumb(location.pathname)}</p>
    </div>
  );
}
