import React, { useEffect, useRef, useState } from 'react';
import { Table, DatePicker } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Link from '../../components/Link';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Button from '../../components/Buttons/Button';
import Api from '../../utils/ApiUtils/Api';
import Input from '../../components/Form/Input';
import Loading from '../../components/loading';
import { debounce } from 'lodash';
import permissions from '../../utils/permissionConfig';

const { RangePicker } = DatePicker;
const LockertypeDetails = () => {
  const [state, setStateHelper] = useState({
    lockersData: [],
    searchValue: '',
    fromDate: null,
    currentPage: 1,
    toDate: null,
    limit: 10,
    count: 0,
    totalLockers: 0,
    isLoading: true,
    edit: false,
    isSaving: false,
    isError: false,
  });
  const inputRef=  useRef(null);
  const { lockerTypeId, spaceId } = useParams();
  const setState = (changes) => setStateHelper((prevState) => ({ ...prevState, ...changes }));

  const [availability, setAvailability] = useState(false);

  const getLockers = (page = 1, search, from, to) => {
    const { limit, currentPage, searchValue, fromDate, toDate } = state;
    const searchFromDate = from ?? fromDate ?? null;
    const searchToDate = to ?? toDate ?? null;
    const searchText  =search ?? searchValue ?? '';

    setState({ isLoading: true, isError: false});
    Api.get(`/locker-type/${lockerTypeId}/space/${spaceId}/booked-lockers?page=${page}
    &limit=${limit}${searchText ? `&search=${(searchText)}`: ''} ${searchFromDate ? `&from=${moment(searchFromDate)}`: ''}
    ${searchToDate ? `&to=${moment(searchToDate)}`: ''}`).then(
      (data) => {
        setState({ isLoading: false });
        setState({ lockerTypeDetails: data?.data?.lockerType,
          lockerActivity: data?.data?.lockerType?.lockerTypeLockerActivity,
          lockersData: data?.data?.lockers?.rows,
          count: data?.data?.lockers?.count, 
          totalLockers: data?.data?.lockerType?.lockerTypeLockerActivity?.locker_count?.toString() ?? 0 });
      },
    ).catch(err => setState({isLoading: false, isError: true}));
  };
  const updateLockerCount = () => {
    setState({isSaving: true, isError: false})
    Api.put(`/locker/${lockerActivity?.id}/locker-activity`, {
      number_of_lockers: parseInt(state?.totalLockers, 10)
    }).then(data => {
      inputRef.current.disabled = true;
      inputRef.current.blur();
      setState({edit: false, isSaving: false})
      getLockers();
      
    }).catch(err => setState({isSaving: false, isError: true}))
  }

  useEffect(() => {
    getLockers();
  }, []);

  const bookedLockerColumns = [
    {
      title: 'OroSafe ID',
      dataIndex: 'locker_number',
      key: 'locker_number',
      show: true,
      render: (text) => <p className="text-body-bold p-2">{text}</p>,
    },
    {
      title: 'Godrej ID',
      dataIndex: 'physical_id',
      key: 'locker_size_identifier',
      render: (text) => <p className="text-body-bold capitalize">{text}</p>,
    },
    {
      title: 'Subscription Date',
      dataIndex: 'subscription_date',
      key: 'subscription_date',
      render: (text, data) => (
        <p className="text-body-bold capitalize">
          {moment(data?.lockerSubscriptions[0]?.subscription_date).format('DD/MM/YYYY')}
        </p>
      ) },

    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text, data) => (
        <p className="text-body-bold capitalize">
          {moment(data?.lockerSubscriptions[0]?.expiry_date).format('DD/MM/YYYY')}
        </p>
      ),
    },

    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (text, data) => (
        <Link className="text-body-bold capitalize underline hover:underline" 
          to={`/customers/details/${data?.lockerSubscriptions[0]?.lockerUser?.id}`}>
          {data?.lockerSubscriptions[0]?.lockerUser?.first_name ?? `${'' + ' '}${
            data?.lockerSubscriptions[0]?.lockerUser?.last_name}`}
        </Link>
      ),
    },

    // {
    //   title: 'Digital Certificate',
    //   dataIndex: 'digital_certificate',
    //   key: 'digital_certificate',
    //   render: () => (
    //     <Link variant="primary" to="/digital-certificates/details" className="text-small-bold">
    //       View Digital Certificates
    //     </Link>),

    // },

    // {
    //   title: 'Actions',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: () => <Button variant="secondary" className="text-small-bold bg-white">Close Locker</Button>,
    // },
  ];

  const handleTableChange = (pagination) => {
    setState({currentPage: pagination.current})
    getLockers(pagination.current);
  };

  const setEdit = () => {
    inputRef.current.disabled = false;
    inputRef.current.focus();
    setState({edit: true})
  }

  const setLockerCount = (value) => {
    if(value ==='' || !value) setState({totalLockers: ''});
    else if(/^[0-9]{1,10}$/.test(value)){
      setState({
        totalLockers: value
      });
    }
    else setState({totalLockers: state?.totalLockers});
  }
  const { isLoading, lockersData, count, limit, lockerTypeDetails, lockerActivity, totalLockers, edit, isSaving} = state;
  return (
    <div className="space-y-10">
      <p className="text-header-3">Locker Type {lockerTypeDetails?.type} - {lockerTypeDetails?.width} X 
        {lockerTypeDetails?.height} X {lockerTypeDetails?.length}</p>
      <div className="flex space-x-10 items-start">
        <PrimaryCard className="space-y-2">
          <p className="small-label">No.of Type {lockerTypeDetails?.type}  Lockers in Space</p>
          <div className="flex justify-between w-52 items-center">
            <input ref={inputRef} type="text" className="w-full text-header-3 border-none outline-none focus:border-none
             focus:outline-none hover:border-none 
            hover:outline-none bg-transparent" value={totalLockers} onChange={(e) => setLockerCount(e.target.value)} disabled />
            <Button className="bg-medium-yellow py-1 uppercase rounded-full text-body-bold px-5" 
              onClick={edit? updateLockerCount : setEdit } isLoading={isSaving}>{edit ? 'Save' : 'Edit'}</Button>
          </div>
        </PrimaryCard>
        <PrimaryCard className="space-y-2">
          <p className="small-label">Pricing</p>
          <Link variant="secondary" to={`/spaces/${spaceId}/locker-types/${lockerTypeId}/pricing`} className="py-3">
            Click here to View Pricing
          </Link>
        </PrimaryCard>
      </div>
      <div className={`space-y-5 p-5 mx-auto ${availability ? 'w-3/4' : 'w-auto'}`}>
        <div className="flex space-x-5 items-center">
          <Button variant={availability ? 'inactive' : 'active'} className="py-3"
            onClick={() => {
              setAvailability(false);
            }}
          >
            Booked Lockers
          </Button>
          <Button variant={availability ? 'active' : 'inactive'} className="py-3"
            onClick={() => {
              setAvailability(true);
            }}
          >
            Available Lockers
          </Button>
        </div>
        {availability ? 
          <p className="text-[65px] font-extrabold mx-auto w-1/2">{lockerActivity?.active_locker_count - 
            lockerActivity?.booked_locker_count} <span className="text-2xl font-bold">lockers Available</span></p>:
          (
            <>
              <div className="flex justify-between">
                <div className="flex space-x-5 items-center">
                  <p className="text-body-normal text-subtle">Subscribed Time Period</p>
                  <RangePicker separator="to" bordered={false} suffixIcon={<DownOutlined />}
                    format="DD/MMM/YY" onChange={(date, dateString) => {
                      setState({fromDate: dateString[0], toDate: dateString[1]})
                      getLockers(undefined, undefined, dateString[0], dateString[1]);
                    }} 
                  />
                </div>
                <div className="relative">
                  <input
                    type="search"
                    placeholder="Search using any parameter"
                    className="input-normal outline-none font-bold w-96 p-2 italic text-body-bold"
                    onChange={debounce((e) => {
                      setState({searchValue: e.target.value})
                      getLockers(undefined, e.target.value)
                    }, 500)}
                  />
                  <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
                </div>
              </div>
              <Table loading={isLoading} scroll={{ x: 1200 }} columns={bookedLockerColumns} onChange={handleTableChange}
                pagination={{ position: ['bottomCenter'], total: count, pageSize: limit }}
                dataSource={lockersData}
              />
            </>
          )
        }
      </div>
    </div>
  );
};
export default LockertypeDetails;
