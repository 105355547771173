import AuthUtils from '../AuthUtils';
import ApiUtils from './index';

const baseConfig = () => ({
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${AuthUtils.getToken()}`,
  },
  baseURL: process.env.REACT_APP_API,
});

const HasuraApi = {
  get(path, config = {}) {
    return ApiUtils.get(path, {
      ...baseConfig(),
      ...config,
    });
  },
  post(path, payload, config = {}) {
    return ApiUtils.post(path, payload, {
      ...baseConfig(),
      ...config,
    });
  },
  getImages(urls) {
    const imgQuery = {
      variables: {
        imageUrl: urls.map(
          (url) => url.split(process.env.REACT_APP_S3_BASE_URL)[1]
        ),
      },
      query: `
      mutation getValidUrlsArray($imageUrl: [String!]) {
        getValidUrlsArray(image_urls: $imageUrl) {
          message
          urls
        }
      }`,
    };
    return this.post('/v1/graphql', imgQuery).then((res) => res?.data?.getValidUrlsArray?.urls);
  },
};

export default HasuraApi;
