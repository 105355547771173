import React, { useEffect, useState } from 'react';
import PrimaryCard from "../Cards/PrimaryCard";

import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Notification from './index';

function NotificationBar() {
  const [notifications, setNotifications] = useState([]);
  const notify = (data) => {
    setNotifications(data)
  }
  useEffect(()=>{
    Notification.subscribe(notify);
    return () => {
      Notification.unsubscribe(notify);
    };
  }, [])
  function onClose(e, key) {
    let temp  =e.target;
    while(temp.nodeName !== 'DIV'){temp = temp.parentNode; temp.innerHTML = ''}
    temp.classList.remove('animate-notification-slide');
    temp.classList.add('animate-notification-close');
    setTimeout(() => Notification.deleteNotification(key), 200);
  }

  return <div className="fixed right-0 flex flex-col gap-y-2 pr-3 z-10 mt-20">
    {notifications?.map(({ type, message, id }) =>
      <PrimaryCard className="animate-notification-slide bg-white flex items-center p-3" key={id}>
        <InfoCircleOutlined className={`${type === 'error' ? "text-error-red" : "text-new-green"} text-base sm:text-2xl`} />
        <p className="w-full  px-3 text-xs sm:text-base font-bold">
          {message}
        </p>
        <CloseOutlined className="text-base" onClick={(e) => onClose(e, id)} />
      </PrimaryCard>)}
  </div>;
};


export default NotificationBar;
