/* eslint-disable camelcase */
import moment from "moment";
import AuthUtils from "../../../../utils/AuthUtils";
import AuthApi from "../../../../utils/ApiUtils/AuthApi";

// visits statues
export const VISIT_STATUES = {
  visitRequested: "VISIT_REQUESTED",
  visitConfirmed: "VISIT_CONFIRMED",
  visitAssigned: "VISIT_ASSIGNED",
  visitInprogress: "VISIT_IN_PROGRESS",
  pendingKycVerification: "PENDING_KYC_VERIFICATION",
  visitCompleted: "VISIT_COMPLETED"
};

// visits intermediate statues
export const VISIT_INTERMEDIATE_STATUES = {
  // EXISITING SUBSCRIPTION STATUSES
  existingVisit: {
    agentWentToSafeToCollectLocker:
      "SAFE_DOORSTEP_AGENT_STARTED_FOR_LOCKUR_PICKUP",
    agentReachedSafeToCollectLocker:
      "SAFE_DOORSTEP_AGENT_REACHED_LOCKER_STORAGE_FOR_PICKUP",
    lockerManagerVerifiedByAgent:
      "SAFE_DOORSTEP_AGENT_VERIFIED_BY_LOCKER_MANAGER",
    lockerReceived: "SAFE_DOORSTEP_AGENT_RECEIVED_LOCKERS",
    agentStartedVisitToCustomerHome:
      "SAFE_DOORSTEP_AGENT_LEFT_LOCKER_STORAGE_FOR_CUSTOMER",
    agentReachedToCustomerHome:
      "SAFE_DOORSTEP_AGENT_REACHED_CUSTOMER_LOCATION_FOR_HANDOVER",
    agentVerifiedCustomer: "SAFE_DOORSTEP_CUSTOMER_VERIFIED_BY_AGENT",
    lockerHandoverToCustomer:
      "SAFE_DOORSTEP_AGENT_HANDOVER_LOCKERS_TO_CUSTOMER"
  },
  // NEW CUSTOMER / NEW SUBSCRIPTION STATUES
  newVisit: {
    customerVerifiedAgent: "SAFE_DOORSTEP_AGENT_VERIFIED_BY_CUSTOMER",
    agentStartedKyc: "SAFE_DOORSTEP_AGENT_STARTED_KYC",
    kycVerificationSubmitted:
      "SAFE_DOORSTEP_AGENT_SUBMITTED_KYC_FOR_VERIFICATION",
    kycVerified: "SAFE_DOORSTEP_CUSTOMER_KYC_VERIFIED",
    goldEvaluationStarted: "SAFE_DOORSTEP_AGENT_STARTED_GOLD_VALUATION",
    goldSealingStarted: "SAFE_DOORSTEP_AGENT_STARTED_GOLD_SEALING",
    goldSealingCompleted: "SAFE_DOORSTEP_AGENT_COMPLETED_GOLD_SEALING",
    lockerApproved: "SAFE_DOORSTEP_CUSTOMER_APPROVED_LOCKERS",
    planSelectedByCustomer: "SAFE_DOORSTEP_CUSTOMER_SELECTED_PLANS",
    paymentDone: "SAFE_DOORSTEP_CUSTOMER_PAID"
  },
  // COMMON STATUES
  common: {
    lockerSubmittedForApproval:
      "SAFE_DOORSTEP_AGENT_SUBMITTED_LOCKERS_FOR_CUSTOMER_APPROVAL",
    leftCustomerPlaceWithLocker:
      "SAFE_DOORSTEP_AGENT_LEFT_CUSTOMER_FOR_LOCKER_STORAGE",
    reachedSafeWithLocker:
      "SAFE_DOORSTEP_AGENT_REACHED_LOCKER_STORAGE_FOR_HANDOVER",
    agentVerified: "SAFE_DOORSTEP_LOCKER_MANAGER_VERIFIED_BY_AGENT",
    lockerHandoverToLockerManager:
      "SAFE_DOORSTEP_AGENT_HANDOVER_LOCKERS_TO_LOCKER_MANAGER",
    lockerStoredInSafe: "SAFE_DOORSTEP_LOCKERS_STORED_IN_VAULT"
  }
};

// query for confirming the visit
export const getConfirmVisitQuery = (reqObj) => {
  return {
    variables: reqObj,
    query: `
          mutation confirmVisit(
            $id: Int!
            $visit_time: timestamptz!
            $confirmed_at: timestamptz!
            $confirmed_by: String!
            $address: jsonb!
            $lat: float8!
            $long: float8!
          ) {
            update_visits(
              where: { id: { _eq: $id } }
              _set: {
                visit_time: $visit_time
                visit_status: "VISIT_CONFIRMED"
                address: $address
                lat: $lat
                long: $long
                confirmed_at: $confirmed_at
                confirmed_by_auth_id: $confirmed_by
              }
            ) {
              returning {
                id
                visit_time
                visit_status
                confirmed_at
                confirmed_by_auth_id
                address
              }
            }
          }
        `
  };
};

// query for fetching the list of field agents
export const getFieldAgentsListQuery = () => {
  return {
    variables: {},
    query: `
          query getFieldAgentsListQuery {
            users(where: {hasura_default_role: {_eq: "field_agent"}}) {
              auth_id
              first_name
              last_name
              email_id
              mobile_number
              name
              role_name
    					agent {
                id
                agent_display_id
              }
              agents {
                address
                address_proof
                agent_display_id
                id
                id_proof
                id_card_photos
                relation
                secondary_address
                secondary_mobile_number
              }
            }
          }
          `
  };
};

// query for assigning the visit to a field agent
export const getAssignVisitQuery = async (visitId, lockerManager) => {
  return {
    variables: {
      id: visitId,
      agent_auth_id: lockerManager,
      assigned_by: `${(await AuthApi?.verifyToken())?.user?.id}`,
      assigned_at: moment()
    },
    query: `
          mutation updateAgent(
            $id: Int!
            $agent_auth_id: String!
            $visit_status: visit_status_enum = "VISIT_ASSIGNED"
            $assigned_by: String!
            $assigned_at: timestamptz!
            $secondary_field_agent_one_auth_id: String
            $secondary_field_agent_two_auth_id: String
            $secondary_agents_visit_status: jsonb
          ) {
            update_visits_by_pk(
              pk_columns: { id: $id }
              _set: {
                agent_auth_id: $agent_auth_id
                visit_status: $visit_status
                assigned_by_auth_id: $assigned_by
                assigned_at: $assigned_at
                secondary_field_agent_one_auth_id: $secondary_field_agent_one_auth_id
                secondary_field_agent_two_auth_id: $secondary_field_agent_two_auth_id
                secondary_agents_visit_status: $secondary_agents_visit_status
              }
            ) {
              id
              agent_auth_id
              visit_status
              secondary_field_agent_one_auth_id
              secondary_field_agent_two_auth_id
              secondary_agents_visit_status
              assigned_by_auth_id
              assigned_by {
                id
                first_name
                last_name
                auth_id
                mobile_number
              }
              agent {
                id
                agent {
                  id
                  agent_display_id
                }
                auth_id
                first_name
                last_name
                mobile_number
              }
            }
          }
        `
  };
};

export const getVisitByIdQuery = (id) => {
  return {
    variables: {
      where: {
        id: { _eq: id }
      }
    },
    query: `
    query getVisitById($where: visits_bool_exp = {}) {
      visits(where: $where) {
        id
        visit_display_id
        visit_start_time
        visit_status
        intermediate_status
        visit_time
        visit_type
        first_name
        last_name
        space_id
        mobile_number
        address
        confirmed_at
        confirmed_by_auth_id
        assigned_by {
          id
          first_name
          last_name
        }
        space {
          id
          name
          space_number
          status
          address_id
        }
        agent {
          id
          agent {
            id
            agent_display_id
          }
          auth_id
          first_name
          last_name
          mobile_number
        }
        user {
          id
          first_name
          last_name
        }
        customer {
          id
          first_name
          last_name
        }
      }
    }
    `
  };
};

export const getStartVisitQuery = (id) => {
  return {
    variables: {
      id: parseInt(id, 10),
      visit_status: "VISIT_IN_PROGRESS",
      visit_start_time: moment()
    },
    query: `
    mutation myMutation(
      $id: Int!
      $visit_status: visit_status_enum = "VISIT_IN_PROGRESS"
      $visit_start_time: timestamptz
    ) {
      update_visits_by_pk(
        pk_columns: { id: $id }
        _set: { visit_status: $visit_status , visit_start_time: $visit_start_time }
      ) {
        id
        visit_status
        intermediate_status
      }
    }
  `
  };
};
