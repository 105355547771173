// ** builtins
import React, { useRef } from 'react';
import moment from 'moment';

// ** assets
import userIcon from '../../assets/userIcon.png';

// ** components
import RenewLockerForm from './RenewLockerForm';

const RenewLockerModal = ({ renewalData, onModalClose }) => {
	const modalRef = useRef(null);

	const {
		customerName = '',
		lockerNumber = '',
		startDate: previousStartDate = '',
		endDate: previousEndDate = '',
		mobileNumber = '',
		spaceId = '',
		lockerTypeId = '',
		lockerId = '',
	} = renewalData;
	return (
		<div ref={modalRef} className='mt-[32px]'>
			<div className='bg-[#FFF8EA] rounded-[12px] flex justify-between items-center p-[22px]'>
				<div className='flex gap-2 items-center'>
					<img src={userIcon} className='w-[32px] h-[32px] mr-2' />
					<div className='flex flex-col'>
						<div className='text-[18px] text-bold'>{customerName}</div>
						<div className='text-[14px] text-[#909090] font-bold'>
							{mobileNumber}
						</div>
					</div>
				</div>
				<div>
					<span className='text-[16px] text-[#909090] font-bold'>
						Locker ID:{' '}
					</span>
					<span className='text-[16px] text-bold'>{lockerNumber}</span>
				</div>
			</div>
			<div className='flex justify-between mt-[16px] mb-[32px]'>
				<div className='flex flex-col'>
					<div className='text-[12px] text-[#909090] font-bold'>Start Date</div>
					<div className='text-[18px] font-extrabold'>
						{moment(previousStartDate).format('DD-MM-YYYY')}
					</div>
				</div>
				<div className='flex flex-col'>
					<div className='text-[12px] text-[#909090] font-bold'>End Date</div>
					<div className='text-[18px] font-extrabold text-[#D42800]'>
						{moment(previousEndDate).format('DD-MM-YYYY')}
					</div>
				</div>
			</div>
			<hr className='bg-[#E6E6E6] h-[1px]' />
			<div className='mt-[24px]'>
				<div className='text-[#333333] text-[16px] font-bold mb-[16px]'>
					Renewal Details
				</div>
				<RenewLockerForm
					lockerTypeId={lockerTypeId}
					lockerId={lockerId}
					spaceId={spaceId}
					onModalClose={onModalClose}
					modalRef={modalRef}
				/>
			</div>
		</div>
	);
};

export default RenewLockerModal;
