import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../../../components/Modal";
import Api from "../../../../utils/ApiUtils/Api";
import { CloseOutlined } from "@ant-design/icons";

export default function OtpVerificationModal({onClose}) {
  const { id } = useParams();
  const [state, onSetState] = useState({
    otp: "",
    isLoading: false,
    isError: false
  });
  const { otp, isLoading } = state;
  useEffect(() => {
    async function generateOtp() {
      onSetState((prev) => ({
        ...prev,
        isLoading: true
      }));
      try {
        const data = await Api.post(`/visit/${id}/doorstep/otp/generate`);
        const otp = data?.otp ?? data?.data?.otp;
        onSetState((prev) => ({
          ...prev,
          otp,
          isLoading: false
        }));
      } catch (err) {
        onSetState((prev) => ({
          ...prev,
          isLoading: false,
          isError: true
        }));
      }
    }
    generateOtp();
  }, []);
  return (
    <Modal
      className="w-1/3 p-10"
      isLoading={isLoading}
      closable={false}
      isCloseOutside={false}
    >
      <div className="relative">
        <CloseOutlined className="text-black text-2xl font-bold absolute top-0 right-0 cursor-pointer" onClick={onClose} />
        <div className="pb-2">
          <p className="text-3xl font-bold">Oro Safe Partner Verification</p>
        </div>
        <div className="py-6 w-3/4">
          <p className="text-2xl text-subtle font-bold leading-8">Please share the OTP to the Oro Safe Partner to begin visit.</p>
        </div>
        <div className="py-2">
          <p className="text-base text-subtle font-bold">Share the below otp.</p>
        </div>
        <div className="py-1">
          <p className="font-bold" style={{fontSize: '64px'}}>{otp}</p>
        </div>
      </div>
    </Modal>
  );
}
