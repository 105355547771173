import React from 'react'
import Modal from '../Modal'
import {ReactComponent as SuccessIcon} from '../../assets/success.svg'
import Button from '../Buttons/Button'

function Success({body, onClose}) {
  return (
    <Modal  className="p-10" onClose={onClose}>
      <div className="flex flex-col justify-center items-center space-y-10">
        <SuccessIcon/>
        {body}
        <Button variant="cta" className="w-full" onClick={onClose}>Close</Button>
      </div>
    </Modal>
  )
}

export default Success
