export const doorstepLockerTableColumns = [
  {
    title: "OroSafe ID",
    dataIndex: "oro-safe-id",
    key: "oro-safe-id",
    show: true,
    render: (text) => <p className="text-body-bold uppercase">{text}</p>,
  },
  {
    title: "Godrej ID",
    dataIndex: "godrej-id",
    key: "godrej-id",
    show: true,
    render: (text) => <p className="text-body-bold uppercase">{text}</p>,
  },
  {
    title: "Locker Type",
    dataIndex: "locker-type",
    key: "locker-type",
    show: true,
    render: (text) => <p className="text-body-bold uppercase">{text}</p>,
  },
  {
    title: "Size Identifier",
    dataIndex: "size-identifier",
    key: "size-identifier",
    show: true,
    render: (text) => <p className="text-body-bold">{text}</p>,
  },
];
