import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Buttons/Button";
import Loading from "../../../../../components/loading";
import Api from "../../../../../utils/ApiUtils/Api";
import AddressDetailsCard from "./AddressDetailsCard";
import NomineeDetailsCard from "./NomineeDetailsCard";
import PersonalDetailsCard from "./PersonalDetailsCard";

export default function ApproveKycForm({ userId, onUpdate }) {
  const [state, setState] = useState({
    isLoading: false,
    isError: false,
    isSubmitting: false
  });
  const [userDetails, setUserDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    middleName: "",
    dateOfBirth: new Date(),
    gender: "",
    fatherName: "",
    // monthlyIncome: "",
    maskedAadhaarNumber: "",
    aadhaarCardPhoto: {},
    // occupation: ""
  });
  const [addressDetails, setAddressDetails] = useState({});
  const [nomineeDetails, setNomineeDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    address: {}
  });

  const { isLoading } = state;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isLoading: true
    }));
    async function getKycData() {
      try {
        const { data } = await Api.get(`/user/${userId}/safe-kyc`);
        setUserDetails(data?.user);
        setAddressDetails(data?.user?.permanentAddress);
        setNomineeDetails(data?.nominee);
        setState((prev) => ({
          ...prev,
          isLoading: false
        }));
      } catch (err) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          isError: true
        }));
      }
    }
    getKycData();
  }, []);

  // use this function approve the kyc details
  async function onHandleApproveKycDetails() {
    setState((prev) => ({
      ...prev,
      isSubmitting: true
    }));
    try {
      await Api.post(`/user/${userId}/safe-kyc/approve`, {
        userDetails: { ...userDetails, permanentAddress: addressDetails },
        nomineeDetails: {
          ...nomineeDetails,
          address: { ...nomineeDetails.address, country: 'India' }
        }
      });
      onUpdate();
      setState((prev) => ({
        ...prev,
        isSubmitting: false
      }));
    } catch (err) {
      setState((prev) => ({
        ...prev,
        isSubmitting: false,
        isError: true
      }));
    }
  }

  const onChangeUserInfo = (type, e) => {
    switch (type) {
    case 'firstName':
      setUserDetails((prev) => ({
        ...prev,
        firstName: e.target.value
      }));
      break;
    case 'middleName':
      setUserDetails((prev) => ({
        ...prev,
        middleName: e.target.value
      }));
      break;
    case 'lastName':
      setUserDetails((prev) => ({
        ...prev,
        lastName: e.target.value
      }));
      break;
    case 'dateOfBirth':
      setUserDetails((prev) => ({
        ...prev,
        dateOfBirth: e
      }));
      break;
    case 'gender':
      setUserDetails((prev) => ({
        ...prev,
        gender: e.target.value
      }));
      break;
    case 'fatherName':
      setUserDetails((prev) => ({
        ...prev,
        fatherName: e.target.value
      }));
      break;
    case 'maskedAadhaarNumber':
      setUserDetails((prev) => ({
        ...prev,
        maskedAadhaarNumber: e
      }));
      break;
    case 'occupation':
      setUserDetails((prev) => ({
        ...prev,
        occupation: e
      }));
      break;
    case 'monthlyIncome':
      setUserDetails((prev) => ({
        ...prev,
        monthlyIncome: e.target.value
      }));
      break;
    default:
    }
  };

  const onChangeAddress = (type, e) => {
    switch (type) {
    case 'addr_line_1':
      setAddressDetails((prev) => ({
        ...prev,
        addrLine1: e.target.value
      }));
      break;
    case 'state':
      setAddressDetails((prev) => ({
        ...prev,
        state: e.target.value
      }));
      break;
    case 'city':
      setAddressDetails((prev) => ({
        ...prev,
        city: e.target.value
      }));
      break;
    case 'pincode':
      setAddressDetails((prev) => ({
        ...prev,
        pincode: e
      }));
      break;
    default:
    }
  };

  const onChangeNomineeDetails = (type, e) => {
    switch (type) {
    case 'firstName':
      setNomineeDetails((prev) => ({
        ...prev,
        firstName: e.target.value
      }));
      break;
    case 'mobileNumber':
      setNomineeDetails((prev) => ({
        ...prev,
        mobileNumber: e
      }));
      break;
    case 'lastName':
      setNomineeDetails((prev) => ({
        ...prev,
        lastName: e.target.value
      }));
      break;
    case 'addr_line_1':
      setNomineeDetails((prev) => ({
        ...prev,
        address: {
          ...nomineeDetails.address,
          addrLine1: e.target.value
        }
      }));
      break;
    case 'state':
      setNomineeDetails((prev) => ({
        ...prev,
        address: {
          ...nomineeDetails.address,
          state: e.target.value
        }
      }));
      break;
    case 'city':
      setNomineeDetails((prev) => ({
        ...prev,
        address: {
          ...nomineeDetails.address,
          city: e.target.value
        }
      }));
      break;
    case 'pincode':
      setNomineeDetails((prev) => ({
        ...prev,
        address: {
          ...nomineeDetails.address,
          pincode: e
        }
      }));
      break;
    default:
    }
  };
  
  function isButtonDisabled() {
    return (
      !userDetails?.firstName ||
			!userDetails?.lastName ||
			!userDetails?.dateOfBirth ||
			!userDetails?.gender ||
			!userDetails?.fatherName ||
			!userDetails?.aadhaarCardPhoto ||
			!userDetails?.maskedAadhaarNumber ||
			// !userDetails?.occupation ||
			// !userDetails?.monthlyIncome ||
			!addressDetails?.addrLine1 ||
			!addressDetails?.city ||
			!addressDetails?.pincode ||
			!addressDetails?.state ||
			!nomineeDetails?.firstName ||
			!nomineeDetails?.lastName ||
			!nomineeDetails?.mobileNumber ||
			!nomineeDetails?.address?.addrLine1 ||
			!nomineeDetails?.address?.city ||
			!nomineeDetails?.address?.pincode ||
			!nomineeDetails?.address?.state
    );
  }


  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="pb-6">
      <div>
        <p className="text-header-3 py-4">Approve Safe KYC</p>
      </div>
      <div className="flex lg:flex-row flex-col items-start py-4">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">Personal Details</p>
        <PersonalDetailsCard
          state={userDetails}
          onChange={(value, event) => onChangeUserInfo(value, event)}
        />
      </div>
      <div className="flex lg:flex-row flex-col items-start py-4">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">
          Permanent Address Details
        </p>
        <AddressDetailsCard
          state={addressDetails}
          onChange={(value, event) => onChangeAddress(value, event)}
        />
      </div>
      <div className="flex lg:flex-row flex-col items-start py-4">
        <p className="text-header-3 p-5 lg:w-1/5 uppercase">nominee details</p>
        <NomineeDetailsCard
          state={nomineeDetails}
          onChange={(value, event) => onChangeNomineeDetails(value, event)}
        />
      </div>
      <div className="py-6 flex space-x-5 w-1/5 mx-auto">
        <Button
          onClick={() => onHandleApproveKycDetails()}
          variant={isButtonDisabled() ? 'inactive' : 'cta'}
          className={
            isButtonDisabled()
              ? 'w-full rounded-full bg-gray-300 py-3.5 cursor-not-allowed'
              : 'w-full'
          }
          disabled={isButtonDisabled()}
        >
          Approve Safe KYC
        </Button>
      </div>
    </div>
  );
}
