import React from "react";
import Input from "./Input";

const LabelInput = ({ label, labelClassName, rootClassName, ...rest }) => (
  <div className={`flex flex-col space-y-4 ${rootClassName ?? ''}`}>
    {!!label && <p className={`text-base font-bold text-[#909090] uppercase ${labelClassName ?? ''}`}>{label}</p>}
    <Input {...rest} />
  </div>
);

export default LabelInput;
