import React from "react";
import { Link as ReactLink, useLocation } from "react-router-dom";

export default function Link({ children, className = '', variant, to }) {
  const location = useLocation();

  const variantClassNameMap = {
    primary: 'bg-medium-yellow px-5 py-2 rounded-lg text-body-bold',
    secondary: 'bg-white px-5 border border-[#454545] py-2 rounded-lg text-body-bold',
    normal: 'border border-[#454545] text-body-bold px-5 h-[50px] rounded-full flex items-center justify-center',
    cta: 'bg-gradient-to-b from-[#F9CA36] to-[#F8B830] text-body-bold px-5 py-3 rounded-full shadow-lg',
    active: 'bg-new-green px-5 py-2 rounded-lg text-body-bold text-white',
    inactive: 'rounded-lg px-5 py-2 border border-gray-300 text-body-bold text-gray-400',
    preview: 'py-1 px-3 border border-[#454545] rounded-lg bg-white text-xs text-black',
  };

  return (
    <ReactLink to={{ pathname: to ?? '/', state: { history: [...(location.state?.history ?? []), location.pathname] } }}
      className={`${variantClassNameMap[variant] ?? ''}  inline-block ${className}`}
    >
      {children}
    </ReactLink>
  );
}
