// ** builtins
import React, { useEffect, useState } from 'react';
import moment from 'moment';

// ** assets
import rupeeSymbol from '../../assets/rupeeSymbol.png';
import successUploadIcons from '../../assets/successUploadIcon.png';

// ** components
import Dropdown from '../../components/Input/Dropdown';
import Upload from '../../components/Input/Upload';
import DatePicker from '../../components/Input/DatePicker';
import Button from '../../components/Buttons/Button';
import Success from '../../components/Modals/Success';
import Notification from '../../components/Notifications';

// ** services
import Api from '../../utils/ApiUtils/Api';

// ** constants

import { tenureTypes } from '../Spaces/constants';
import { PAYMENT_METHODS } from './constants';
import { getPricing, getTenureType } from './utils';

const RenewLockerForm = ({
	lockerId = '',
	lockerTypeId = '',
	spaceId = '',
	onModalClose,
	modalRef,
}) => {
	const [closeOnClick, setCloseOnClick] = useState([]);
	const [formData, setFormData] = useState({
		paymentAmount: '',
		paymentProof: {
			label: '',
			value: '',
		},
		paymentProofImage: {
			imageName: '',
			imageUrl: '',
		},
		tenure: {
			label: '',
			value: '',
		},
		startDate: moment(new Date().getDate(), 'DD/MM/YYYY'),
		endDate: '',
		tenureList: [],
	});

	useEffect(() => {
		if (
			formData?.tenure?.value
				?.toLocaleLowerCase()
				.includes(tenureTypes.MONTH.toLowerCase())
		) {
			const endDate = moment(formData?.startDate).add(
				formData?.tenure?.value.split(' ')[0],
				'months'
			);
			setFormDataHelper({
				key: 'endDate',
				value: endDate,
			});
		} else if (
			formData?.tenure?.value
				?.toLocaleLowerCase()
				.includes(tenureTypes.YEAR.toLowerCase())
		) {
			const endDate = moment(formData?.startDate).add(
				formData?.tenure?.value.split(' ')[0],
				'years'
			);

			setFormDataHelper({
				key: 'endDate',
				value: endDate,
			});
		} else if (
			formData?.tenure?.value
				?.toLocaleLowerCase()
				.includes(tenureTypes.DAY.toLowerCase())
		) {
			const endDate = moment(formData?.startDate).add(
				formData?.tenure?.value.split(' ')[0],
				'days'
			);

			setFormDataHelper({
				key: 'endDate',
				value: endDate,
			});
		}
	}, [formData?.startDate, formData?.tenure?.value]);

	useEffect(() => {
		if (modalRef.current) {
			modalRef.current.addEventListener('click', (e) => {
				if (e.target?.getAttribute('value') !== 'isOption') {
					closeOnClick.map((ele) => ele());
				}
			});
		}

		return modalRef.current.removeEventListener('click', (e) => {
			closeOnClick.map((ele) => ele());
		});
	}, [modalRef.current]);

	const [isSuccessModal, setIsSuccessModal] = useState(false);
	const successMessage = 'Locker renewed successfully';

	const errorObject = Object.keys(formData).reduce((acc, key) => {
		switch (key) {
			case 'paymentProof':
			case 'tenure': {
				if (!formData[key]?.value) {
					acc[key] = 'This field is required';
				} else {
					acc[key] = '';
				}
				break;
			}
			case 'paymentProofImage': {
				if (!formData[key]?.imageUrl) {
					acc[key] = 'This field is required';
				} else {
					acc[key] = '';
				}
				break;
			}
			default: {
				if (!formData[key]) {
					acc[key] = 'This field is required';
				} else {
					acc[key] = '';
				}
			}
		}
		return acc;
	}, {});

	function setFormDataHelper({ key, value }) {
		setFormData((data) => ({
			...data,
			[key]: value,
		}));
	}

	const isError = Object.values(errorObject).some((error) => !!error);

	const onSubmit = async () => {
		const response = await Api.post('/safe/renew-subscription', {
			lockerId: lockerId,
			paymentAmount: parseInt(formData?.paymentAmount),
			paymentProof: formData?.paymentProofImage?.unSignedImage,
			paymentMethod: formData?.paymentProof?.value,
			startDate: formData?.startDate,
			endDate: formData?.endDate,
			tenure: parseInt(formData?.tenure?.value.split(' ')[0]),
			tenure_type: getTenureType(formData?.tenure?.value.split(' ')[1]),
		});

		if (response?.status === 200) {
			setIsSuccessModal(true);
		} else {
			Notification.create('error', 'Unable To Renew Locker');
		}
	};

	useEffect(() => {
		(async () => {
			const response = await getPricing({
				lockerTypeId,
				spaceId: spaceId,
			});
			if (response?.isError) {
				Notification.create('error', 'Unable To Fetch Pricing');
			} else {
				setFormDataHelper({
					key: 'tenureList',
					value: response,
				});
			}
		})();
	}, []);

	return (
		<div>
			<div className='flex gap-[24px] flex-wrap'>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase'>
						Payment Amount
					</div>
					<div className='relative'>
						<img
							src={rupeeSymbol}
							className='w-[34px] h-[34px] absolute top-[8px] left-[8px]'
						/>
						<input
							name='paymentAmount'
							onChange={(event) => {
								const value = event?.target?.value;
								if (!isNaN(value)) {
									setFormDataHelper({
										key: 'paymentAmount',
										value: value,
									});
								}
							}}
							value={formData?.paymentAmount}
							className={`w-[156px] h-[50px] pl-[46px] pr-[8px] border-[2px] rounded-[10px] 
                        font-extrabold text-[16px]
                        border-[#E8E8E8] outline-none ${
													errorObject?.paymentAmount ? 'border-[#FF0000]' : ''
												}`}
						/>
					</div>
				</div>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase'>
						payment proof
					</div>
					<Dropdown
						setCloseOnClick={setCloseOnClick}
						className={`${
							errorObject?.paymentProof ? '!border-[#FF0000]' : ''
						}`}
						selectedOption={formData?.paymentProof}
						onSelect={(value) => {
							setFormDataHelper({ key: 'paymentProof', value: value });
						}}
						placeholder='Choose'
						options={PAYMENT_METHODS}
					/>
				</div>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase flex gap-4'>
						<div>payment proof </div>
						{formData?.paymentProofImage?.imageUrl ? (
							<div>
								<img src={successUploadIcons} height='16px' width='90px' />
							</div>
						) : null}
					</div>
					<Upload
						className={`${
							errorObject?.paymentProofImage
								? '!border-[#FF0000] !border-[2px]'
								: ''
						}`}
						image={formData?.paymentProofImage}
						setImage={(data) => {
							setFormDataHelper({
								key: 'paymentProofImage',
								value: data,
							});
						}}
					/>
				</div>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase'>
						choose tenure
					</div>
					<Dropdown
						setCloseOnClick={setCloseOnClick}
						className={`${errorObject?.tenure ? '!border-[#FF0000]' : ''}`}
						selectedOption={formData?.tenure}
						onSelect={(value) => {
							setFormDataHelper({ key: 'tenure', value: value });
						}}
						placeholder='Choose'
						options={formData?.tenureList?.map((element) => ({
							label: element?.tenureString,
							value: element?.tenureString,
						}))}
					/>
				</div>
			</div>
			<div className='mt-[24px] flex gap-[40px]'>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase'>
						choose New Start Date
					</div>
					<DatePicker
						className={`${errorObject?.startDate ? '!border-[#FF0000]' : ''}`}
						date={formData?.startDate}
						onChange={(value) => setFormDataHelper({ key: 'startDate', value })}
					/>
				</div>
				<div>
					<div className='text-[14px] font-bold text-[#909090] mb-[12px] uppercase'>
						end date
					</div>
					<DatePicker
						className={`${errorObject?.endDate ? '!border-[#FF0000]' : ''}`}
						disabled
						date={formData?.endDate}
						disablePlaceholderText='End Date'
					/>
				</div>
			</div>
			<Button
				type='submit'
				disabled={isError}
				className={`w-full mt-[40px] ${
					isError
						? '!bg-[#CECECE] !cursor-not-allowed !shadow-none !bg-none text-black/30'
						: ''
				}`}
				variant='cta'
				onClick={onSubmit}
			>
				Confirm Renewal
			</Button>
			{isSuccessModal ? (
				<Success
					body={
						<div className='text-center text-2xl font-bold w-3/4'>
							{successMessage}
						</div>
					}
					onClose={() => {
						onModalClose()
						setIsSuccessModal(false);
						window.location.reload();
					}}
				/>
			) : null}
		</div>
	);
};

export default RenewLockerForm;
