import React from 'react';

export default function ArrowSvg({
	width = '8',
	height = '4',
	arrowFill = '#000',
	className,
	onClick,
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 8 4'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
			onClick={onClick}
		>
			<path
				d='M1 0.5L4 3.5L7 0.5'
				stroke={arrowFill}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
