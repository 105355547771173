import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import Modal from '../../components/Modal';
import LabelInput from '../../components/Form/LabelInput';
import Button from '../../components/Buttons/Button';
import Form from '../../components/Form/Form';
import Link from '../../components/Link';
import Api from '../../utils/ApiUtils/Api';
import Loading from '../../components/loading';
import Notification from '../../components/Notifications';
import Success from '../../components/Modals/Success';
import { SearchOutlined } from '@ant-design/icons';
import { capitalize, debounce } from 'lodash';
import permissions from '../../utils/permissionConfig';
import AccessCheck from '../../routes/accessCheck';

const LockerTypes = () => {
  const [state, setStateHelper] = useState({
    isLoading: true,
    isError: false,
    count: 0,
    lockerTypes: [],
    page: 1,
    isSubmitting: false,
    searchValue: '',
    createLockerTypeModal: false,
    showSuccess: false,
  });

  function setState(changes) {
    setStateHelper((prev) => ({
      ...prev,
      ...changes,
    }));
  }

  function onChange(type, e) {
    switch (type) {
      case "type":
        setState({ typeName: e.target.value });
        break;
      case "size":
        setState({ size: e.target.value });
        break;
      case "width":
        setState({ width: e });
        break;
      case "height":
        setState({ height: e });
        break;
      case "length":
        setState({ length: e });
        break;
      default:
    }
  }
  const getLockerTypes = (page = 1, search = null) => {
    const { searchValue, page: currentPage } = state;
    setState({ isLoading: true });
    Api.get(`locker-type/detail-list?page=${page ?? currentPage}&limit=10&search=${search ?? searchValue}`).then((data) => {
      setState({ isLoading: false, lockerTypes: data?.lockerTypes?.rows, count: data?.lockerTypes?.count });
    }).catch(err => setState({ isLoading: false, isError: true }));
  }
  useEffect(() => {
    getLockerTypes();
  }, []);

  const activateLocker = (id) => {
    setState({ isLoading: true })
    Api.put(`locker-type/${id}/activate`).then((data) => {
      setState({
        isLoading: false, lockerTypes: state?.lockerTypes?.map((lockerType) => (lockerType?.id === parseInt(id, 10)
          ? { ...lockerType, status: 'ACTIVE' } : lockerType))
      })
      Notification.create('success', 'Locker is Activated succefully');

    }).catch(err => setState({ isLoading: false, isError: true }));;
  };

  const deactiveLocker = (id) => {
    setState({ isLoading: true })
    Api.put(`locker-type/${id}/deactivate`).then((data) => {
      setState({
        isLoading: false, lockerTypes: state?.lockerTypes?.map((lockerType) => (lockerType?.id === parseInt(id, 10)
          ? { ...lockerType, status: 'DEACTIVATED' } : lockerType))
      })
      Notification.create('success', 'Locker is Deactivated succefully');
    }).catch(err => setState({ isLoading: false, isError: true }));;
  };

  const handleTableChange = (pagination) => {
    setState({ page: pagination.current });
    getLockerTypes(pagination.current);
  }

  const handleSearch = debounce((value) => {
    setState({ searchValue: value });
    getLockerTypes(1, value);
  }, 500);


  const columns = [
    {
      title: 'Type Name',
      dataIndex: 'type',
      key: 'type',
      show: true,
      render: (text) => <p className="text-body-bold p-2">{text}</p>,
    },
    {
      title: 'Size Identifier',
      dataIndex: 'size',
      key: 'size',
      show: true,
      render: (text) => <p className="text-body-bold capitalize">{capitalize(text)}</p>,
    },
    {
      title: 'Dimensions (in cm)',
      dataIndex: 'locker_dimensions',
      key: 'locker_dimensions',
      show: true,

      render: (text, data) => <p className="text-body-bold capitalize">{`${data?.width} X ${data?.height} X ${data?.length}`}</p>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      show: true,
      render: (text, data) => (
        <AccessCheck
          requiredPermissions={permissions.locker_types.table_row.deactivate.permission}
          alternateChildren={<div></div>}
          looseCheck={false}
        >
          <Button variant="secondary" className="text-xs font-bold"
            onClick={() => (data?.status === 'DEACTIVATED' ? activateLocker(data?.id) : deactiveLocker(data?.id))}
          >
            {data?.status === 'DEACTIVATED' ? 'Activate' : 'Deactivate'}
          </Button>
        </AccessCheck>
      ),
    },
  ];


  const addLockerType = () => {
    const { typeName, width, height, length, size } = state;
    const body = {
      type: typeName,
      size: size?.toUpperCase(),
      height: parseFloat(height),
      width: parseFloat(width),
      length: parseFloat(length),
    };
    setState({ isSubmitting: true })
    Api.post('locker-type', body).then(
      (data) => {
        setState({
          isSubmitting: false, createLockerTypeModal: false,
          typeName: null, width: null, height: null, length: null, size: null, showSuccess: true
        });
        getLockerTypes();
        Notification.create('success', 'Locker Type added succefully')
      },
    ).catch((err) => setState({ isError: true, isSubmitting: false }));
  };
  const { isLoading, isError, createLockerTypeModal, lockerTypes, count, isSubmitting,
    typeName, width, height, length, size, showSuccess } = state;

  if (isError) return (
    <div className="flex flex-col space-y-2 font-bold h-1/2 items-center justify-center">
      <p>Something Went Wrong</p>
      <p>Please Try Again</p>
      <Button variant="primary" onClick={getLockerTypes}>Retry</Button>
    </div>
  )

  return (
    <>
      <div className="space-y-10">
        <div className="flex justify-between mr-48">
          <div className="flex gap-10 items-start flex-wrap">
            <p className="text-header-3">Locker Types</p>
            <AccessCheck
              requiredPermissions={permissions.locker_types.create_new_locker_type.permission}
              alternateChildren={<div></div>}
              looseCheck={false}
            >
              <Button variant="secondary" className="text-xs font-bold" onClick={() => setState({ createLockerTypeModal: true })}>
                Create New Locker Type
              </Button>
            </AccessCheck>
          </div>
          <div className="relative ">
            <input type="search" placeholder="Search using any parameter" className="input-normal font-bold outline-none w-96 p-2"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <SearchOutlined className="text-xl absolute right-2 top-2 text-subtle" />
          </div>
        </div>
        <Table loading={isLoading} className="w-3/4 mx-auto" scroll={{ x: 800 }} columns={columns} dataSource={lockerTypes}
          onChange={handleTableChange} pagination={{ position: ['bottomCenter'], pageSize: 10, total: count }}
        />
      </div>
      {createLockerTypeModal && (
        <Modal title="Create New Locker Type" onClose={() => setState({ createLockerTypeModal: false })}
          className="w-4/5 md:w-1/2 xl:w-2/5 p-10">
          <Form onSubmit={addLockerType} className="space-y-10">
            <LabelInput label="Type Name" type="text" className="text-base mb-8" placeholder="Enter Locker Type Name here"
              value={typeName} onChange={(e) => onChange('type', e)} required
            />
            <div className="flex flex-wrap">
              <div className="space-y-4 w-2/3">
                <p className="label">
                  SIZE (IN CM)
                </p>
                <div className="flex gap-2 items-center w-full">
                  <LabelInput type="numberText" placeholder="width" value={width}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('width', e)} required
                  />
                  <span className="label">X</span>
                  <LabelInput type="numberText" placeholder="height" value={height}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('height', e)} required
                  />
                  <span className="label">X</span>
                  <LabelInput type="numberText" placeholder="depth" value={length}
                    rootClassName="w-1/4" inputClassName="p-2"
                    onChange={(e) => onChange('length', e)} required
                  />
                </div>
              </div>
              <div className="w-1/3">
                <LabelInput label="Size Identifier" type="text" className="text-base" placeholder="Small/Medium/Large"
                  value={size} onChange={(e) => onChange('size', e)} required
                />
              </div>
            </div>
            <div className="flex gap-5">
              <Button variant="normal" className="w-full" onClick={() => setState({ createLockerTypeModal: false })}>Cancel</Button>
              <Button variant="cta" className="w-full" isLoading={isSubmitting} type="submit">Create New Locker Type</Button>
            </div>
          </Form>
        </Modal>
      )}
      {showSuccess && (
        <Success body={
          <div className="text-center text-2xl font-bold w-3/4">
            New Locker Type added as per request
          </div>
        } onClose={() => setState({ showSuccess: false })} />
      )}
    </>
  );
};
export default LockerTypes;
